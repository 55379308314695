import React from 'react';
import {
  StyledContent,
  StyledLine,
  StyledStep,
  StyledProgress,
  StyledPoint,
  StyledTimeline,
} from './Timeline.styled';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

interface IStep {
  heading?: string;
  tasks: string[];
  completion: number;
}

const Step: React.FC<{ step: IStep }> = ({ step }) => {
  const { heading, tasks, completion } = step;

  return (
    <StyledStep>
      <div>
        <StyledLine>
          <StyledProgress progress={completion} />
          <StyledPoint variant={completion > 0 ? 'primary' : 'secondary'} />
        </StyledLine>
        <StyledContent>
          <Grid container>
            <Grid item xs={12} sx={{ order: { xs: 2, md: 1 } }}>
              {tasks.map((task, index) => (
                <Typography
                  variant="body2"
                  component="p"
                  color="text.primary"
                  key={`timeline-${heading}-task-${index}`}
                  mb={1}
                >
                  {task}
                </Typography>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ order: { xs: 1, md: 2 }, mb: { xs: 1, md: 0 } }}
            >
              <Typography variant="h200" color="primary" component="h6">
                {heading}
              </Typography>
            </Grid>
          </Grid>
        </StyledContent>
      </div>
    </StyledStep>
  );
};

interface TimelineProps {
  steps: IStep[];
}

const Timeline: React.FC<TimelineProps> = ({ steps }) => {
  return (
    <StyledTimeline>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 0 }}>
        {steps.map((step, index) => (
          <Step step={step} key={`timeline-step-${index}`} />
        ))}
      </Stack>
    </StyledTimeline>
  );
};

export default Timeline;
