import styled from 'styled-components';

export const StyledImage = styled.img<{ loaded?: boolean }>`
  border-radius: 8px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(props) => (props.loaded ? 1 : 0)} !important;
  display: block;
  outline: 9px solid rgba(255, 255, 255, 0.18);
  outline-offset: -9px;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 280px;
  height: 280px;
  margin: 0 auto;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.up('md')} {
    height: 352px;
    max-width: 352px;
  }
`;

export const StyledImagePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
`;

export const StyledCompleted = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
`;

export const StyledMinting = styled.div`
  text-align: center;
  padding-bottom: 128px;
  width: 100%;
  margin-top: 12px;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 512px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    margin-top: 32px;
  }
`;
