import styled from 'styled-components';

export const StyledSwiperArrow = styled.button`
  padding: 0;
  background: transparent;
  border: 0;
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: -2px;
  transition: color 350ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    color: ${(props) => props.theme.palette.text.secondary};

    &:hover {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }

  .MuiSvgIcon-fontSizeMedium {
    ${(props) => props.theme.breakpoints.up('md')} {
      font-size: 48px;
    }

    &:focus {
      outline: none;
    }
  }
`;
