import Heading from '@components/Heading/Heading';
import {
  StyledMinting,
  StyledCompleted,
  StyledImage,
  StyledImagePlaceholder,
  StyledImageWrapper,
} from './Minting.styled';
import Typography from '@mui/material/Typography';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import React, { useEffect, useState, useRef } from 'react';
import Fade from '@mui/material/Fade';
import Button from '@components/Button/Button';
import Stack from '@mui/material/Stack';
import Alert from '@components/Alert/Alert';
import { getShortText } from '@utils/getShortText';
import { SampleToken, TxReceipt } from '@utils/blockchain/blockchain.interface';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import LazyLoad from 'react-lazyload';

interface MintingProps {
  setMinting: React.Dispatch<React.SetStateAction<boolean>>;
  setMinted: React.Dispatch<React.SetStateAction<boolean>>;
  setTxReceipt: React.Dispatch<React.SetStateAction<TxReceipt | null>>;
  contractAddress: string;
  txReceipt: TxReceipt | null;
  sampleDrops: SampleToken[] | null;
}

const Minting: React.FC<MintingProps> = ({
  setMinting,
  setMinted,
  txReceipt,
  setTxReceipt,
  sampleDrops,
}) => {
  const [progress, setProgress] = useState(0);
  const [randomNFTsImages, setRandomNFTsImages] = useState<string[]>([]);
  const [currentNFTImageIndex, setCurrentNFTImageIndex] = useState(0);
  const [loading, setLoading] = useState({
    cacheImages: true,
  });

  const randomImagesCount = 4;

  const getRandomNFTsImages = async () => {
    if (sampleDrops && sampleDrops.length > 0) {
      const randomImages = sampleDrops.map(
        (sampleToken) => sampleToken.imageURL
      );
      setRandomNFTsImages(randomImages);
    }
  };

  useEffect(() => {
    if (randomNFTsImages) getRandomNFTsImages();
  }, [sampleDrops]);

  const cacheImages = async (images: string[]) => {
    const promises = images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = () => {
          resolve(null);
        };
        img.onerror = () => {
          reject();
        };
      });
    });

    await Promise.all(promises);

    setLoading((loading) => ({ ...loading, cacheImages: false }));
  };

  useEffect(() => {
    if (randomNFTsImages && randomNFTsImages.length > 0) {
      cacheImages(randomNFTsImages);
    }
  }, [randomNFTsImages]);

  let interval: ReturnType<typeof setInterval>;

  useEffect(() => {
    if (!loading.cacheImages) {
      if (progress < 100) {
        interval = setInterval(() => {
          const randomNumber = Math.round(Math.random() * 20);

          setCurrentNFTImageIndex((prevIndex) =>
            prevIndex !== randomImagesCount - 1 ? prevIndex + 1 : 0
          );

          setProgress((prevProgress) =>
            prevProgress + randomNumber < 100
              ? prevProgress + randomNumber
              : 100
          );
        }, 300);
      }
    }

    return () => clearInterval(interval);
  }, [loading, progress]);

  const shortTxId = getShortText(txReceipt!.txId as string, 8);

  const [loaded, setLoaded] = useState(false);
  const placeholderRef = useRef<HTMLDivElement>(null);

  const handleLoad = () => {
    placeholderRef?.current?.remove();
    setLoaded(true);
  };

  return loading.cacheImages ? (
    <LoadingSpinner size="large" color="secondary" addText center />
  ) : (
    <Fade in={true}>
      <div>
        <Heading
          variant="h700"
          component="h1"
          mt={{ xs: '12px', md: 2, xl: 4 }}
          textAlign="center"
        >
          {progress < 100 ? 'Processing...' : 'See results of minting'}
        </Heading>
        <StyledMinting>
          {randomNFTsImages && randomNFTsImages.length > 0 && (
            <StyledImageWrapper>
              <StyledImagePlaceholder ref={placeholderRef} />
              <LazyLoad>
                <Fade in={true}>
                  <StyledImage
                    src={randomNFTsImages[currentNFTImageIndex]}
                    alt="NFT"
                    onLoad={handleLoad}
                    onError={handleLoad}
                    loaded={loaded}
                  />
                </Fade>
              </LazyLoad>
              {progress >= 100 && (
                <StyledCompleted>
                  <Typography
                    variant="h900"
                    color="text.primary"
                    component="h1"
                  >
                    ?
                  </Typography>
                </StyledCompleted>
              )}
            </StyledImageWrapper>
          )}
          <Typography
            variant="body4"
            color="text.primary"
            mt={{ xs: 2, xl: 4 }}
            mb={1}
            component="p"
          >
            {progress < 100
              ? 'NFT Generation Commenced'
              : 'Generation Process Completed'}
          </Typography>
          <Stack direction="row" alignItems="center">
            <ProgressBar value={progress} sx={{ width: '100%', mb: 0 }} />
            <Typography variant="body3" color="text.primary" ml={2}>
              {progress}%
            </Typography>
          </Stack>
          <Button
            type="button"
            variant="contained"
            color="primary"
            fullWidth
            disabled={progress < 100}
            sx={{ mt: { xs: 2, md: 3 } }}
            onClick={() => {
              setMinted(true);
              setMinting(false);
              setTxReceipt(null);
            }}
          >
            Check your NFTs
          </Button>
          <Alert
            severity="success"
            title="Transaction broadcasted!"
            sx={{ mt: { xs: 3, md: 4 } }}
          >
            <Typography variant="body2" color="text.secondary">
              Tx Hash:{' '}
              <a href={txReceipt?.txTerraFinderUrl} target="_blank">
                {shortTxId}
              </a>
            </Typography>
          </Alert>
        </StyledMinting>
      </div>
    </Fade>
  );
};

export default Minting;
