export const HOME = '/';
export const PROJECT = '/project';
export const PRIVACY_POLICY = '/privacy-policy';
export const TOS = '/tos';
export const WITHDRAW = '/withdraw';

//EXTERNAL LINKS
export const LANDING_PAGE = 'https://luart.io';
export const STAKING_APP = 'https://app.luart.io';
export const MARKETPLACE = 'https://marketplace.luart.io';
export const LUART_WALLET = 'https://marketplace.luart.io/account/wallet';
export const ACCOUNT_OWNED = 'https://marketplace.luart.io/account/owned';
export const COLLECTIONS = 'https://marketplace.luart.io/collections';
export const ALL_ACTIVITY = 'https://marketplace.luart.io/activity/all';
export const BRANDING =
  'https://drive.google.com/drive/folders/1bgtoEK9_AwKiJQTljNUko483qipCPkxE?usp=sharing';
export const APPLICATION_FORM = 'https://forms.gle/PjixvpiDC5i2jGMCA';
export const PRICING =
  'https://drive.google.com/file/d/1-Hra_0fSNcwrOIOHJzqOgiZHeB2bqAn1/view?usp=sharing';

// SOCIALS
export const DISCORD = 'https://discord.com/invite/luart';
export const TELEGRAM = 'https://t.me/luart_io';
export const TWITTER = 'https://twitter.com/luart_io';
export const MEDIUM = 'https://luart-io.medium.com';
