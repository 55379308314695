import React from 'react';
import LazyLoad from 'react-lazyload';
import { useState, useRef } from 'react';
import Fade from '@mui/material/Fade';
import {
  StyledImage,
  StyledImagePlaceholder,
  StyledImageWrapper,
} from './Image.styled';
import ZoomInButton from '@components/ZoomedImage/ZoomInButton';
import { ResizeIcon } from '@theme/icons';

interface ImageProps {
  imageURL: string;
  setZoomedImage: React.Dispatch<React.SetStateAction<null | string>>;
}

const Image: React.FC<ImageProps> = ({ imageURL, setZoomedImage }) => {
  const [loaded, setLoaded] = useState(false);
  const placeholderRef = useRef<HTMLDivElement>(null);

  const handleLoad = () => {
    placeholderRef?.current?.remove();
    setLoaded(true);
  };

  const handleShowZoomedImage = () => {
    setZoomedImage(imageURL);
    document.body.style.overflow = 'hidden';
  };

  return (
    <StyledImageWrapper>
      <StyledImagePlaceholder ref={placeholderRef} />
      <LazyLoad>
        <Fade in={true}>
          <StyledImage
            src={imageURL}
            alt="NFT"
            onLoad={handleLoad}
            onError={handleLoad}
            loaded={loaded}
          />
        </Fade>
      </LazyLoad>
      <ZoomInButton startIcon={<ResizeIcon />} onClick={handleShowZoomedImage}>
        Zoom in
      </ZoomInButton>
    </StyledImageWrapper>
  );
};

export default Image;
