import _, { Collection } from 'lodash';
import terraUtils from '../terra-utils';
import projects from '../nft-projects';
import luartCDN, { CollectionMetadata } from '../luart-cdn';
import { NFTTokenDetails } from '../../blockchain.interface';

// In this case we should fetch tokens using paging
async function getUserTokens(
  collectionContractAddress: string
): Promise<NFTTokenDetails[]> {
  const walletAddress = await terraUtils.getWalletAddress();

  // Getting NFT contract address
  const projectDetails = await projects.findProjectByAddress(
    collectionContractAddress
  );

  const nftContractAddress = projectDetails.nftContractAddress!;

  // Getting tokens metadata
  const tokensMetadata = await luartCDN.fetchCollectionMetadata(
    collectionContractAddress
  );

  // Getting tokens that user owns
  const response = await terraUtils.sendQuery(nftContractAddress, {
    tokens: {
      owner: walletAddress,
      limit: 30,
    },
  });

  return getMintedNFTsDetails(response.tokens, tokensMetadata);

  // return {
  //   mintedCount,
  //   mintedNFTs: ,
  //   maxTokensToMintForUser: projectDetails.maxTokensToMintForUser,
  // };
}

// We shuffle tokens
// Because sorting be the date of last minting was too complicated
function getMintedNFTsDetails(
  tokenIds: string[],
  tokensMetadata: CollectionMetadata
): NFTTokenDetails[] {
  return _.shuffle(tokenIds).map((tokenId: string) => tokensMetadata[tokenId]);
}

async function getRandomImageUrlFromCollection(
  collectionContractAddress: string
): Promise<string> {
  const images = await luartCDN.getRandomImageUrls(collectionContractAddress);
  const randomIndex = getRandomInt(images.length);
  return images[randomIndex];
}

async function getRandomTokensFromCollection(
  collectionContractAddress: string,
  tokensCount: number
) {
  let randomTokens: NFTTokenDetails[] = [];

  const tokensMetadata = await luartCDN.fetchCollectionMetadata(
    collectionContractAddress
  );

  const tokensLength = Object.keys(tokensMetadata).length;

  for (let i = 0; i < tokensCount; i++) {
    const randomTokenKey =
      Object.keys(tokensMetadata)[getRandomInt(tokensLength - 1)];

    randomTokens.push(tokensMetadata[randomTokenKey]);
  }

  return randomTokens;
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export default {
  getUserTokens,
  getRandomImageUrlFromCollection,
  getRandomTokensFromCollection,
};
