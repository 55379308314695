import styled from 'styled-components';
import MuiTypography from '@mui/material/Typography';

export const StyledLabel = styled.p`
  font-family: 'Inter', sans-serif;
  display: block;
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 600;
  background: linear-gradient(
    51.06deg,
    #b5caef 0.87%,
    #b5caef 25.96%,
    #bac5ee 49.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const StyledDescription = styled(MuiTypography)`
  background: linear-gradient(
    51.06deg,
    #88a7fe 0.87%,
    #99bfff 25.96%,
    #94e1ff 49.23%,
    #94e1ff 74.93%,
    #94e1ff 97.48%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
` as typeof MuiTypography;

export const StyledHero = styled.div`
  text-align: center;

  ${(props) => props.theme.breakpoints.up('md')} {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  p {
    color: #f8f9fa;
  }
`;

export const StyledGridContainer = styled.div<{ topbarDisplayed?: boolean }>`
  .MuiGrid-container {
    padding-top: 48px;

    ${(props) => props.theme.breakpoints.up('md')} {
      min-height: ${(props) =>
        props.topbarDisplayed
          ? 'calc(100vh - 70px - 20px - 46px)'
          : 'calc(100vh - 70px - 20px)'};
    }

    @media screen and (min-width: 1364px) {
      min-height: ${(props) =>
        props.topbarDisplayed
          ? 'calc(100vh - 70px - 30px - 31px)'
          : 'calc(100vh - 70px - 30px)'};
    }

    @media screen and (min-width: 1440px) {
      min-height: ${(props) =>
        props.topbarDisplayed
          ? 'calc(100vh - 70px - 40px - 31px)'
          : 'calc(100vh - 70px - 40px)'};
    }

    ${(props) => props.theme.breakpoints.up('xl')} {
      min-height: auto;
      height: ${(props) =>
        props.topbarDisplayed
          ? 'calc(100vh - 70px - 40px - 31px)'
          : 'calc(100vh - 70px - 40px)'};
      max-height: 915px;
    }
  }
`;

export const StyledRocketImage = styled.div`
  img {
    ${(props) => props.theme.breakpoints.up('md')} {
      height: 500px;
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      height: 550px;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      display: none;
    }
  }
`;
