import { StyledButtonClose, StyledModalContainer } from './Modal.styled';
import { ModalProps as MuiModalProps } from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { CloseIcon } from '@theme/icons';
import MuiModal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface ModalProps extends MuiModalProps {
  heading?: string | JSX.Element;
  subheading?: string | JSX.Element;
  description?: string | JSX.Element;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  width?: number;
  variant?: 'primary' | 'secondary';
  allowClose?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  heading,
  subheading,
  description,
  children,
  setOpen,
  width = 600,
  variant = 'primary',
  allowClose = true,
  ...rest
}) => {
  return (
    <MuiModal
      onClose={() => allowClose && setOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...rest}
    >
      <StyledModalContainer width={width}>
        {allowClose && (
          <StyledButtonClose
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </StyledButtonClose>
        )}
        <div style={{ width: '100%' }}>
          {heading && (
            <Typography
              variant={variant === 'primary' ? 'h600' : 'h400'}
              component="h3"
              color="text.primary"
              mb={1}
            >
              {heading}
            </Typography>
          )}
          {subheading && (
            <Typography
              variant="body4"
              color="text.primary"
              mb={3}
              component="p"
            >
              {subheading}
            </Typography>
          )}
          {description && (
            <Typography variant="body2" color="text.primary" component="p">
              {description}
            </Typography>
          )}
          <Box mt={4}>{children}</Box>
        </div>
      </StyledModalContainer>
    </MuiModal>
  );
};

export default Modal;
