import Layout from '@components/Layout/Layout';
import * as ROUTES from '@constants/routes';
import Heading from '@components/Heading/Heading';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WithdrawCard from './components/WithdrawCard';
import { useEffect, useState } from 'react';
import blockchain from '@utils/blockchain/blockchain';
import { Balance } from '@utils/blockchain/blockchain.interface';
import ContentContainer from '@components/ContentContainer/ContentContainer';
import Box from '@mui/material/Box';

const WithdrawPage = () => {
  const [balance, setBalance] = useState<Balance>({
    LUNA: 0,
    UST: 0,
  });

  const getWithdrawableBalance = async () => {
    try {
      const balance = await blockchain.getWithdrawableBalance();
      setBalance(balance);
    } catch (error) {
      console.log(error);
      setBalance({ LUNA: 0, UST: 0 });
    }
  };

  useEffect(() => {
    getWithdrawableBalance();
  }, []);

  return (
    <Layout>
      <ContentContainer>
        <Box pt={{ xs: 4, md: 8 }} pb={8}>
          <Heading variant="h800" component="h1" mb={2}>
            Introducing the Luart Wallet
          </Heading>
          <Typography variant="body2" color="text.primary" component="p" mb={6}>
            We would like to present our explorers with the Luart wallet. Any
            funds made through sales on our platform will go directly to you
            Luart wallet. <br />
            You funds will be safely stored on Luart and ready to use in an
            instant. You are also able to withdraw your funds to your Terra
            Wallet anytime.
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <WithdrawCard
                balance={balance.LUNA}
                currency="LUNA"
                setBalance={setBalance}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <WithdrawCard
                balance={balance.UST}
                currency="UST"
                setBalance={setBalance}
              />
            </Grid>
          </Grid>
        </Box>
      </ContentContainer>
    </Layout>
  );
};

export default WithdrawPage;
