import React, { useEffect } from 'react';
import {
  StyledBackdrop,
  StyledBackdropExit,
  StyledImageWrapper,
} from './ZoomedImage.styled';
import { CloseIcon } from '@theme/icons';
import ZoomInButton from './ZoomInButton';

interface ZoomedImageProps {
  setZoomedImage: React.Dispatch<React.SetStateAction<null | string>>;
  zoomedImage: string | null;
}

const ZoomedImage: React.FC<ZoomedImageProps> = ({
  setZoomedImage,
  zoomedImage,
}) => {
  const handleClose = () => {
    setZoomedImage(null);
    document.body.style.overflow = 'visible';
  };

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <StyledBackdrop>
      <StyledImageWrapper>
        {zoomedImage && <img src={zoomedImage} alt="NFT" />}
        <ZoomInButton onClick={handleClose}>
          <CloseIcon />
        </ZoomInButton>
      </StyledImageWrapper>
      <StyledBackdropExit onClick={handleClose} />
    </StyledBackdrop>
  );
};

export default ZoomedImage;
