import styled from 'styled-components';
import MuiGrid from '@mui/material/Grid';
import MuiStack from '@mui/material/Stack';

export const StyledProjectDetails = styled.div`
  a {
    color: ${(props) => props.theme.palette.text.primary};
    text-decoration: underline;
    transition: 350ms color;

    &:hover {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;

export const StyledContentStack = styled(MuiStack)`
  margin-bottom: 128px;

  ${(props) => props.theme.breakpoints.up('md')} {
    min-height: calc(100vh - 70px - 64px);
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    min-height: auto;
    height: calc(100vh - 70px - 64px);
    max-height: 900px;
  }
`;
