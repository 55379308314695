import CountInput from '@components/CountInput/CountInput';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import React, { useState, useCallback } from 'react';
import Button from '@components/Button/Button';
import SeparatedTexts from '@components/SeparatedTexts/SeparatedTexts';
import { debounce } from '@utils/debounce';
import {
  EstimatedCosts,
  ProjectDetails,
  TxReceipt,
  UserStatsForCollection,
} from '@utils/blockchain/blockchain.interface';
import blockchain from '@utils/blockchain/blockchain';

import { NewspaperIcon } from '@theme/icons';
import useBroadcastingTx from '@hooks/useBroadcastingTx';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import Box from '@mui/material/Box';
import Alert from '@components/Alert/Alert';
import { Link } from 'react-router-dom';
import { UserStatus } from './GetNFT';
import * as ROUTES from '@constants/routes';
import WalletSelector from '@components/WalletSelector/WalletSelector';
import {
  StyledMaxMintButton,
  StyledWhitelistAlertWrapper,
} from './MintForm.styled';
import { useDispatch } from 'react-redux';
import { getBalance } from '@store/actions/blockchain';

interface MintFormProps {
  project: ProjectDetails;
  mintedCount: number;
  maxTokensToMintForUser: number;
  maxMint: number;
  txReceipt: TxReceipt | null;
  setTxReceipt: React.Dispatch<React.SetStateAction<TxReceipt | null>>;
  tokenCount: number;
  setTokenCount: React.Dispatch<React.SetStateAction<number>>;
  setMinting: React.Dispatch<React.SetStateAction<boolean>>;
  userStatus: UserStatus;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  userProjectStats: UserStatsForCollection | null;
  setUserProjectStats: React.Dispatch<
    React.SetStateAction<UserStatsForCollection | null>
  >;
  setProject: React.Dispatch<
    React.SetStateAction<ProjectDetails | null | undefined>
  >;
  globallyMintedProgress: number;
}

const MintForm: React.FC<MintFormProps> = ({
  maxMint,
  project,
  txReceipt,
  setTxReceipt,
  setMinting,
  userStatus,
  count,
  setCount,
  userProjectStats,
  setUserProjectStats,
  setProject,
  globallyMintedProgress,
}) => {
  const { contractAddress, mintingAccess } = project;
  const [estimatedMintingCosts, setEstimatedMintingCosts] =
    useState<EstimatedCosts | null>(null);
  const [gettingEstimatedCosts, setGettingEstimatedCosts] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onSuccessBroadcast = () => {
    if (userProjectStats) {
      setUserProjectStats({
        ...userProjectStats,
        mintedCount: userProjectStats.mintedCount + count,
      });
    }

    setProject({
      ...project,
      alreadyMintedCount: project.alreadyMintedCount + count,
    });

    setCount(0);
    dispatch(getBalance());
    setMinting(true);
  };

  const onFailedBroadcast = () => {
    setErrorMessage(
      'Unfortunately, minting failed. Most probably you were late'
    );
  };

  const { loading, setLoading, loadingText } = useBroadcastingTx(
    txReceipt?.txId,
    onSuccessBroadcast,
    onFailedBroadcast
  );

  const getEstimatedMintingCosts = useCallback(
    debounce(async (tokensCount: number) => {
      try {
        const estimatedMintingCosts = await blockchain.estimateMintingCosts(
          contractAddress,
          tokensCount
        );
        setEstimatedMintingCosts(estimatedMintingCosts);
      } catch (error) {
        console.log(error);
        setEstimatedMintingCosts(null);
      }
      setGettingEstimatedCosts(false);
    }),
    [contractAddress]
  );

  const handleDecrement = () => {
    if (count > 0) {
      setGettingEstimatedCosts(true);
      setCount(count - 1);
      getEstimatedMintingCosts(count - 1);
    }
  };

  const handleIncrement = () => {
    if (count < maxMint) {
      setGettingEstimatedCosts(true);
      setCount(count + 1);
      getEstimatedMintingCosts(count + 1);
    }
  };

  const handleMaxMintClick = () => {
    setGettingEstimatedCosts(true);
    setCount(maxMint);
    getEstimatedMintingCosts(maxMint);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value === '') {
      value = '0';
      setEstimatedMintingCosts(null);
      setCount(parseInt(value));
    } else {
      if (parseInt(value) <= maxMint) {
        setGettingEstimatedCosts(true);
        getEstimatedMintingCosts(parseInt(value));
        setCount(parseInt(value));
      }
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading((prevLoading) => ({ ...prevLoading, send: true }));
    try {
      const txReceipt = await blockchain.mint(contractAddress, count);
      setTxReceipt(txReceipt);
    } catch (error) {
      console.log(error);
      setErrorMessage('There was an error while processing the transaction.');
      setEstimatedMintingCosts(null);
      setCount(0);
      setLoading((prevLoading) => ({ ...prevLoading, send: false }));
      setTxReceipt(null);
    }
  };

  const notReadyToSubmit =
    !count || !estimatedMintingCosts || gettingEstimatedCosts;

  const renderConditionalElements = () => {
    switch (userStatus) {
      case UserStatus.INITIALIZING:
        return <></>;
      case UserStatus.WALLET_NOT_CONNECTED:
        return (
          <WalletSelector
            isPrimary={true}
            btnNotConnectedId="mint-not-connected-button"
            btnConnectedId="mint-connected-button"
          />
        );
      case UserStatus.ALREADY_MINTED:
        return (
          <Alert
            title="You have already assembled your position"
            description="You have already minted your maximum allocation.
              Thank you for participating."
            severity="info"
          />
        );
      case UserStatus.NOT_ON_WHITELIST:
        return (
          <>
            <StyledWhitelistAlertWrapper>
              <Alert
                title="Not on whitelist"
                description="Sorry, you are not on the whitelist for this mint."
                severity="info"
                icon={
                  <Box color="text.secondary">
                    <NewspaperIcon fontSize="small" />
                  </Box>
                }
              >
                {/* <Typography
                  variant="h200"
                  color="text.primary"
                  component="p"
                  mt={1}
                >
                  Public Sale starts {formattedpublicMintingStartTime} UTC
                </Typography> */}
              </Alert>
            </StyledWhitelistAlertWrapper>
            <Link to={ROUTES.HOME}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3 }}
              >
                Check out other projects
              </Button>
            </Link>
          </>
        );
      case UserStatus.NO_LUA_POWER:
        return (
          <>
            <Alert
              title="You don't have enough LUA Power"
              description="Buy more $LUART. 1 token is 1 LUA Power."
              severity="warning"
            ></Alert>
            <a href={`${ROUTES.STAKING_APP}/trade`}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3 }}
              >
                Buy more $LUART
              </Button>
            </a>
          </>
        );
      case UserStatus.MINT_ALLOWED:
        return (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={notReadyToSubmit}
            fullWidth
            loading={loading.send || loading.broadcasting}
            loadingIndicator={<LoadingSpinner />}
          >
            {loadingText || 'Mint Now'}
          </Button>
        );
      case UserStatus.SOLD_OUT:
      case UserStatus.MINTING_FINISHED:
        return (
          <Link to={ROUTES.HOME}>
            <Button type="button" variant="contained" color="primary" fullWidth>
              Check out other projects
            </Button>
          </Link>
        );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {userStatus !== UserStatus.NOT_ON_WHITELIST && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h300" component="h6" color="text.primary">
              How many NFTs to mint?
            </Typography>
            <StyledMaxMintButton
              disabled={
                userStatus !== UserStatus.MINT_ALLOWED ||
                globallyMintedProgress >= 100
              }
              type="button"
              onClick={handleMaxMintClick}
            >
              Max mint: {maxMint}
            </StyledMaxMintButton>
          </Stack>
          <CountInput
            fullWidth
            placeholder="0"
            value={count === 0 ? '' : count}
            onChange={handleChange}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            disabled={
              userStatus !== UserStatus.MINT_ALLOWED ||
              globallyMintedProgress >= 100
            }
            min={0}
            count={count}
            max={maxMint}
            sx={{ mb: 3 }}
          />
        </>
      )}
      {renderConditionalElements()}
      {errorMessage && (
        <Alert
          severity="error"
          title="Transaction Failed!"
          description={errorMessage}
          onClose={() => setErrorMessage('')}
          sx={{ mt: 2 }}
        />
      )}
      {estimatedMintingCosts && count > 0 && (
        <Box mt={2}>
          <SeparatedTexts
            left="Luart Fee"
            right={estimatedMintingCosts.luartFee}
          />
          <SeparatedTexts left="Price" right={estimatedMintingCosts.price} />
          <SeparatedTexts left="Tx Fee" right={estimatedMintingCosts.txFee} />
        </Box>
      )}
    </form>
  );
};

export default MintForm;
