import {
  LCDClient,
  MsgExecuteContract,
  Coins,
  Coin,
  StdFee,
  Msg,
} from '@terra-money/terra.js';
import { Wallet } from '@terra-money/wallet-provider';
import { TxReceipt } from '../blockchain.interface';
import axios from 'axios';
import { sleep } from '../mock/utils';
import _ from 'lodash';

export const UST_DECIMALS = 6;
export const LUART_DECIMALS = 6;
export const LUNA_DECIMALS = 6;
export const LP_DECIMALS = 6;

let __cachedGasPrices__: object; // Used for lazy gas prices fetching
let __waitedForWallet__ = false;

type NetworkId = 'columbus-5' | 'bombay-12';

interface CoinsDetails {
  ust?: string;
  luna?: string;
}

interface TransactionDetails {
  contractAddress: string;
  message: object;
  coins?: CoinsDetails;
}

const LCD_URLS = {
  'bombay-12': 'https://bombay-lcd.terra.dev',
  
  'columbus-5': 'https://nameless-autumn-hill.terra-mainnet.quiknode.pro/26d007136990dab7e62c85c2e9c4d98a7964d617',
  // 'columbus-5': 'https://lcd.terra.dev',
};

const FCD_URLS = {
  'bombay-12': 'https://bombay-fcd.terra.dev',
  'columbus-5': 'https://fcd.terra.dev',
};

export const amountConverter = {
  luart: createAmountConverter(LUART_DECIMALS),
  luna: createAmountConverter(LUNA_DECIMALS),
  ust: createAmountConverter(UST_DECIMALS),
  lp: createAmountConverter(LP_DECIMALS),
};

let wallet: Wallet;

function setWallet(newWallet: Wallet) {
  console.log(`Setting a new wallet in blockchain.ts module`, { newWallet });
  wallet = newWallet;
}

async function getLCDClient(gasPrices?: any) {
  const url = await getLcdURL();
  const networkId = await getNetworkId();
  return new LCDClient({
    URL: url,
    chainID: networkId,
    gasPrices,
  });
}

async function getLcdURL(): Promise<string> {
  const networkId = await getNetworkId();
  return LCD_URLS[networkId];
}

async function getNetworkId(): Promise<NetworkId> {
  checkTerraNetworkParamInURL();

  if (localStorage.selectedNetworkId) {
    return localStorage.selectedNetworkId;
  }

  // We wait for wallet here only one time (right after the app code loading)
  if (!__waitedForWallet__) {
    // Waiting for wallet (max: 2 seconds)
    const maxTries = 10,
      retryIntervalMilliseconds = 200,
      throwErrorIfNoWallet = false;
    await getWalletAddress(
      maxTries,
      retryIntervalMilliseconds,
      throwErrorIfNoWallet
    ); // max duration: 2 seconds
    __waitedForWallet__ = true;
  }

  if (wallet) {
    const networkId = wallet.network.chainID as NetworkId;
    return networkId;
  } else {
    return 'columbus-5';
  }
}

async function isTestnet(): Promise<boolean> {
  const networkId = await getNetworkId();
  return networkId === 'bombay-12';
}

function checkTerraNetworkParamInURL(): void {
  const url = window.location.href;
  if (url.includes('use-testnet')) {
    localStorage.selectedNetworkId = 'bombay-12';
  }
  if (url.includes('use-mainnet')) {
    localStorage.selectedNetworkId = 'columbus-5';
  }
  if (url.includes('clear-network-selection')) {
    localStorage.removeItem('selectedNetworkId');
  }
}

async function lazyFetchGasPrices() {
  const networkId = await getNetworkId();

  if (!__cachedGasPrices__) {
    const response = await axios.get(
      FCD_URLS[networkId] + '/v1/txs/gas_prices'
    );
    __cachedGasPrices__ = _.pick(response.data, ['uusd']);
  }

  return __cachedGasPrices__;
}

async function getWalletAddress(
  maxTries: number = 30,
  retryIntervalMilliseconds: number = 1000,
  throwErrorIfNoWallet: boolean = true
): Promise<string> {
  for (let i = 0; i < maxTries; i++) {
    const address = wallet?.wallets[0]?.terraAddress;
    if (address) {
      return address;
    } else {
      console.log(
        `Haven't got wallet address. Retrying in ${retryIntervalMilliseconds} ms`
      );
      await sleep(retryIntervalMilliseconds);
    }
  }

  if (throwErrorIfNoWallet) {
    throw new Error('Unable to get wallet address');
  } else {
    return '';
  }
}

// Returns UST balance on the user's wallet
export async function getBalanceUST(): Promise<number> {
  const address = await getWalletAddress();
  const fcdUrl = await getLcdURL();
  const response = await axios.get(`${fcdUrl}/bank/balances/${address}`);
  for (const coin of response.data.result) {
    if (coin.denom === 'uusd') {
      return amountConverter.ust.blockchainValueToUserFacing(coin.amount);
    }
  }

  // If uusd not found in the coin list, return 0
  return 0;
}

export async function getBalanceLUNA(): Promise<number> {
  const address = await getWalletAddress();
  const fcdUrl = await getLcdURL();
  const response = await axios.get(`${fcdUrl}/bank/balances/${address}`);

  for (const coin of response.data.result) {
    if (coin.denom === 'uluna') {
      return amountConverter.luna.blockchainValueToUserFacing(coin.amount);
    }
  }

  return 0;
}

async function sendQuery(contractAddress: string, query: object): Promise<any> {
  const lcdClient = await getLCDClient();
  return await lcdClient.wasm.contractQuery(contractAddress, query);
}

async function postTransaction(
  tx: TransactionDetails,
  useDefaultTxFeeIfEstimationFails?: boolean
): Promise<TxReceipt> {
  return postManyTransactions([tx], useDefaultTxFeeIfEstimationFails);
}

async function estimateTxFee(messages: Msg[], useDefaultTxFeeIfEstimationFails: boolean) {
  // TODO: uncomment this to test failed mints
  // return new StdFee(1000000, '500000uusd');

  const address = await getWalletAddress();

  const gasPrices = await lazyFetchGasPrices();
  const lcdClient = await getLCDClient(gasPrices);

  try {
    const fee = await lcdClient.tx.estimateFee(address, messages, {
      feeDenoms: ['uusd'],
    });
    return fee;
  } catch (e) {
    if (useDefaultTxFeeIfEstimationFails) {
      return new StdFee(1000000, '500000uusd');
    } else {
      throw e;
    }
  }
}

async function postManyTransactions(
  txs: TransactionDetails[],
  useDefaultTxFeeIfEstimationFails?: boolean
): Promise<TxReceipt> {
  checkWallet('postManyTransactions');

  const address = await getWalletAddress();
  const msgs = txs.map((tx) => {
    const coins = getCoinsConfig(tx.coins);
    return new MsgExecuteContract(
      address,
      tx.contractAddress,
      tx.message,
      coins
    );
  });

  const fee = await estimateTxFee(msgs, useDefaultTxFeeIfEstimationFails || false);

  const tx = await wallet.post({
    fee,
    msgs,
  });
  const txId = tx.result.txhash;

  // TODO: improve result fee fetching
  return {
    txId,
    txFee: '< 5UST',
    txTerraFinderUrl: await getTerraUrlForTxId(txId),
  };
}

// It turned out that limit must be one of [10, 100]
// Commented for now, because it is not used anywhere
// async function loadLatestTransactionsForUser(
//   address: string,
//   limit: number = 10
// ): Promise<any[]> {
//   const networkId = await getNetworkId();
//   const fcdUrl = FCD_URLS[networkId] + '/v1/txs';

//   // Fetching data
//   const response = await axios.get(fcdUrl, {
//     params: {
//       offset: 0,
//       limit,
//       account: address,
//     },
//   });

//   return response.data.txs;
// }

async function getTxResult(txHash: string): Promise<any> {
  const networkId = await getNetworkId();
  const response = await axios.get(`${FCD_URLS[networkId]}/v1/tx/${txHash}`);
  return response;
}

// Checking is terra gateway working properly
async function pingTerraGateway(): Promise<any> {
  const networkId = await getNetworkId();
  const response = await axios.get(`${FCD_URLS[networkId]}/syncing`);
  return response;
}

function getCoinsConfig(coins?: CoinsDetails): Coins.Input | undefined {
  if (coins) {
    const coinObjects = [];
    if (coins.luna) {
      const lunaCoin = Coin.fromData({ denom: 'uluna', amount: coins.luna });
      coinObjects.push(lunaCoin);
    }
    if (coins.ust) {
      const utsCoin = Coin.fromData({ denom: 'uusd', amount: coins.ust });
      coinObjects.push(utsCoin);
    }
    return new Coins(coinObjects);
  } else {
    return undefined;
  }
}

function checkWallet(parentFunctionName: string): void {
  if (!wallet) {
    throw new Error(`${parentFunctionName} function requires connected wallet`);
  }
}

async function getTerraUrlForTxId(txId: string): Promise<string> {
  const networkId = await getNetworkId();

  return `https://finder.terra.money/${networkId}/tx/${txId}`;
}

function createAmountConverter(decimals: number) {
  return {
    userFacingToBlockchainValue: (amount: number) =>
      String(Math.ceil(amount * 10 ** decimals)),
    blockchainValueToUserFacing: (amount: any) =>
      Number(amount) / 10 ** decimals,
  };
}

export default {
  sendQuery,
  postTransaction,
  postManyTransactions,
  getNetworkId,
  isTestnet,
  getWalletAddress,
  setWallet,
  getBalanceUST,
  getBalanceLUNA,
  amountConverter,
  getTxResult,
  pingTerraGateway,
};
