import Card from '@components/Card/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import Badge from '@components/Badge/Badge';
import {
  ProjectDetails,
  TxReceipt,
  UserStatsForCollection,
  MintingStage,
} from '@utils/blockchain/blockchain.interface';
import MintForm from './MintForm';
import { getProgressPercentage } from '@utils/getProgressPercentage';
import { useWallet, WalletStatus } from '@terra-money/wallet-provider';
import { getCountdown, isDateOlderThanOneYear } from '@utils/date';
import { useState, useEffect } from 'react';
import { formatInteger, formatDecimal } from '@utils/formatNumbers';
import { formatLUNADecimal, formatUSTDecimal } from '@utils/currency';
import { StyledGetNFT, StyledContentStack } from './GetNFT.styled';
import { formatInTimeZone } from 'date-fns-tz';

export enum UserStatus {
  INITIALIZING,
  WALLET_NOT_CONNECTED,
  NO_LUA_POWER,
  NOT_ON_WHITELIST,
  ALREADY_MINTED,
  MINT_ALLOWED,
  SOLD_OUT,
  MINTING_FINISHED,
}

interface GetNFTProps {
  project: ProjectDetails;
  userProjectStats: UserStatsForCollection | null;
  txReceipt: TxReceipt | null;
  setTxReceipt: React.Dispatch<React.SetStateAction<TxReceipt | null>>;
  tokenCount: number;
  setTokenCount: React.Dispatch<React.SetStateAction<number>>;
  setMinting: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  setUserProjectStats: React.Dispatch<
    React.SetStateAction<UserStatsForCollection | null>
  >;
  setProject: React.Dispatch<
    React.SetStateAction<ProjectDetails | null | undefined>
  >;
  activeMintingStage: MintingStage;
}

const GetNFT: React.FC<GetNFTProps> = ({
  project,
  userProjectStats,
  txReceipt,
  setTxReceipt,
  tokenCount,
  setTokenCount,
  setMinting,
  count,
  setCount,
  setUserProjectStats,
  setProject,
  activeMintingStage,
}) => {
  const {
    minimalRequiredLuaPower,
    luaPowerMint,
    totalTokensCount,
    alreadyMintedCount,
    mintingAccess,
  } = project;

  const [countdown, setCountdown] = useState({
    countdown: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    formattedCountdown: '00d 00h 00m',
    finished: false,
  });

  const maxTokensToMintForUser = userProjectStats?.maxTokensToMintForUser || 0;
  const mintedCount = userProjectStats?.mintedCount || 0;

  const mintPrice = activeMintingStage.mintPrice;
  const mintCurrency = activeMintingStage.mintCurrency;

  const globallyMintedProgress = getProgressPercentage(
    alreadyMintedCount,
    totalTokensCount
  );

  const userMintedProgress = getProgressPercentage(
    mintedCount,
    maxTokensToMintForUser || 100
  );

  const getFormattedTime = (timestamp?: number) => {
    if (!timestamp) return '';

    return formatInTimeZone(timestamp, 'Africa/Abidjan', "d MMM h:mmaaaaa'm'");
  };

  const formattedStartDate = getFormattedTime(activeMintingStage.startTime);
  const formattedEndDate = getFormattedTime(activeMintingStage.endTime);
  const endDate = activeMintingStage.endTime;

  useEffect(() => {
    const countdown = getCountdown(endDate || 0);
    setCountdown(countdown);
  }, [endDate]);

  const wallet = useWallet();
  const walletStatus = wallet.status;
  const userTerraAddress = wallet?.wallets[0]?.terraAddress;

  const walletNotConnected = walletStatus === WalletStatus.WALLET_NOT_CONNECTED;

  const getUserStatus = () => {
    if (!project || walletStatus === WalletStatus.INITIALIZING) {
      return UserStatus.INITIALIZING;
    } else if (alreadyMintedCount >= totalTokensCount) {
      return UserStatus.SOLD_OUT;
    } else if (countdown.finished) {
      return UserStatus.MINTING_FINISHED;
    } else if (walletNotConnected) {
      return UserStatus.WALLET_NOT_CONNECTED;
    } else if (userProjectStats?.reason === 'not_on_whitelist') {
      return UserStatus.NOT_ON_WHITELIST;
      // } else if (userLuaPower < minimalRequiredLuaPower) {
      //   return UserStatus.NO_LUA_POWER;
    } else if (userProjectStats && mintedCount >= maxTokensToMintForUser) {
      return UserStatus.ALREADY_MINTED;
    } else {
      return UserStatus.MINT_ALLOWED;
    }
  };

  const userStatus = getUserStatus();

  const getMaxMint = () => {
    const currentUserMaxMint = maxTokensToMintForUser - mintedCount;

    const availableTokensCount = totalTokensCount - alreadyMintedCount;

    const maxMintForUser =
      currentUserMaxMint > availableTokensCount
        ? availableTokensCount
        : currentUserMaxMint;

    return maxMintForUser > 0 ? maxMintForUser : 0;
  };

  const maxMint = getMaxMint();

  return (
    <StyledGetNFT>
      <StyledContentStack direction="column" justifyContent="space-between">
        <div>
          <Stack
            direction={{ xs: 'row', md: 'column', lg: 'row' }}
            alignItems={{ xs: 'center', md: 'flex-start', lg: 'center' }}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography
              variant="h400"
              component="h3"
              mb={{ md: 2, lg: 0 }}
              noWrap
            >
              {userStatus === UserStatus.SOLD_OUT
                ? 'SOLD OUT!'
                : 'Get your NFT'}
            </Typography>
            {userStatus === UserStatus.SOLD_OUT ||
            userStatus === UserStatus.MINTING_FINISHED ? (
              <Badge
                size="large"
                color="alpha"
                text="FINISHED"
                dotColor="danger"
              />
            ) : (
              <Badge
                size="large"
                color="alpha"
                text="LIVE"
                secondaryText={
                  isDateOlderThanOneYear(endDate)
                    ? undefined
                    : countdown.formattedCountdown
                }
                dotColor="success"
              />
            )}
          </Stack>
          <Typography
            variant="body2"
            component="p"
            color="text.secondary"
            mb={1}
          >
            {isDateOlderThanOneYear(endDate) ? (
              <>
                Start date:{' '}
                <Typography
                  variant="h200"
                  component="span"
                  color="text.primary"
                >
                  {formattedStartDate} UTC
                </Typography>
              </>
            ) : (
              <>
                Minting date:{' '}
                <Typography
                  variant="h200"
                  component="span"
                  color="text.primary"
                >
                  {formattedStartDate} - {formattedEndDate} UTC
                </Typography>
              </>
            )}
          </Typography>
          {luaPowerMint && (
            <Typography
              variant="body2"
              component="p"
              color="text.secondary"
              mb={1}
            >
              LUA Power Multiplier:{' '}
              <Typography variant="h200" component="span" color="text.primary">
                {luaPowerMint}
              </Typography>
            </Typography>
          )}
          <Typography
            variant="body2"
            component="p"
            color="text.secondary"
            mb={1}
          >
            Price:{' '}
            <Typography variant="h200" component="span" color="text.primary">
              {mintCurrency === 'LUNA'
                ? formatLUNADecimal(mintPrice)
                : formatUSTDecimal(mintPrice)}
            </Typography>
          </Typography>
          {mintingAccess === 'whitelisted' && (
            <Typography
              variant="body2"
              component="p"
              color="text.secondary"
              mb={1}
            >
              LUA Power Whitelist:{' '}
              <Typography variant="h200" component="span" color="text.primary">
                {minimalRequiredLuaPower <= 0
                  ? 'N/A'
                  : formatDecimal(minimalRequiredLuaPower)}
              </Typography>
            </Typography>
          )}
          {/* {walletStatus === WalletStatus.WALLET_CONNECTED &&
            mintingAccess === 'whitelisted' && (
              <Typography variant="body2" component="p" color="text.secondary">
                Your LUA Power:{' '}
                <Typography
                  variant="h200"
                  component="span"
                  color="text.primary"
                >
                  {formatDecimal(userLuaPower)}
                </Typography>
              </Typography>
            )} */}
          <Box mt={4}>
            <MintForm
              maxMint={maxMint}
              maxTokensToMintForUser={maxTokensToMintForUser}
              mintedCount={mintedCount}
              project={project}
              txReceipt={txReceipt}
              setTxReceipt={setTxReceipt}
              tokenCount={tokenCount}
              setTokenCount={setTokenCount}
              setMinting={setMinting}
              userStatus={userStatus}
              userProjectStats={userProjectStats}
              count={count}
              setCount={setCount}
              setProject={setProject}
              setUserProjectStats={setUserProjectStats}
              globallyMintedProgress={globallyMintedProgress}
            />
          </Box>
        </div>
        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ mt: 3 }}>
            <ProgressBar
              value={globallyMintedProgress}
              leftText="Globally minted"
              rightText={`${formatInteger(
                alreadyMintedCount
              )} / ${formatInteger(totalTokensCount)}`}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <ProgressBar
              value={
                walletStatus !== WalletStatus.WALLET_CONNECTED
                  ? 0
                  : userMintedProgress
              }
              leftText="You have minted"
              rightText={`${
                walletStatus !== WalletStatus.WALLET_CONNECTED ? 0 : mintedCount
              } / ${maxTokensToMintForUser}`}
            />
          </Box>
        </Box>
      </StyledContentStack>
    </StyledGetNFT>
  );
};

export default GetNFT;
