import styled from 'styled-components';

export const StyledImage = styled.img<{ loaded?: boolean }>`
  border-radius: 16px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(props) => (props.loaded ? 1 : 0)} !important;
  outline: 8px solid rgba(255, 255, 255, 0.18);
  outline-offset: -8px;
  border-radius: 8px;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 325px;

  @media screen and (min-width: 450px) {
    height: 400px;
  }

  @media screen and (min-width: 600px) {
    height: 450px;
  }

  @media screen and (min-width: 700px) {
    height: 500px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 95%;
    margin: 0 auto;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    height: 528px;
  }
`;

export const StyledImagePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
`;
