import Typography from '@mui/material/Typography';
import {
  StyledBadge,
  StyledBadgeProps,
  StyledDot,
  StyledDotContainer,
} from './Badge.styled';
import Stack from '@mui/material/Stack';

interface BadgeProps extends StyledBadgeProps {
  text: string;
  secondaryText?: string;
  dotColor?: 'success' | 'warning' | 'danger';
}

const Badge: React.FC<BadgeProps> = ({
  text,
  secondaryText,
  color = 'success',
  size = 'medium',
  dotColor,
}) => {
  const typographyVariant = {
    small: 'body1' as 'body1',
    medium: 'body2' as 'body2',
    large: 'body3' as 'body3',
  };

  return (
    <StyledBadge color={color} size={size}>
      <Stack direction="row" alignItems="center" justifyContent="space-beetwen">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          {dotColor && (
            <StyledDotContainer>
              <StyledDot dotColor={dotColor} />
            </StyledDotContainer>
          )}
          <Typography
            variant={typographyVariant[size]}
            component="span"
            color="text.primary"
            noWrap
          >
            {text}
          </Typography>
        </Stack>
        {secondaryText && (
          <Typography
            variant={typographyVariant[size]}
            component="span"
            color="text.secondary"
            ml={2}
            sx={{ minWidth: { xs: 71, md: 95 } }}
            noWrap
          >
            {secondaryText}
          </Typography>
        )}
      </Stack>
    </StyledBadge>
  );
};

export default Badge;
