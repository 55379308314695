import Layout from '@components/Layout/Layout';
import ProjectDetails from './components/ProjectDetails/ProjectDetails';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ContentContainer from '@components/ContentContainer/ContentContainer';
import { useSelector } from 'react-redux';
import { State } from '@store/store';
import {
  ProjectDetails as Project,
  TxReceipt,
  UserStatsForCollection,
  NFTTokenDetails,
} from '@utils/blockchain/blockchain.interface';
import Typography from '@mui/material/Typography';
import blockchain from '@utils/blockchain/blockchain';
import Minted from './components/Minted/Minted';
import Minting from './components/Minting/Minting';
import { useWallet, WalletStatus } from '@terra-money/wallet-provider';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import SafetyNotice from './components/SafetyNotice';

interface MatchParams {
  id: string;
}

const ProjectPage: React.FC<RouteComponentProps<MatchParams>> = (props) => {
  const contractAddress = props.match.params.id;
  const { projects } = useSelector((state: State) => state.blockchain);
  const [project, setProject] = useState<null | undefined | Project>(null);
  const [loading, setLoading] = useState(true);
  const [userProjectStats, setUserProjectStats] =
    useState<UserStatsForCollection | null>(null);
  const [minting, setMinting] = useState(false);
  const [minted, setMinted] = useState(false);
  const [txReceipt, setTxReceipt] = useState<TxReceipt | null>(null);
  const [tokenCount, setTokenCount] = useState(0);
  const [count, setCount] = useState(0);

  const { status: walletStatus } = useWallet();

  const fetchData = async () => {
    if (walletStatus === WalletStatus.WALLET_CONNECTED) {
      const userProjectStats = await blockchain.getUserStatsForCollection(
        contractAddress
      );

      setUserProjectStats(userProjectStats);
    }

    if (projects) {
      const project = [...projects.live, ...projects.finished].find(
        (project) => project.contractAddress === contractAddress
      );

      setProject(project);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (projects) {
      fetchData();
    }
  }, [projects, walletStatus]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 100);

    return () => clearTimeout(timeout);
  }, [minted, minting]);

  const renderView = () => {
    if (minting)
      return (
        <Minting
          setMinting={setMinting}
          setMinted={setMinted}
          contractAddress={contractAddress}
          txReceipt={txReceipt}
          setTxReceipt={setTxReceipt}
          sampleDrops={project?.sampleDrops || []}
        />
      );
    else if (minted) {
      return (
        <Minted
          setMinted={setMinted}
          previousMintedNFTs={userProjectStats?.mintedNFTs}
          project={project}
          collectionContractAddress={contractAddress}
        />
      );
    } else
      return (
        <>
          {loading ? (
            <LoadingSpinner size="large" color="secondary" addText center />
          ) : project ? (
            <ProjectDetails
              project={project}
              userProjectStats={userProjectStats}
              txReceipt={txReceipt}
              setTxReceipt={setTxReceipt}
              tokenCount={tokenCount}
              setTokenCount={setTokenCount}
              setMinting={setMinting}
              count={count}
              setCount={setCount}
              setUserProjectStats={setUserProjectStats}
              setProject={setProject}
            />
          ) : (
            <Typography variant="body2" color="text.primary" mt={4}>
              Project not found
            </Typography>
          )}
        </>
      );
  };

  return (
    <>
      <Layout>
        <ContentContainer>{renderView()}</ContentContainer>
      </Layout>
      <SafetyNotice />
    </>
  );
};

export default ProjectPage;
