import styled from 'styled-components';

export interface StyledAppleIconProps {
  size?: 'small' | 'large';
}

export const StyledAppleIcon = styled.img<StyledAppleIconProps>`
  width: 24px;
  margin-bottom: -4px;
  margin-right: 8px;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${(props) => (props.size === 'small' ? '40px' : '64px')};
    margin-bottom: -6px;
    margin-right: 16px;
  }
`;
