import { palette } from '@theme/palette';
import styled from 'styled-components';

export const StyledMaxMintButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 14px;
  color: ${(props) => props.theme.palette.text.secondary};
  text-decoration: underline;
  transition: color 350ms;
  font-family: 'Libre Franklin', sans-serif;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }

  &:disabled {
    &:hover {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;

export const StyledWhitelistAlertWrapper = styled.div`
  .MuiAlert-filledInfo {
    background: ${palette.alphaDark[50]};
    border: 1px solid ${palette.alphaLight[200]};
  }
`;
