import { StyledTopbar } from './Topbar.styled';
import Typography from '@mui/material/Typography';

const Topbar = () => {
  return (
    <StyledTopbar>
      <Typography variant="body1" color="text.primary">
        Sorry, we are experiencing problems trying to connnect to the public
        Terra Gateway. Looks like other applications like terra station also
        can’t work correctly right now. Please check the app a bit later. Sorry
        for the inconvenience :(
      </Typography>
    </StyledTopbar>
  );
};

export default Topbar;
