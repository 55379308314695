import styled from 'styled-components';

export const StyledSwiperContainer = styled.div`
  position: relative;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 0 112px;
    margin-top: 0;
  }
`;

export const StyledSwiperButtonWrapper = styled.div<{
  position?: 'left' | 'right';
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => (props.position === 'left' ? '0' : 'auto')};
  right: ${(props) => (props.position === 'right' ? '0' : 'auto')};
`;

export const StyledConfettiContainer = styled.div`
  position: fixed;
  left: 0;
  top: 70px;
`;
