import styled from 'styled-components';
import MuiAppBar from '@mui/material/AppBar';
import { palette } from '@theme/palette';

export const StyledAppBar = styled(MuiAppBar)`
  height: 70px;
  background: #262c4a;
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  z-index: 2;

  ${(props) => props.theme.breakpoints.up('lg')} {
    background: ${palette.alphaLight[50]};
    backdrop-filter: blur(64px);
  }

  .MuiToolbar-root {
    min-height: auto;
    padding: 0;
  }
`;

export const StyledLogo = styled.img`
  width: 82px;
  margin-top: 2px;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 108px;
    height: 42px;
  }
`;

export const StyledNavContainer = styled.div<{ open?: boolean }>`
  position: absolute;
  top: 54px;
  width: 100vw;
  min-height: calc(100vh - 70px);
  left: -16px;
  background: #1d2343;
  z-index: 3;
  padding: 24px 32px 92px 32px;
  display: ${(props) => (props.open ? 'flex' : 'none')};
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.up('md')} {
    position: relative;
    padding: 0;
    background: transparent;
    top: auto;
    left: auto;
    z-index: 1;
    position: relative;
    width: auto;
    min-height: auto;
    display: block;
  }
`;

export const StyledNavLink = styled.li<{
  active?: boolean;
  disabled?: boolean;
}>`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-right: 24px;
  }

  a {
    font-family: 'Inter', sans-serif;
    transition: color 350ms;
    font-size: 22px;
    font-weight: 700;
    line-height: 34px;
    color: ${(props) =>
      props.active
        ? props.theme.palette.text.primary
        : props.theme.palette.text.secondary};

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    ${(props) => props.theme.breakpoints.up('md')} {
      font-size: 14px;
      font-weight: 600;
    }

    &:hover {
      color: ${(props) =>
        props.disabled
          ? props.theme.palette.text.secondary
          : props.theme.palette.text.primary};
    }
  }
`;

export const StyledIconButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 20px;
  }
`;

export const StyledTestnetBadge = styled.div`
  font-family: 'Libre Franklin', sans-serif;
  font-size: 10px;
  background: ${(props) => props.theme.palette.error.light};
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 4px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 4px 6px;
    border-radius: 8px;
    font-size: 12px;
  }
`;
