import styled from 'styled-components';
import MuiCard from '@mui/material/Card';

export const StyledCard = styled(MuiCard)`
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 24px;
  box-shadow: none;
  border-radius: 8px;
`;
