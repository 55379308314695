import React from 'react';
import { StyledCountButton, StyledCountInput } from './CountInput.styled';
import { InputProps as MuiInputProps } from '@mui/material/Input';
import { MinusIcon, PlusIcon } from '@theme/icons';

interface CountInputProps extends MuiInputProps {
  handleDecrement: () => void;
  handleIncrement: () => void;
  min: number;
  max: number;
  count: number;
}

const CountInput: React.FC<CountInputProps> = ({
  handleDecrement,
  handleIncrement,
  min,
  max,
  count,
  disabled,
  ...rest
}) => {
  const pattern: RegExp = new RegExp('[0-9]');

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!pattern.test(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData?.getData('text');

    if (!pattern.test(pastedText)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <StyledCountInput
      type="number"
      onKeyPress={handleKeyPress}
      onPaste={handlePaste}
      disabled={disabled}
      startAdornment={
        <StyledCountButton
          type="button"
          onClick={handleDecrement}
          active={count > min}
          disabled={disabled || count === min}
        >
          <MinusIcon fontSize="small" />
        </StyledCountButton>
      }
      endAdornment={
        <StyledCountButton
          type="button"
          onClick={handleIncrement}
          active={count < max}
          disabled={disabled || count === max}
        >
          <PlusIcon fontSize="small" />
        </StyledCountButton>
      }
      {...rest}
    />
  );
};

export default CountInput;
