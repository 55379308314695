import Card from '@components/Card/Card';
import MuiStack from '@mui/material/Stack';
import styled from 'styled-components';

export const StyledGetNFT = styled(Card)`
  @media (min-height: 700px) and (min-width: 1200px) {
    min-height: calc(100vh - 70px - 64px);
    position: sticky;
    top: 32px;
  }

  @media (min-height: 700px) and (min-width: 1400px) {
    min-height: auto;
    height: calc(100vh - 70px - 64px);
    max-height: 900px;
  }

  a {
    text-decoration: none !important;
  }
`;

export const StyledContentStack = styled(MuiStack)`
  @media (min-height: 700px) and (min-width: 992px) {
    min-height: calc(100vh - 70px - 64px - 50px);
  }

  @media (min-height: 700px) and (min-width: 1400px) {
    min-height: 100%;
  }
`;
