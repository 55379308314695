import { ThemeProvider } from '@mui/material/styles';
import Router from './Router';
import theme from '@theme/theme';
import GlobalStyles from './Global.styled';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { State } from '@store/store';
import {
  getProjects,
  getBalance,
  pingTerraGateway,
} from '@store/actions/blockchain';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'add-to-calendar-button/assets/css/atcb.min.css';

const App = () => {
  const dispatch = useDispatch();
  const { projects, balance, loading, errors } = useSelector(
    (state: State) => state.blockchain
  );

  useEffect(() => {
    if (!projects && loading?.getProjects) dispatch(getProjects());
    if (!balance && loading?.getBalance) dispatch(getBalance());
    if (!errors?.pingTerraGateway && !loading?.pingTerraGateway)
      dispatch(pingTerraGateway());
  }, [dispatch, projects]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  );
};

export default App;
