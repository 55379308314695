import styled from 'styled-components';
import MuiInput from '@mui/material/Input';
import { palette } from '@theme/palette';

export const StyledInput = styled(MuiInput)`
  height: 48px;
  padding: 12px 16px;
  background: ${palette.alphaLight[50]};
  border: 1px solid ${palette.alphaLight[200]};
  border-radius: 6px;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 24px;
  opacity: 1 !important;
  cursor: ${(props) => (props.readOnly ? 'not-allowed' : 'auto')};

  input {
    padding: 0;
    opacity: 1 !important;
    cursor: ${(props) => (props.readOnly ? 'not-allowed' : 'auto')};
  }
`;
