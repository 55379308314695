import styled from 'styled-components';

export const StyledSocialLinks = styled.div`
  a {
    color: ${(props) => props.theme.palette.text.secondary};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;
