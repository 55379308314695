import axios from 'axios';
import terraUtils from './terra-utils';
import { NFTTokenDetails, ProjectDetails } from '../blockchain.interface';
import nftProjects from './nft-projects';
import nftProjectsConfig from './nft-projects-config/index';

// The CDN is heavily optimised with GZIP compression and efficient
const BASE_CDN_URL = 'https://cdn.luart.io';
export const DYNAMIC_VALUE = 'dynamic';

const __cache__: any = {};

export interface CollectionMetadata {
  [tokenId: string]: NFTTokenDetails;
}

async function getDataUrl(): Promise<string> {
  const isTestnet = await terraUtils.isTestnet();
  const dataFolder = isTestnet ? 'testnet' : 'mainnet';
  return `${BASE_CDN_URL}/${dataFolder}`;
}

async function fetchProjects(): Promise<ProjectDetails[]> {
  // Lazy fetching
  if (!__cache__.projects) {
    // (OLD) Load from luart CDN
    // const responseAxios = await axios.get(projectsUrl);
    // const response = responseAxios.data;

    // (NEW) Load from web app code
    const chainId = await terraUtils.getNetworkId();
    const response = (nftProjectsConfig as any)[chainId];

    __cache__.projects = convertProjectsResponseToProjectsDetails(response);
  }

  return __cache__.projects;
}

async function fetchCollectionMetadata(
  nftLaunchContracAddress: string
): Promise<CollectionMetadata> {
  const projectDetails = await nftProjects.findProjectByAddress(
    nftLaunchContracAddress
  );
  const nftContractAddress = projectDetails.nftContractAddress!;

  // Lazy fetching
  if (!__cache__[nftContractAddress]) {
    const collectionMetadataUrl =
      (await getDataUrl()) + `/${nftContractAddress}/nft-compact-metadata.json`;
    const response = await axios.get(collectionMetadataUrl);
    const metadataWithRarity = addRarityToMetadata(response.data);
    __cache__[nftContractAddress] = metadataWithRarity;
  }

  return __cache__[nftContractAddress];
}

function convertProjectsResponseToProjectsDetails(
  responseData: any
): ProjectDetails[] {
  return responseData.map((project: any) => {
    const currentTimestamp = Date.now();

    // Set status
    if (project.mintingStages) {
      const firstStageStart = project.mintingStages[0].startTime;
      const lastStageEnd =
        project.mintingStages[project.mintingStages.length - 1].endTime;

      if (project.status === DYNAMIC_VALUE) {
        if (
          currentTimestamp >= firstStageStart &&
          currentTimestamp < lastStageEnd
        ) {
          project.status = 'live';
        } else if (currentTimestamp > lastStageEnd) {
          project.status = 'previous';
        } else {
          project.status = 'upcoming';
        }
      }
    } else {
      project.status = 'upcoming';
    }

    // Calculate reservedTokensCount
    project.reservedTokensCount = project.reservedTokensCount || 0;

    return project;
  });
}

function addRarityToMetadata(fetchedMetadata: any): CollectionMetadata {
  const rarityConfig: any = {};
  let total = Object.keys(fetchedMetadata).length;

  // Calculating rarity
  for (const tokenId of Object.keys(fetchedMetadata)) {
    for (const [traitName, traitValue] of Object.entries(
      fetchedMetadata[tokenId].traits
    )) {
      if (!rarityConfig[traitName]) {
        rarityConfig[traitName] = {};
      }
      if (!rarityConfig[traitName][traitValue as string]) {
        rarityConfig[traitName][traitValue as string] = 0;
      }
      rarityConfig[traitName][traitValue as string]++;
    }
  }

  // Preparing response object
  const updatedMetadata = { ...fetchedMetadata };
  for (const tokenId of Object.keys(updatedMetadata)) {
    for (const traitName of Object.keys(updatedMetadata[tokenId].traits)) {
      const value = updatedMetadata[tokenId].traits[traitName];
      const rarity = toFixedIfNecessary(
        (rarityConfig[traitName][value] / total) * 100,
        2
      ); // rarity percentage
      updatedMetadata[tokenId].traits[traitName] = { value, rarity };
    }
  }

  return updatedMetadata;
}

async function getRandomImageUrls(collectionAddress: string): Promise<string> {
  const urlPrefix = (await getDataUrl()) + `/${collectionAddress}`;
  const url = `${urlPrefix}/random-image-names.json`;
  const response = await axios.get(url);
  return response.data.map(
    (imageName: string) => `${urlPrefix}/random-images/${imageName}`
  );
}

function toFixedIfNecessary(value: any, dp: number) {
  return Number(parseFloat(value).toFixed(dp));
}

export default {
  fetchProjects,
  fetchCollectionMetadata,
  getRandomImageUrls,
};
