import Hero from './components/Hero/Hero';
import Layout from '@components/Layout/Layout';
import Newsletter from '@components/Newsletter/Newsletter';
import Projects from './components/Projects/Projects';
import { useSelector } from 'react-redux';
import { State } from '@store/store';
import Alert from '@components/Alert/Alert';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import Box from '@mui/material/Box';
import AppleIcon from '@components/AppleIcon/AppleIcon';
import { useMediaQuery } from 'react-responsive';

const HomePage = () => {
  const { projects, errors, loading } = useSelector(
    (state: State) => state.blockchain
  );

  const isMobile = useMediaQuery({ maxWidth: 991 });

  return (
    <Layout>
      <Hero />
      {errors && errors.getProjects ? (
        <Alert
          sx={{ mt: 16 }}
          severity="error"
          title="Something went wrong"
          description="Projects could not be displayed. Please try again later."
        />
      ) : (
        <>
          {loading?.getProjects ? (
            <Box mt={16} display="flex" justifyContent="center">
              <LoadingSpinner color="secondary" size="large" />
            </Box>
          ) : (
            <>
              {projects && projects.live && projects.live.length > 0 && (
                <Projects
                  heading="Live Projects"
                  icon={<AppleIcon icon="police-car-light" size="small" />}
                  status="live"
                  projects={projects.live}
                  variant={isMobile ? 'slider' : 'grid'}
                  id="live-minting"
                />
              )}
              {projects &&
                projects.upcoming &&
                projects.upcoming.length > 0 && (
                  <Projects
                    heading="Upcoming Projects"
                    icon={<AppleIcon icon="stopwatch" size="small" />}
                    status="upcoming"
                    projects={projects.upcoming}
                    id="upcoming-projects"
                  />
                )}
              {projects &&
                projects.finished &&
                projects.finished.length > 0 && (
                  <Projects
                    heading="Previous Mints"
                    icon={<AppleIcon icon="clinking-glasses" size="small" />}
                    status="previous"
                    projects={projects.finished}
                    id="previous-mints"
                  />
                )}
            </>
          )}
        </>
      )}
      <Newsletter />
    </Layout>
  );
};

export default HomePage;
