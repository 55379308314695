import { ProjectDetails } from '../blockchain.interface';
import nftLaunch from './contracts/nft-launch';
import luartCDN, { DYNAMIC_VALUE } from './luart-cdn';

const LOAD_MINTING_STATE_PROJECT_TITLES = ['DystopAI'];

// Will fetch using axios data from static storage
async function getProjects(): Promise<ProjectDetails[]> {
  const fetchedProjects = await luartCDN.fetchProjects();
  const result: ProjectDetails[] = [];

  for (const project of fetchedProjects) {
    // Load minting state only for live projects
    if (project.status === 'live') {
      project.alreadyMintedCount =
        (await nftLaunch.getAlreadyMintedCount(project.contractAddress)) +
        project.reservedTokensCount;

      const mintingStatus = await nftLaunch.getMintingStatus(
        project.contractAddress
      );

      // Set current minting stage
      const mintingStage = mintingStatus?.minting_stage || 0;

      project.mintingStage = mintingStage;

      const activeMintingStage = project.mintingStages[mintingStage];

      // Calculate minting access type
      if (project.mintingAccess === DYNAMIC_VALUE) {
        project.mintingAccess = activeMintingStage.isPublic
          ? 'public'
          : 'whitelisted';
      }
    }

    result.push(project);
  }

  return result;
}

async function findProjectByAddress(
  launchContractAddress: string
): Promise<ProjectDetails> {
  const projects = await luartCDN.fetchProjects();
  const selectedProject = projects.find(
    (p) => p.contractAddress === launchContractAddress
  );
  return selectedProject!;
}

export default {
  getProjects,
  findProjectByAddress,
};
