import {
  BlockchainAction,
  BlockchainActionTypes,
  BlockchainState,
} from '../types/blockchain.types';

const initialState: BlockchainState = {
  projects: null,
  loading: {
    getProjects: true,
    getBalance: true,
  },
  balance: null,
  errors: null,
};

const projectsReducer = (state = initialState, action: BlockchainAction) => {
  switch (action.type) {
    case BlockchainActionTypes.PING_TERRA_GATEWAY:
      return {
        ...state,
        errors: {
          ...state.errors,
          pingTerraGateway: false,
        },
        loading: {
          ...state.loading,
          pingTerraGateway: false,
        },
      };
    case BlockchainActionTypes.GET_BALANCE:
      return {
        ...state,
        balance: action.payload,
        errors: {
          ...state.errors,
          getBalance: false,
        },
        loading: {
          ...state.loading,
          getBalance: false,
        },
      };
    case BlockchainActionTypes.GET_PROJECTS:
      return {
        ...state,
        errors: {
          ...state.errors,
          getProjects: false,
        },
        loading: {
          ...state.loading,
          getProjects: false,
        },
        projects: action.payload,
      };
    case BlockchainActionTypes.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: true,
        },
      };
    case BlockchainActionTypes.ERROR:
      return {
        ...state,
        ...action.payload.newState,
        errors: {
          ...state.errors,
          [action.payload.loading]: true,
        },
        loading: {
          ...state.loading,
          [action.payload.loading]: false,
        },
      };
    default:
      return state;
  }
};

export default projectsReducer;
