import Heading from '@components/Heading/Heading';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { StyledMailbox, StyledNewsletter } from './Newsletter.styled';
import Input from '@components/Input/Input';
import Stack from '@mui/material/Stack';
import Button from '@components/Button/Button';
import { useSubscribe } from '@hooks/useSubscribe';
import Alert from '@components/Alert/Alert';
import Box from '@mui/material/Box';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import Checkbox from '@components/Checkbox/Checkbox';
import * as ROUTES from '@constants/routes';

const Newsletter = () => {
  const {
    handleSubmit,
    handleChange,
    errors,
    setErrors,
    email,
    loading,
    success,
    setSuccess,
    tosChecked,
    setTosChecked,
  } = useSubscribe();

  return (
    <Box my={16}>
      <StyledNewsletter>
        <Grid container>
          <Grid item xs={12} md={8}>
            <div>
              <Heading variant="h700" component="h2" mb={4}>
                Don't Miss Out on What's Next
              </Heading>
              <Typography variant="body4" color="text.primary">
                Sign up for our newsletter and keepup to date with the latest
                news from Luart. Be first to hear about NFT launches and
                exclusive events.
              </Typography>
              <form onSubmit={handleSubmit}>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                  sx={{ mb: 2 }}
                >
                  <Input
                    type="email"
                    placeholder="you@example.com"
                    value={email}
                    onChange={handleChange}
                    inputProps={{
                      id: 'email',
                      autoComplete: 'on',
                    }}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    loading={loading}
                    loadingIndicator={<LoadingSpinner />}
                  >
                    Subscribe Now
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  onClick={() => setTosChecked(!tosChecked)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Checkbox checked={tosChecked} />
                  <Typography
                    variant="body2"
                    color="text.primary"
                    component="p"
                  >
                    I agree to the processing of personal data by Luart for
                    marketing purposes and accept{' '}
                    <a href={ROUTES.TOS} target="blank" rel="noreferrer">
                      Terms of Service
                    </a>
                  </Typography>
                </Stack>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledMailbox src="/images/mailbox.png" alt="Mailbox" />
          </Grid>
        </Grid>
      </StyledNewsletter>
      {errors.tosChecked && (
        <Alert
          severity="warning"
          title="Error!"
          description={errors.tosChecked}
          onClose={() =>
            setErrors({ email: null, api: null, tosChecked: null })
          }
          sx={{ mt: 4 }}
        />
      )}
      {errors.email && (
        <Alert
          severity="warning"
          title="Error! Email not found."
          description={errors.email}
          onClose={() =>
            setErrors({ email: null, api: null, tosChecked: null })
          }
          sx={{ mt: 4 }}
        />
      )}
      {errors.api && (
        <Alert
          severity="warning"
          title="Oops!"
          description={errors.api}
          onClose={() =>
            setErrors({ email: null, api: null, tosChecked: null })
          }
          sx={{ mt: 4 }}
        />
      )}
      {success && (
        <Alert
          severity="success"
          title="Welcome to Luart! You’re subscribed."
          description={success}
          onClose={() => setSuccess(null)}
          sx={{ mt: 4 }}
        />
      )}
    </Box>
  );
};

export default Newsletter;
