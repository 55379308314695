import React from 'react';
import { StyledProgressBar } from './ProgressBar.styled';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

interface ProgressBarProps {
  value?: number;
  leftText?: string;
  rightText?: string;
  variant?: 'primary' | 'secondary';
  sx?: SxProps;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  leftText,
  rightText,
  sx = {},
}) => {
  return (
    <>
      <StyledProgressBar variant="determinate" value={value} sx={sx} />
      {leftText && rightText && (
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body3" color="text.primary">
            {leftText}
          </Typography>
          <Typography variant="h300" color="text.primary" component="h6">
            {rightText}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default ProgressBar;
