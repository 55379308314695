import numeral from 'numeral';
import big, { BigSource } from 'big.js';

export const LUNA_MAXIMUM_DECIMAL_POINTS = 6;
export const UST_MAXIMUM_DECIMAL_POINTS = 6;
export const LUART_MAXIMUM_DECIMAL_POINTS = 6;

// To Decimal

type MaxDecimals = number | 'auto';

export const toDecimal = (
  decimalPoints: number,
  num: BigSource,
  maxDecimals?: MaxDecimals
): string => {
  const decimalNum = big(
    big(num)
      .mul(10 ** decimalPoints)
      .toFixed()
      .split('.')[0]
  ).div(10 ** decimalPoints);

  if (maxDecimals && maxDecimals !== 'auto') {
    return (
      Math.floor(parseFloat(decimalNum.toString()) * 100) / 100
    ).toString();
  }

  return decimalNum.toFixed();
};

export const toUSTDecimal = (num: BigSource, maxDecimals?: MaxDecimals) => {
  if (!maxDecimals) maxDecimals = 2;

  return toDecimal(UST_MAXIMUM_DECIMAL_POINTS, num, maxDecimals);
};

export const toLUNADecimal = (num: BigSource, maxDecimals?: MaxDecimals) => {
  if (!maxDecimals) maxDecimals = 2;

  return toDecimal(LUNA_MAXIMUM_DECIMAL_POINTS, num, maxDecimals);
};

export const toLUARTDecimal = (num: BigSource, maxDecimals?: MaxDecimals) => {
  if (!maxDecimals) maxDecimals = 2;

  return toDecimal(LUART_MAXIMUM_DECIMAL_POINTS, num, maxDecimals);
};

// Formats

export const formatDecimal = (
  num: BigSource,
  fn: (num: BigSource) => string,
  maxDecimals?: MaxDecimals
) => {
  let decimalNum = fn(num);

  if (maxDecimals && maxDecimals !== 'auto') {
    decimalNum = fn(num);
  }

  const [integer, decimal] = decimalNum.split('.');

  return numeral(integer).format('0,0') + `${decimal ? `.${decimal}` : ''}`;
};

export const formatUSTDecimal = (num: BigSource, maxDecimals?: MaxDecimals) => {
  if (!maxDecimals) maxDecimals = 2;

  return `${formatDecimal(num, toUSTDecimal, maxDecimals)} $UST`;
};

export const formatLUNADecimal = (
  num: BigSource,
  maxDecimals?: MaxDecimals
) => {
  if (!maxDecimals) maxDecimals = 2;

  return `${formatDecimal(num, toLUNADecimal, maxDecimals)} $LUNA`;
};

export const formatLUARTDecimal = (
  num: BigSource,
  maxDecimals?: MaxDecimals
) => {
  if (!maxDecimals) maxDecimals = 2;

  return `${formatDecimal(num, toLUARTDecimal, maxDecimals)} $LUART`;
};
