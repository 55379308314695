import { ProjectDetails } from '@utils/blockchain/blockchain.interface';

interface Balance {
  ust: number;
  luna: number;
  luart: number;
}

export interface BlockchainState {
  projects: {
    live: ProjectDetails[];
    upcoming: ProjectDetails[];
    finished: ProjectDetails[];
  } | null;
  loading: {
    [key: string]: boolean;
  } | null;
  balance: Balance | null;
  errors: {
    [key: string]: boolean;
  } | null;
}

export enum BlockchainActionTypes {
  GET_PROJECTS,
  GET_BALANCE,
  LOADING,
  ERROR,
  PING_TERRA_GATEWAY,
}

interface LoadingAction {
  type: BlockchainActionTypes.LOADING;
  payload: string;
}

interface ErrorAction {
  type: BlockchainActionTypes.ERROR;
  payload: {
    loading: string;
    newState?: {
      [key: string]: any;
    };
  };
}

interface GetProjectsAction {
  type: BlockchainActionTypes.GET_PROJECTS;
  payload: {
    live: ProjectDetails[];
    upcoming: ProjectDetails[];
    finished: ProjectDetails[];
  };
}

interface GetBalanceAction {
  type: BlockchainActionTypes.GET_BALANCE;
  payload: Balance;
}

interface PingTerraGatewayAction {
  type: BlockchainActionTypes.PING_TERRA_GATEWAY;
}

export type BlockchainAction =
  | LoadingAction
  | ErrorAction
  | GetProjectsAction
  | GetBalanceAction
  | PingTerraGatewayAction;
