import { DiscordIcon, GlobeIcon, MediumIcon, TwitterIcon } from '@theme/icons';
import { ProjectDetails } from '@utils/blockchain/blockchain.interface';
import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { StyledSocialLinks } from './SocialLinks.styled';

interface SocialLinksProps {
  projectLinks: ProjectDetails['socialLinks'];
}

const SocialLinks: React.FC<SocialLinksProps> = ({ projectLinks }) => {
  const socialLinks = [
    {
      name: projectLinks?.website?.title,
      href: projectLinks?.website?.url,
      icon: <GlobeIcon />,
    },
    {
      name: projectLinks?.twitter?.title,
      href: projectLinks?.twitter?.url,
      icon: <TwitterIcon />,
    },
    {
      name: projectLinks?.medium?.title,
      href: projectLinks?.medium?.url,
      icon: <MediumIcon />,
    },
    {
      name: projectLinks?.discord?.title,
      href: projectLinks?.discord?.url,
      icon: <DiscordIcon />,
    },
  ];

  return (
    <StyledSocialLinks>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        sx={{ display: { xs: 'none', md: 'flex' } }}
        divider={
          <Box
            mx={3}
            sx={{
              width: '8px',
              height: '1px',
              background: 'rgba(255, 255, 255, 0.52)',
              display: { xs: 'none', md: 'block' },
            }}
          ></Box>
        }
      >
        {socialLinks.map(
          (link, index) =>
            link.href && (
              <a
                key={`project-social-link-$${index}`}
                href={link.href}
                target="_blank"
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  {link.icon}
                  <span>{link.name}</span>
                </Stack>
              </a>
            )
        )}
      </Stack>
    </StyledSocialLinks>
  );
};

export default SocialLinks;
