import Button from '@components/Button/Button';
import CurrencyInput from '@components/CurrencyInput/CurrencyInput';
import { useForm } from '@utils/useForm';
import React, { useState } from 'react';
import { Balance, TxReceipt } from '@utils/blockchain/blockchain.interface';
import blockchain from '@utils/blockchain/blockchain';
import TxResult from '@components/TxResult/TxResult';
import useBroadcastingTx from '@hooks/useBroadcastingTx';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';

interface WithdrawFormProps {
  currency: 'LUNA' | 'UST';
  balance: number;
  setBalance: React.Dispatch<React.SetStateAction<Balance>>;
}

interface FormValues {
  amount: string;
}

const WithdrawForm: React.FC<WithdrawFormProps> = ({
  currency,
  balance,
  setBalance,
}) => {
  const validations = {
    amount: {
      required: {
        value: true,
        message: 'Please enter integer (over 0)',
      },
      pattern: {
        value: '\\.[0-9]{3,}$',
        message: `Amount must be within 2 decimal points`,
      },
      custom: {
        isValid: (value: string) => {
          return parseFloat(value) > 0;
        },
        message: 'Please enter integer (over 0)',
      },
      max: {
        value: balance,
        message: 'Insufficient balance',
      },
    },
  };

  const { values, handleChange, errors, resetValues, handleSubmit } =
    useForm<FormValues>({
      validations,
      initialValues: {
        amount: '',
      },
    });

  const [errorMessage, setErrorMessage] = useState('');
  const [txReceipt, setTxReceipt] = useState<TxReceipt | null>(null);

  const onSuccessBroadcast = () => {
    setBalance((balance: Balance) => {
      const oldAmount = balance[currency];
      const newAmount = oldAmount - parseFloat(values.amount);

      balance[currency] = newAmount;

      return {
        ...balance,
        [currency]: newAmount,
      };
    });

    resetValues();
  };

  const { loading, setLoading, loadingText, broadcasted, setBroadcasted } =
    useBroadcastingTx(txReceipt?.txId, onSuccessBroadcast);

  const onSubmit = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, send: true }));
    try {
      const txReceipt = await blockchain.withdraw(
        parseFloat(values.amount),
        currency
      );

      setTxReceipt(txReceipt);
      setErrorMessage('');
    } catch (error) {
      console.log(error);
      setErrorMessage('There was an error while processing the transaction.');
      setLoading((prevLoading) => ({ ...prevLoading, send: false }));
    }
  };

  const balanceIsZero = Boolean(!balance || (balance && balance <= 0));

  const notReadyToSubmit = Boolean(balanceIsZero || errors.amount);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CurrencyInput
        currency={currency}
        label="Amount to be payout"
        value={values.amount}
        error={errors.amount}
        name="amount"
        onChange={handleChange}
        readOnly={balanceIsZero}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        sx={{ mt: 3 }}
        disabled={notReadyToSubmit}
        loading={loading.send || loading.broadcasting}
        loadingIndicator={<LoadingSpinner />}
      >
        {loadingText ? loadingText : 'Payout'}
      </Button>
      {(broadcasted || errorMessage) && (
        <TxResult
          errorMessage={errorMessage}
          txReceipt={txReceipt}
          onSuccessClose={() => {
            setBroadcasted(false);
            setTxReceipt(null);
          }}
          onErrorClose={() => setErrorMessage('')}
          sx={{ mt: 3 }}
        />
      )}
    </form>
  );
};

export default WithdrawForm;
