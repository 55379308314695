import { createTheme } from '@mui/material';
import { palette } from './palette';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: palette.teal[200],
      light: palette.teal[50],
      dark: palette.teal[600],
    },
    secondary: {
      main: palette.green[400],
      light: palette.green[300],
    },
    error: {
      main: palette.red[300],
      dark: palette.red[400],
      light: palette.red[200],
    },
    warning: {
      main: palette.orange[300],
      dark: palette.orange[400],
      light: palette.orange[200],
    },
    success: {
      main: palette.green[300],
      dark: palette.green[400],
      light: palette.green[200],
    },
    info: {
      main: palette.blue[300],
      dark: palette.blue[400],
      light: palette.blue[200],
    },
    light: {
      light: '#e2e2e3',
      main: '#ffffff',
      dark: '#5f6061',
    },
    dark: {
      main: palette.alphaDark[50],
      light: palette.alphaDark[300],
    },
    tertiary: {
      light: palette.alphaLight[100],
      main: palette.alphaLight[50],
    },
    grey: {
      ...palette.gray,
      A100: palette.secondaryGray[100],
      A200: palette.secondaryGray[200],
    },
    text: {
      primary: palette.alphaLight[800],
      secondary: palette.alphaLight[600],
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 16,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#383B42',
          color: 'white',
          padding: 6,
          borderRadius: 4,
          boxShadow: 'none',
        },
        arrow: {
          color: '#383B42',
        },
      },
    },
  },
});

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    body4: {
      fontFamily: "'Libre Franklin', sans-serif",
      fontSize: 16,
      lineHeight: '24px',
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
        lineHeight: '28px',
      },
    },
    body3: {
      fontFamily: "'Libre Franklin', sans-serif",
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontFamily: "'Libre Franklin', sans-serif",
      fontSize: 14,
      lineHeight: '18px',
    },
    body1: {
      fontFamily: "'Libre Franklin', sans-serif",
      fontSize: 12,
      lineHeight: '16px',
    },
    h900: {
      fontSize: 26,
      fontWeight: 700,
      lineHeight: '32px',
      [theme.breakpoints.up('md')]: {
        fontSize: 72,
        lineHeight: '108px',
      },
    },
    h800: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: '42px',
      [theme.breakpoints.up('md')]: {
        fontSize: 48,
        lineHeight: '72px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 56,
        lineHeight: '84px',
      },
    },
    h700: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
      [theme.breakpoints.up('md')]: {
        fontSize: 40,
        lineHeight: '60px',
      },
    },
    h600: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
        lineHeight: '32px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 28,
        lineHeight: '42px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 32,
        lineHeight: '48px',
      },
    },
    h500: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: '34px',
    },
    h400: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: '32px',
      },
    },
    h300: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    h200: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
    },
    h100: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
    },
  },
};

export default theme;
