import skeletonPunks from './skeleton-punks.json';
import lunaLions from './luna-lions.json';
import rektWolf from './rekt-wolf.json';
import mutantZ from './mutantz.json';
import anarchistsOnTerra from './anarchists-on-terra.json';
import genesisWolves from './genesis-wolves.json';
import hellcatsDemo from './hellcats-demo.json';
import astroHeroes from './astro-heroes.json';
import lunilandPlots1 from './luniland-plots-1.json';
import artsyApes from './artsy-apes.json';
import col from './col.json';
import dystopAi from './dystop-ai.json';
// import hellCats from './hellcats.json';
import LunaShield from './luna-shield.json';
import luni from './luni.json';
import silentSolohs from './silent-solohs.json';
import terrabayRonins from './terrabay-ronins.json';
import lunita from './lunita.json';
import graviCats from './gravicats.json';
import unstables from './unstables.json';
import terraAliens from './terra-aliens.json';
import redacted from './redacted.json';
// import hellHounds from './hellhounds.json';

export default {
  // Mainnet
  'columbus-5': [
    rektWolf,
    skeletonPunks,
    lunaLions,
    mutantZ,
    anarchistsOnTerra,
    genesisWolves,
    astroHeroes,
    lunilandPlots1,
    artsyApes,
    col,
    dystopAi,
    // hellCats,
    LunaShield,
    luni,
    silentSolohs,
    terrabayRonins,
    lunita,
    graviCats,
    terraAliens,
    unstables,
    // hellHounds,
  ],
  // Testnet
  'bombay-12': [
    {
      ...terraAliens,
      contractAddress: 'terra18hhptnt5lljvwfnquqycfrzqnjjnpg0783sxr4',
      nftContractAddress: 'terra1303lsm0jgs74u2tknjekryrjh0lguef3j2x7g3',
      mintingStages: [
        {
          ...terraAliens.mintingStages[0],
          startTime: 1,
          endTime: 1,
        },
        {
          ...terraAliens.mintingStages[1],
          startTime: 1,
          endTime: 1,
        },
        {
          ...terraAliens.mintingStages[2],
          startTime: 1,
          endTime: 1,
        },
        {
          ...terraAliens.mintingStages[3],
          startTime: 1,
        },
      ],
    },
    {
      ...unstables,
      contractAddress: 'terra10eu2rjnv5sl6xlz5fe0ezkmvh2v402g0alvfvh',
      nftContractAddress: 'terra1322nmw9zj9a38gah4clznhnqu9jatt6qnm8re7',
      mintingStages: [
        {
          ...unstables.mintingStages[0],
          startTime: 1,
          endTime: 1,
        },
        {
          ...unstables.mintingStages[1],
          startTime: 1,
          endTime: 1,
        },
        {
          ...unstables.mintingStages[2],
          startTime: 1,
          endTime: 1,
        },
        {
          ...unstables.mintingStages[3],
          startTime: 1,
          endTime: 1,
        },
        {
          ...unstables.mintingStages[4],
          startTime: 1,
          endTime: 1,
        },
        {
          ...unstables.mintingStages[5],
          startTime: 1,
          endTime: 1,
        },
        {
          ...unstables.mintingStages[6],
          startTime: 1,
        },
      ],
    },
    {
      ...graviCats,
      contractAddress: 'terra1rdez477wtkce9sf6v4uh7f3evn89whe2nvysn2',
      nftContractAddress: 'terra1sjv7qupde2rh2fz4ecv06t020maly6w64me97v',
      mintingStages: [
        {
          ...graviCats.mintingStages[0],
          startTime: 1,
        },
      ],
    },
    {
      ...genesisWolves,
      contractAddress: 'terra1slfh2297eqp2lztlpsx2u902t3ggvw5er2w7lf',
      nftContractAddress: 'terra1tdlc2nrqwslhmjxymnrxk7zejryhnssrdvack9',
      mintingStages: [
        {
          ...genesisWolves.mintingStages[0],
          startTime: 1,
          endTime: 1,
        },
        {
          ...genesisWolves.mintingStages[1],
          startTime: 1,
        },
      ],
    },
    {
      ...lunilandPlots1,
      contractAddress: 'terra1uxh57m3d4xp58uj505c22zus7jkzppghw6faxz',
      nftContractAddress: 'terra1ch6lrnfse9s69ptdms6gp69v82xpg33nwgch7z',
      mintingStages: [
        {
          ...lunilandPlots1.mintingStages[0],
          startTime: 1648719900000,
          endTime: 1648720500000,
        },
        {
          ...lunilandPlots1.mintingStages[1],
          startTime: 1648720500000,
        },
      ],
    },
    {
      ...anarchistsOnTerra,
      contractAddress: 'terra1pleyh24vywf22yuh3spnu27gkhrd344qxaj3st',
      nftContractAddress: 'terra1xw52eat6zvdhr97zrmkrz0au7jh8fszdc0yjnc',
      mintingStages: [
        {
          ...anarchistsOnTerra.mintingStages[0],
          startTime: 1,
          endTime: 1,
        },
        {
          ...anarchistsOnTerra.mintingStages[1],
          startTime: 1,
        },
      ],
    },
    // hellcatsDemo,
    // {
    //   ...hellHounds,
    //   mintingStages: [
    //     {
    //       ...hellHounds.mintingStages[0],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[1],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[2],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[3],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[4],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[5],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[6],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[7],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[8],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[9],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[10],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[11],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[12],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[13],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[14],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[15],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[16],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[17],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[18],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[19],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[20],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[21],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[22],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[23],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[24],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[25],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[26],
    //       startTime: 1650877200000,
    //       endTime: 1650885600000,
    //     },
    //     {
    //       ...hellHounds.mintingStages[27],
    //       startTime: 1650887700000,
    //       endTime: 1682467200000,
    //     },
    //   ],
    //   contractAddress: 'terra1vfqk7qkf0x3pvdj0624fzs3jpugrxufxurx6f0',
    //   nftContractAddress: 'terra1dhpggv6647cqhd99pw0pugag3636jd5x0mjsjd',
    // },
  ],
};
