import styled from 'styled-components';
import Button from '@mui/material/Button';
import { palette } from '@theme/palette';

export const StyledBackdrop = styled.div`
  background: ${palette.alphaDark[500]};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
`;

export const StyledBackdropExit = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledImageWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  z-index: 10000;
  max-height: 800px;

  img {
    width: 100%;
    max-height: 700px;
  }
`;
