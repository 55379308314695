import React from 'react';
import Card from '@components/Card/Card';
import { ProjectDetails as IProjectDetails } from '@utils/blockchain/blockchain.interface';
import Badge from '@components/Badge/Badge';
import { getProgressPercentage } from '@utils/getProgressPercentage';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import { Link } from 'react-router-dom';
import Button from '@components/Button/Button';
import * as ROUTES from '@constants/routes';
import { ArrowRightIcon } from '@theme/icons';
import Grid from '@mui/material/Grid';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { StyledBanner } from './Live.styled';
import Box from '@mui/material/Box';

interface LiveProjectProps {
  project: IProjectDetails;
}

const LiveProject: React.FC<LiveProjectProps> = ({ project }) => {
  const {
    title,
    bannerImageURL,
    totalTokensCount,
    mintingStages,
    mintingStage,
    alreadyMintedCount,
    contractAddress,
  } = project;

  const activeMintingStage = mintingStages[mintingStage || 0];

  const { stageTitle, isPublic } = activeMintingStage;

  const progress = getProgressPercentage(alreadyMintedCount, totalTokensCount);

  return (
    <Card>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} display={{ xs: 'none', md: 'block' }}>
          <StyledBanner src={bannerImageURL} alt={title} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="flex-end">
            <Badge
              size="small"
              text={stageTitle}
              color={isPublic ? 'success' : 'warning'}
              dotColor={isPublic ? 'success' : 'warning'}
            />
          </Box>
          <ProjectDetails
            project={project}
            hideDetails={['Start Date', 'Start Time']}
          />
          <Link to={`${ROUTES.PROJECT}/${contractAddress}`}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 6,
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
              endIcon={<ArrowRightIcon />}
            >
              Visit Minter
            </Button>
          </Link>
          <ProgressBar value={progress} sx={{ mb: 1 }} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body3" color="text.primary">
              Progress
            </Typography>
            <Typography variant="h300" color="text.primary" component="h6">
              {progress}%
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default LiveProject;
