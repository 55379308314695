import React from 'react';
import Card from '@components/Card/Card';
import { ProjectDetails as IProjectDetails } from '@utils/blockchain/blockchain.interface';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import Button from '@components/Button/Button';
import { Link } from 'react-router-dom';
import * as ROUTES from '@constants/routes';

interface PreviousProjectProps {
  project: IProjectDetails;
}

const PreviousProject: React.FC<PreviousProjectProps> = ({ project }) => {
  const { contractAddress, nftContractAddress } = project;

  const marketplaceCollectionURL = `${ROUTES.MARKETPLACE}/collections/${nftContractAddress}`;
  const launchpadCollectionURL = `/project/${contractAddress}`;

  return (
    <Card>
      <ProjectDetails
        project={project}
        hideDetails={['Start Date', 'Start Time']}
      />
      {/* <Link to={launchpadCollectionURL}>
        <Button
          variant="contained"
          color="tertiary"
          type="button"
          fullWidth
          sx={{ mb: 2, mt: 4 }}
        >
          Check minting details
        </Button>
      </Link> */}
      <a href={marketplaceCollectionURL} rel="noreferrer" target="_blank">
        <Button
          variant="contained"
          color="tertiary"
          type="button"
          fullWidth
          sx={{ mt: 4 }}
        >
          Visit collection
        </Button>
      </a>
    </Card>
  );
};

export default PreviousProject;
