import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    color: white;
    position: relative;
    min-height: 100vh;
    background: #1d2343;
    width: 100%;
    overflow-x: hidden !important;
    font-family: 'Libre Franklin', sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, label {
    font-family: 'Inter', sans-serif;
  }

  a {
    text-decoration: none;

    .MuiButton-root {
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  button {
    &:disabled {
      cursor: not-allowed !important;
      pointer-events: auto !important;
    }
  }

  .MuiDivider-root {
    border-color: rgba(255, 255, 255, 0.18);
  }

  .MuiTooltip-tooltip {
    background: #55596f;
    padding: 12px;
    border-radius: 8px;
  }

  .MuiTooltip-arrow {
    &:before {
      background: #55596f;
    }
  }

  .MuiPopover-root {
    position: absolute;
  }

  .atcb_bgoverlay {
    backdrop-filter: none;
    background: rgba(0, 0, 0, 0.72);
    cursor: default !important;
  }

  .atcb_list_item {
    box-shadow: none;
    background: #282C42;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    border-right: 1px solid rgba(255, 255, 255, 0.18);
    border-left: 1px solid rgba(255, 255, 255, 0.18);
    color: rgba(255, 255, 255, 0.72);

    &:focus {
      outline: none;
      background: #282C42;
      color: rgba(255, 255, 255, 0.72);
    }

    &:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.18);
    }

    &:hover {
      background: #282C42;
      color: white;
      box-shadow: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: all 5000s ease-in-out 0s;
  }
`;

export default GlobalStyles;
