import Heading from '@components/Heading/Heading';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@components/Button/Button';
import {
  StyledDescription,
  StyledGridContainer,
  StyledHero,
  StyledLabel,
  StyledRocketImage,
} from './Hero.styled';
import SecondaryFooter from '@components/SecondaryFooter/SecondaryFooter';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as ROUTES from '@constants/routes';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import { State } from '@store/store';

const Hero = () => {
  const { errors, projects } = useSelector((state: State) => state.blockchain);

  const getScrollToProjectsData = () => {
    if (projects) {
      if (projects?.live && projects?.live?.length > 0) {
        return {
          title: 'Visit Live Minting',
          id: 'live-minting',
        };
      } else {
        return {
          title: 'Visit Upcoming Projects',
          id: 'upcoming-projects',
        };
      }
    } else {
      return {
        title: 'Visit Live Minting',
        id: 'live-minting',
      };
    }
  };

  const scrollToProjectsData = getScrollToProjectsData();

  return (
    <StyledHero>
      <StyledGridContainer topbarDisplayed={errors?.pingTerraGateway}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} md={8}>
            <StyledLabel>EXPLORE NFTS ON TERRA</StyledLabel>
            <Heading variant="h800" component="h1">
              The Luart Launchpad: Your <br />
              NFT Journey Starts Here
            </Heading>
            <StyledDescription
              variant="h400"
              component="h3"
              mt={3}
              mb={6}
              sx={{ fontWeight: 500 }}
            >
              Luart brings the first gamified NFT Launchpad to The Terra
              Ecosystem. Delivering a seamless user experience for Explorers,
              you will be able to mint from your favorite collections while
              earning LUA Power.
            </StyledDescription>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, md: 4 }}
            >
              <HashLink smooth to={`${ROUTES.HOME}#${scrollToProjectsData.id}`}>
                <Button variant="contained" color="primary">
                  {scrollToProjectsData.title}
                </Button>
              </HashLink>
              <a target="blank" href={ROUTES.MARKETPLACE} rel="noreferrer">
                <Button variant="contained" color="tertiary">
                  Visit Marketplace
                </Button>
              </a>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <StyledRocketImage>
              <LazyLoadImage src="/images/rocket.png" alt="Rocket" />
            </StyledRocketImage>
          </Grid>
        </Grid>
      </StyledGridContainer>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <SecondaryFooter />
      </Box>
    </StyledHero>
  );
};

export default Hero;
