import terraUtils from './terra-utils';

type ContractName = 'luart';

const addresses = {
  'columbus-5': {
    luart: 'terra1vwz7t30q76s7xx6qgtxdqnu6vpr3ak3vw62ygk',
  },
  'bombay-12': {
    luart: 'terra16wvd4hkqw388y8kff9g535gtr4th6zxj48trpn',
  },
};

async function getContractAddress(contractName: ContractName): Promise<string> {
  const networkId = await terraUtils.getNetworkId();
  return addresses[networkId][contractName];
}

export default {
  getContractAddress,
};
