import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ProjectNFT from './ProjectNFT';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState, Suspense } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { StyledArrowButton } from './ProjectNFTS.styled';
import { ArrowLeftIcon, ArrowRightIcon } from '@theme/icons';

interface ProjectNFTsProps {
  randomTokens: {
    tokenId: string;
    imageURL: string;
    name: string;
  }[];
  projectTitle?: string;
}

const ProjectNFTs: React.FC<ProjectNFTsProps> = ({
  randomTokens,
  projectTitle,
}) => {
  const [swiper, setSwiper] = useState<any>(null);

  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 16,
    loop: true,
  };

  return (
    <Suspense fallback="">
      <Box mt={{ xs: 16, md: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h500" component="h3" color="text.primary">
            Sample drops
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <StyledArrowButton onClick={() => swiper?.slidePrev()}>
              <ArrowLeftIcon />
            </StyledArrowButton>
            <StyledArrowButton onClick={() => swiper?.slideNext()}>
              <ArrowRightIcon />
            </StyledArrowButton>
          </Stack>
        </Stack>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Swiper onSwiper={(swiper) => setSwiper(swiper)} {...swiperParams}>
            {randomTokens.map((nft, index) => (
              <SwiperSlide key={`project-nft-${index}`}>
                <ProjectNFT
                  image={nft.imageURL}
                  projectTitle={projectTitle}
                  tokenId={nft.tokenId}
                  name={nft.name}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Grid
          container
          spacing={4}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          {randomTokens.map((nft, index) => (
            <Grid item xs={12} md={3} key={`project-nft-${index}`}>
              <ProjectNFT
                image={nft.imageURL}
                projectTitle={projectTitle}
                name={nft.name}
                tokenId={nft.tokenId}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Suspense>
  );
};

export default ProjectNFTs;
