import Input from '@components/Input/Input';
import styled from 'styled-components';

export const StyledCountInput = styled(Input)`
  input {
    text-align: center;
  }

  button {
    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }
`;

export const StyledCountButton = styled.button<{
  active?: boolean;
}>`
  padding: 0;
  position: absolute;
  top: 0;
  width: 46px;
  height: 46px;
  cursor: pointer;
  background: transparent;
  color: ${(props) =>
    props.active
      ? props.theme.palette.text.primary
      : props.theme.palette.text.secondary};
  border: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;
