import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box, { BoxProps } from '@mui/material/Box';
import { SxProps } from '@mui/system';

interface SeparatedTexts extends BoxProps {
  left: string;
  right: string;
  sx?: SxProps;
}

const SeparatedTexts: React.FC<SeparatedTexts> = ({
  left,
  right,
  sx = { mb: 1 },
  ...rest
}) => {
  return (
    <Box sx={sx} {...rest}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" color="text.primary">
          {left}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {right}
        </Typography>
      </Stack>
    </Box>
  );
};

export default SeparatedTexts;
