import ContentContainer from '@components/ContentContainer/ContentContainer';
import Layout from '@components/Layout/Layout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArticleContainer from '@components/ArticleContainer/ArticleContainer';

const TermsOfServicePage = () => {
  return (
    <Layout>
      <ContentContainer>
        <ArticleContainer>
          <Box textAlign="center">
            <Typography variant="h600" component="h1">
              TERMS & CONDITIONS
            </Typography>
            <Typography variant="h400" component="h5">
              OF DECEMBER 27th, 2021
            </Typography>
          </Box>
          <h3>1. INTRODUCTION</h3>
          <p>
            LUT INVESTMENTS Limited Liability Company in organization, a company
            established under the laws of Georgia with its seat in Tbilisi,
            address: Ilia Chavchavadze Avenue 60b, Floor 16, Office 65, Tbilisi,
            Georgia, provides its Services described herewith below to you
            through its Platform located at{' '}
            <a href="https://luart.io" target="_blank">
              https://luart.io
            </a>
            , subject to the following Terms and Conditions. These Terms
            constitute a binding and enforceable legal contract between you and
            the Company.
          </p>
          <br />
          <p>
            By accessing, using, or clicking on the Services, and information
            made available by the Company via the Platform, you agree that you
            have read, understood, and to are to bound by these Terms, as
            amended from time to time, and that you comply with the requirements
            listed herein. The Privacy Policy and other additional terms,
            guidelines, and rules as set forth on the Platform are hereby
            incorporated by reference into these Terms and expressly agreed to
            and acknowledged by the User. If you do not agree to all of these
            Terms or comply with the requirement herein, please do not access or
            use the Platform and the Services.
          </p>
          <br />
          <h4>
            PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT
            INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS.
            EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED THEREIN, YOU AND
            COMPANY AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED AMICABLY,
            INCLUDING THROUGH MEDIATION, AND YOU AND COMPANY WAIVE ANY RIGHT TO
            PARTICIPATE IN A CLASS-ACTION LAWSUIT.
          </h4>
          <br />
          <p>
            We reserve the right, at our sole discretion, to change or modify
            portions or the full wording of these Terms at any time, including
            for security, legal or regulatory reasons, as well as to reflect
            updates or changes to the Services or functionality of the Platform.
            If we do this, we will post the changes on this page and will
            indicate at the top of this page the date these Terms were last
            revised. You are strongly advised to check these Terms periodically
            to ensure that you are cognizant of the current version and comply
            with them. We shall also notify you about the above-mentioned
            changes, either through the Platform interface or through other
            reasonable means. Any such changes will become effective no earlier
            than fourteen (14) days after they are posted, except that changes
            addressing new functions of the Platform will be effective
            immediately. Your continued use of the Platform after the date any
            such changes become effective constitutes your acceptance of the new
            Terms and Conditions.
          </p>
          <br />
          <p>
            You should seek professional advice regarding any possible legal
            requirements you must comply with in relation to the use of the
            Platform, the Service or the relevant tools provided by the Company.
          </p>
          <br />
          <p>
            By signing up for an account on the Platform you are entering into a
            contract with LUT INVESTMENTS Limited Liability Company in
            organization, a company established under the laws of Georgia with
            its seat in Tbilisi, address: Ilia Chavchavadze Avenue 60b, Floor
            16, Office 65, Tbilisi, Georgia.
          </p>
          <h3>2. DEFINITIONS</h3>
          <p>
            <strong>AML</strong> – shall mean Anti Money Laundering and
            Terrorist Financing procedure, that provides a record of the
            specific actions taken by the Company in the context of prevention
            of money laundering and terrorist financing.
          </p>

          <p>
            <strong>AMM Protocol</strong> – shall mean an external service,
            provided by the third party, which is a type of decentralized
            exchange (DEX) protocol, which operates in a form of a
            peer-to-contract (P2C) on the given blockchain, that relies on a
            mathematical formula to price assets, which, in return for providing
            liquidity to a liquidity pool, issues the LP Tokens to the liquidity
            provider. The AMM Protocol is not controlled, managed, influenced or
            advised by the Company by any means.
          </p>

          <p>
            <strong>APR score</strong> – shall mean an Annual Percentage Rate
            score representing monetary value or reward that investors may earn
            by performing specified actions.
          </p>

          <p>
            <strong>Applicable laws</strong> – shall mean any relevant documents
            such as acts, statutes, regulations, ordinances, treaties,
            guidelines, and policies issued by governmental organizations or
            supervisory authorities, including, but not limited to, the
            governing law stipulated under Laws of Georgia which relate
            specifically to the Services provided by the Platform.
          </p>

          <p>
            <strong>Company, we</strong> – shall mean the LUT INVESTMENTS
            Limited Liability Company in organization, a company established
            under the laws of Georgia with its seat in Tbilisi, address: Ilia
            Chavchavadze Avenue 60b, Floor 16, Office 65, Tbilisi, Georgia.
          </p>
          <p>
            <strong>Content</strong> – shall mean all content generated by the
            Company and provided on the Platform, including among others logos,
            identifying marks, images, illustrations, designs, icons,
            photographs, videos, text, any written or multimedia materials,
            services, advertisements, software, code, data, files, archives,
            folders, or available downloads on the Platform.
          </p>
          <p>
            <strong>Cookie</strong> – shall mean the small text file that are
            placed on your computer while you are using the Platform.
          </p>
          <p>
            <strong>Digital Asset</strong> – shall mean any type and standard of
            cryptographic tokens that can be created, bought, transferred or
            traded on the Platform.
          </p>
          <p>
            <strong>Feedback</strong> – shall mean any comments, bug reports,
            ideas or other feedback about the Platform, including without
            limitation about how to improve the Platform.
          </p>
          <p>
            <strong>Gas fee</strong> – shall mean a payment of a transaction fee
            for every transaction that occurs on the blockchain, which remains
            out of the Company’s control or influence.
          </p>
          <p>
            <strong>Indemnitees</strong> – shall mean the LUT INVESTMENTS
            Limited Liability Company in organization and its affiliates and
            their officers, employees, directors and agents.
          </p>
          <p>
            <strong>KYC</strong> – shall mean Know-Your-Customer procedure that
            the Company can carry out to identify its customers and beneficial
            owners and obtain the relevant information required to do business
            with the party concerned.
          </p>
          <p>
            <strong>Launchpad Service</strong> – shall mean a service that
            allows NFT Authors to launch NFT Tokens on the Platform as well as
            allows other Users to “mint” the launched NFT Tokens.
          </p>
          <p>
            <strong>LP Token</strong> – shall mean a Digital Asset issued to a
            liquidity provider in exchange for providing the liquidity to a
            liquidity pool that is run by the given AMM protocol.
          </p>
          <p>
            <strong>LUART</strong> – shall mean the Company's website or the
            Platform itself that facilitates usage of the Services, which are
            developed therein and where the Company's Project is officially
            launched.
          </p>
          <p>
            <strong>LUART Token</strong> – shall mean a CW20 token standard
            Digital Asset issued within the TGE conducted on the Platform.
          </p>
          <p>
            <strong>LUART Trademarks</strong> – shall mean name and logos that
            are trademarks and service marks of LUART.
          </p>
          <p>
            <strong>LUNA Token</strong> – shall mean a Digital Asset that is the
            Terra protocol's native token.
          </p>
          <p>
            <strong>Marketplace Service</strong> – shall mean a service that
            allows Users to trade NFT Tokens through fixed price listings and
            bidding.
          </p>
          <p>
            <strong>NFT Token</strong> – shall mean a type of Digital Asset that
            can be used to represent various items such as in particular:
            digital art, music, video etc.
          </p>
          <p>
            <strong>NFT Author</strong> – shall mean a specific type of the User
            that launches NFT Tokens on the Platform.
          </p>
          <p>
            <strong>NFT Content</strong> – shall mean digital art and virtual
            in-game items.
          </p>
          <p>
            <strong>Platform</strong> – shall mean an internal Company’s
            environment through which the services are being provided;
          </p>
          <p>
            <strong>Prohibited Jurisdictions</strong> – shall mean any country
            or jurisdiction (such as for example the United States of America –
            including its territories and dependencies or Democratic People’s
            Republic of Korea) in which, conducting any activity related to
            virtual assets (in particular cryptocurrency), is otherwise
            prohibited or restricted, under Applicable laws or sanctions.
          </p>
          <p>
            <strong>Project</strong> - shall mean the Company’s project,
            developed and launched by means of the Platform.
          </p>
          <p>
            <strong>Single Asset Staking</strong> – shall mean a process of
            delegating or locking up Digital Assets to earn rewards expressed in
            the same Digital Assets.
          </p>
          <p>
            <strong>Services</strong> – shall mean all the Services provided to
            the Users through the Platform, which include in particular the
            accessibility to the Trading Service, the Staking Service, the
            Launchpad Service and the Marketplace Service.
          </p>
          <p>
            <strong>Slippage Tolerance</strong> – the maximum acceptable
            deviation from the intended price (expressed in %).
          </p>
          <p>
            <strong>Staking Service</strong> – shall mean a service that allows
            Users to stake the LUART Tokens and the LP Tokens in order to
            receive rewards in the LUART Tokens.
          </p>
          <p>
            <strong>Submissions</strong> – shall mean any questions, comments,
            suggestions, ideas, feedback or other information about the
            Platform.
          </p>
          <p>
            <strong>Third-Party services</strong> – shall mean any services,
            content, and information owned, made available, or otherwise
            licensed by a third party.
          </p>
          <p>
            <strong>TGE</strong> – shall mean a Token Generation Event that is
            an act of generating Digital Assets.
          </p>
          <p>
            <strong>Trading service</strong> – shall mean a service that allows
            Users to buy and sell the LUART Tokens using the UST Tokens as well
            as to provide liquidity for the pair consisting of the LUART Tokens
            and the UST Tokens.
          </p>
          <p>
            <strong>Transaction</strong> – shall mean an act of generating,
            selling, buying, transferring or locking Digital Assets by means of
            the Platform.
          </p>
          <p>
            <strong>Unbounding period</strong> – shall mean a period of time in
            which the staked tokens cannot be sold or transferred.
          </p>
          <p>
            <strong>Users, you</strong> – shall mean all the Platform users.
          </p>
          <p>
            <strong>User Content</strong> – shall mean any content, Digital
            Assets, or other materials that the Users upload through the
            Platform or share with other Users or recipients.
          </p>
          <p>
            <strong>UST Token</strong> – shall mean Digital Asset built on the
            Terra blockchain that represent the value of 1 USD.
          </p>
          <p>
            <strong>Wallet</strong> – shall mean a digital wallet assigned to
            the Platform by the User to whom it belongs.
          </p>
          <p>
            <strong>Whitelist</strong> – shall mean a list of Wallet addresses
            that are allowed to “mint” NFT Tokens.
          </p>
          <h3>3. ABOUT LUART</h3>

          <p>
            LUART is a decentralized space for Internet users functioning as a
            NFT marketplace providing a full experience for the Users using
            gamification. It allows Users to trade, stake and transfer Digital
            Assets using smart contracts that provide an immutable ledger of all
            Transactions that occur on the Platform.
          </p>

          <p>
            <div>
              <strong>[Noncustodial]</strong>
            </div>
            The Company shall provide the Users with tools that they can use in
            their sole discretion and does not interfere with or participate in
            their functioning. Due to noncustodial nature of LUART Services, the
            Company does not facilitate or provide brokerage, exchange, payment,
            escrow, remittance or merchant services. Digital Assets which are
            introduced within the Platform ecosystem can only be withdrawn by
            the rightful User, who, depending on the status of the particular
            Transaction, is entitled to use the “Withdraw” or “Claim”
            functionalities on the Platform.
          </p>

          <p>
            The User understands and acknowledges that any of the
            functionalities available on the Platform does not give the Company
            custody, possession, or control of any Digital Asset introduced or
            generated within the Platform at any time for the purpose of
            facilitating Transactions on the Platform. In this respect, you
            acknowledge and agree that the Company is a non-custodial software
            provider that has developed this distributed and decentralized
            environment where you can autonomously and directly access the
            Platform by connecting your individual Wallet.
          </p>

          <p>
            <strong>
              USERS WILL NOT HAVE TO FORFEIT THEIR RIGHTS TO PRIVACY IN ORDER TO
              PARTICIPATE IN THE PLATFORM ECOSYSTEM.
            </strong>
          </p>

          <p>
            <div>
              <strong>[Lack of control]</strong>
            </div>
            All Digital Assets introduced within the Platform’s ecosystem are
            outside of the control of any one party, including the Company, and
            are subject to many risks and uncertainties. The Company neither
            owns nor controls the Terra blockchain, AMM Protocol or external
            Wallets available on the Platform, your browser, or any other third
            party site, product, or service that you might access, visit, or use
            for the purpose of enabling you to use the various features of the
            Platform. The Company will not be liable for the acts or omissions
            of any such third parties, nor will we be liable for any damage that
            you may suffer as a result of your Transactions or any other
            interaction with any such third parties. You understand that your
            Wallet public address will be made publicly visible whenever you
            initiate or engage in the Transaction on the Platform. As the
            Platform which only enables the usage of particular Services, over
            which once initiated the Company has no control or influence, LUART
            cannot make any representation or guarantee that the Users will
            achieve any particular outcome as the result of using the Services.
          </p>
          <h3>How can I use LUART?</h3>

          <p>
            <div>
              <strong>[Technical Requirements]</strong>
            </div>
            The easiest way to use the Platform is via a web browser, e.g.
            Google Chrome.
          </p>

          <p>
            Once you have installed your browser, go to the Platform website
            https://luart.io and connect your individual Wallet. In order to use
            the Services you must read and accept (i) these Terms and (ii)
            Privacy Policy.
          </p>

          <p>
            The Platform is based on the Terra blockchain, secured using a
            state-of-the-art proof-of-stake (PoS) consensus algorithm.
          </p>
          <p>
            The Wallet, that the User is required to connect to the Platform in
            order to use its Services, is an external tool provided by a third
            party that enables to engage in Transactions. The choice of the
            Wallet that the User connects to the Platform, remains at the sole
            discretion of the particular User and is limited only by the
            technical requirements corresponding to the Terra blockchain. The
            Company does not hold any control over the functioning of the Wallet
            and cannot be considered as a provider of such solution. You hereby
            acknowledge and agree that the Company will not be liable for any
            damage, loss, consequence, or devaluation of the assets held in any
            of your Wallets, which may occur as a result or in connection with
            any matter related to disruptive function, broken internet system or
            any failure. You understand that you are solely responsible for
            maintaining the security of your Wallet. You acknowledge and agree
            that if you lose access to the Wallet, the Company will not be able
            to help you recover the loss, or transfer the Digital Assets back to
            your Wallet. You understand and agree that you will not hold the
            Company responsible for managing and maintaining the security of
            your Wallet. You further understand and agree that the Company is
            not responsible (and you will not hold the Company responsible) for
            any unauthorized access to, or use of, your Wallet.
          </p>
          <p>
            You will not be able to engage in any Transactions on the Platform
            other than through your browser. The Platform will only recognize
            you as the User, and you will only be able to interact with the
            Platform, if your Wallet is connected and unlocked. There is no
            other way act as the User, or to interact directly with the
            Platform.
          </p>
          <p>
            <div>
              <strong>[Your Registration Obligations]</strong>
            </div>
            Anyone can browse the Platform without connecting the Wallet.
          </p>

          <p>
            You may be required to provide additional data such as e-mail
            address in order to access and use certain features on the Platform,
            such as participating in the newsletter.
          </p>

          <p>
            You must be at least 18 years old and you must have full legal
            capacity and authority to agree and bind yourself to these Terms and
            to use the Platform as well as to use any available Services.
            Moreover, by accepting these Terms you confirm that you are
            responsible for anything that occurs when anyone is using a device
            on which you enabled the access to the Platform through connecting
            your Wallet.
          </p>

          <p>
            If you are a company or represent other legal person and you access
            the Platform and use available therein functionalities, by accepting
            these Terms you are confirming to us that you have the proper legal
            authority resulting from your local laws to accept these Terms on
            that company’s/legal person’s behalf. If you do not have that
            authority, you must not use the Platform.
          </p>

          <p>
            <div>
              <strong>[Modifications to the Platform]</strong>
            </div>
            The Company reserves the right to modify or discontinue, temporarily
            or permanently, the Platform (or any part thereof) with or without
            notice. You agree that LUART will not be liable to you or to any
            third party for any modification, suspension, or discontinuance of
            the Platform.
          </p>

          <p>
            <div>
              <strong>[The Trading Service]</strong>
            </div>
            The Trading Service allows you to buy and sell the LUART Tokens in
            exchange for the UST Tokens. In order to do so, you should use the
            “Trade $LUART” functionality in the “Trade” section on the Platform
            and specify whether you intend to buy or sell the LUART Tokens.
            After selecting an appropriate option, you may input the amount of
            the LUART Tokens that should be traded for the UST Tokens.
            Additionally, you must select the acceptable Slippage Tolerance for
            every Transaction executed using the Trading Service.
          </p>

          <p>
            The Trading Service also allows you to provide liquidity to the
            liquidity pool of the pair consisting of the LUART Tokens and the
            UST Tokens. For this purpose, you should navigate to the “Liquidity”
            section and use the “Add Liquidity” functionality. In order to
            provide liquidity the “Provide” button should be activated and you
            should input either the amount of the LUART Tokens or the UST Tokens
            that is to be added to the liquidity pool. In either case, the
            amount of both the LUART and UST Tokens will be calculated and
            presented for your approval. Under the “$LUART-UST LP” heading you
            will find an estimated amount of the LP Tokens that you will receive
            after adding a specified amount of the LUART Tokens along with the
            UST Tokens to liquidity. Please note that this estimation is not
            binding and the final number of the LP Tokens awarded to you is not
            determined by the Company. By clicking the “Add Liquidity” button,
            the transaction is irrevocably executed.
          </p>

          <p>
            <div>
              <strong>[The Staking Service]</strong>
            </div>
            The Staking Service allows you to stake the LUART Tokens and/or the
            LP Tokens in order to receive rewards in the LUART Tokens. In order
            to stake the LUART Tokens, you should navigate to the “Staking”
            section and use the “Stake $LUART” functionality. When staking the
            LUART Tokens, it is necessary to determine the amount of the LUART
            Tokens that is to be staked and then hit the “Stake $LUART” button.
            When unstaking, it is also necessary to specify the amount of the
            LUART Tokens that is to be unstaked and then the “Commit to unstake”
            button should be used.
          </p>

          <p>
            In order to stake the LP Tokens, you should navigate to the
            “Liquidity” section and use the “Stake $LUART-UST LP” functionality.
            When staking the LP Tokens, it is necessary to determine the amount
            of the LP Tokens that is to be staked and then hit the “Stake
            $LUART-UST LP” button. When unstaking, it is also necessary to
            specify the amount of the LP Tokens that is to be unstaked and then
            the “Commit to unstake” button should be used..
          </p>

          <p>
            <div>
              <strong>[The Launchpad Service]</strong>
            </div>
            The Launchpad Service allows you to launch the NFT Tokens and “mint”
            the NFT Tokens on the Platform. In order to launch an NFT Token, it
            is necessary to provide the Company with the NFT Content for NFT
            Tokens by means of an e-mail at hello@luart.io. Depending on the
            type and amount of the NFT Content, the Company may provide further
            instructions as to the preparation of the NFT Content. Along with
            the NFT Content, the NFT Authors must provide:
          </p>

          <ul>
            <li>collection metadata (name, description, URIs etc.);</li>
            <li>
              metadata for each NFT token in the collection (including URI to
              the corresponding content);
            </li>
            <li>price policy for each token from the collection;</li>
            <li>technical details of minting procedure;</li>
            <li>
              possible restrictions concerning the access to minted NFTs
              (including Whitelists and/or the minimum level of the LUA Power
              score).
            </li>
          </ul>

          <p>
            When the NFT Tokens are launched on the Platform, you may “mint”
            those NFT Tokens by using the “Mint” button next to the chosen NFT
            Token. The “minting” of the NFT Tokens requires paying the fee in
            the UST Tokens.
          </p>

          <p>
            <div>
              <strong>[The Marketplace Service]</strong>
            </div>
            The Marketplace Service allows you to buy and sell the NFT Tokens.
            In order to buy an NFT Token, it is necessary to select one of the
            available NFT Tokens and use the “Buy now” button to execute the
            purchase. The fee can be paid either in the UST Tokens or in the
            LUNA Tokens. In order to sell the NFT Token, it is necessary to use
            the “Apply today” hyperlink in the “Apply to Launch NFTs” footer
            section.
          </p>

          <h3>What are the rules for using the Platform?</h3>

          <p>
            <div>
              <strong>[User Obligations]</strong>
            </div>
            When using the Platform, no User is allowed to:
            <ul>
              <li>
                manipulate the price of a Digital Asset in any way, including
                bidding on your own items, preventing bidding, or using the
                Platform to conceal economic activity;
              </li>
              <li>
                upload any content that (i) infringes any intellectual property
                or other proprietary rights of any party, (ii) you do not have a
                right to upload under any law or under contractual or fiduciary
                relationships, (iii) contains software viruses or any other
                computer code, files or programs designed to interrupt, destroy
                or limit the functionality of any computer software or hardware
                or telecommunications equipment, (iv) poses or creates a privacy
                or security risk to any person, (v) constitutes unsolicited or
                unauthorized advertising, promotional materials, commercial
                activities and/or sales, “junk mail,” “spam,” “chain letters,”
                “pyramid schemes,” “contests,” “sweepstakes,” or any other form
                of solicitation, (vi) is unlawful, harmful, threatening,
                abusive, harassing, tortious, excessively violent, defamatory,
                vulgar, obscene, pornographic, libelous, invasive of another’s
                privacy, hateful racially, ethnically or otherwise
                objectionable, or (vii) in the sole judgment of the Company, is
                objectionable or which restricts or inhibits any other person
                from using or enjoying the Platform, or which may expose the
                Company or the Platform’s Users to any harm or liability of any
                type;
              </li>
              <li>
                interfere with or disrupt the Platform or servers or networks
                connected to the Platform, or disobey any requirements,
                procedures, policies or regulations of networks connected to the
                Platform;{' '}
              </li>
              <li>
                or violate any Applicable laws (i.e. local, state, national or
                international) or any regulations having the force of law, or
                which would involve proceeds of any unlawful activity;{' '}
              </li>
              <li>
                impersonate any person or entity, or falsely state or otherwise
                misrepresent your affiliation with a person or entity;
              </li>
              <li>
                solicit personal information from anyone under the age of 18;
              </li>
              <li>
                harvest or collect email addresses or other contact information
                of other Users from the Platform by electronic or other means
                for the purposes of sending unsolicited emails or other
                unsolicited communications;
              </li>
              <li>
                advertise or offer to sell or buy any goods or services for any
                business purpose that is not specifically authorized;
              </li>
              <li>
                further or promote any criminal activity or enterprise or
                provide instructional information about illegal activities,
                including for the purpose of concealing economic activity,
                laundering money, or financing terrorism;
              </li>
              <li>
                obtain or attempt to access or otherwise obtain any materials or
                information through any means not intentionally made available
                or provided for through the Platform;
              </li>
              <li>
                use any robot, spider, site search/retrieval application, or
                other device to retrieve or index any portion of the Platform or
                the content posted on the Platform, or to collect information
                about its Users for any unauthorized purpose;
              </li>
              <li>
                access or use the Platform for the purpose of creating a product
                or service that is competitive with any of our products or
                Services.
              </li>
            </ul>
          </p>
          <h3>What are the intellectual property rights on the Platform?</h3>

          <p>
            <div>
              <strong>[NFT Author’s rights]</strong>
            </div>
            The NFT Author owns all legal rights, title, and interest in all
            intellectual property rights underlying the NFT Content used to
            create Digital Assets minted by the Users on the Platform, including
            but not limited to copyrights and trademarks. As the copyright
            owner, the NFT Author has the right to reproduce, prepare derivative
            content, distribute, and display or perform the NFT Content.
          </p>

          <p>
            The NFT Author hereby acknowledges, understands, and agrees that
            launching a Digital Asset on the Platform constitutes an express and
            affirmative grant to the Company, its affiliates and successors a
            non-exclusive, world-wide, assignable, sublicensable, perpetual, and
            royalty-free license to make copies of, display, perform, reproduce,
            and distribute the Digital Asset on any media whether now known or
            later discovered for the broad purpose of operating, promoting,
            sharing, developing, marketing, and advertising the Platform, or any
            other purpose related to the Platform or the Company, including
            without limitation, the express right to: (i) display or perform the
            Digital Asset on the Platform, a third party platform, social media
            posts, blogs, editorials, advertising, market reports, virtual
            galleries, museums, virtual environments, editorials, or to the
            public; (ii) create and distribute digital or physical derivative
            Digital Asset based on the Digital Asset; (iii) indexing the Digital
            Asset in electronic databases, indexes, catalogues; and (iv)
            hosting, storing, distributing, and reproducing one or more copies
            of the Digital Asset within a distributed file keeping system, node
            cluster, or other database (e.g., IPFS) or causing, directing, or
            soliciting others to do so.
          </p>
          <p>
            The NFT Authors expressly represent and warrant that their Digital
            Assets listed on the Platform contain only original content
            otherwise authorized for use by the NFT Author, and does not contain
            unlicensed or unauthorized copyrighted content, including any
            imagery, design, audio, video, human likeness, or other unoriginal
            content not created by the NFT Author, not authorized for use by the
            NFT Author, not in the public domain, or otherwise without a valid
            claim of fair use, the NFT Author further represents and warrants
            that it has permission to incorporate the unoriginal content.
          </p>

          <p>
            The NFT Authors irrevocably release, acquit, and forever discharge
            the Company and its subsidiaries, affiliates, officers, and
            successors of any liability for direct or indirect copyright or
            trademark infringement for the Company’s use of the Digital Assets
            in accordance with these Terms.
          </p>

          <p>
            <div>
              <strong>[Platform Content, Software and Trademarks]</strong>
            </div>
            You acknowledge and agree that the Platform may contain Content or
            features that are protected by copyright, patent, trademark, trade
            secret or other proprietary rights and laws. Except as expressly
            authorized by the Company, you agree not to modify, copy, frame,
            scrape, rent, lease, loan, sell, distribute or create derivative
            works based on the Platform or the Content, in whole or in part. In
            connection with your use of the Platform you will not engage in or
            use any data mining, robots, scraping or similar data gathering or
            extraction methods. If you are blocked by the Company from accessing
            the Platform (including by blocking your IP address), you agree not
            to implement any measures to circumvent such blocking (e.g., by
            masking your IP address or using a proxy IP address). Any use of the
            Platform or the Content other than as specifically authorized herein
            is strictly prohibited. The technology and software underlying the
            Platform or distributed in connection therewith are the property of
            the Company, our affiliates and our partners. You agree not to copy,
            modify, create a derivative work of, reverse engineer, reverse
            assemble or otherwise attempt to discover any source code, sell,
            assign, sublicense, or otherwise transfer any right in the Content.
            Any rights not expressly granted herein are reserved by the Company.
          </p>

          <p>
            Other company, product, and service names and logos used and
            displayed via the Platform may be trademarks or service marks of
            their respective owners who may or may not endorse or be affiliated
            with or connected to the Company. Nothing in this Terms and
            Conditions or the Platform should be construed as granting, by
            implication, estoppel, or otherwise, any license or right to use any
            of the LUART Trademarks displayed on the Platform, without our prior
            written permission in each instance. All goodwill generated from the
            use of LUART Trademarks will inure to our exclusive benefit.
          </p>

          <p>
            <div>
              <strong>[Third Party Material]</strong>
            </div>
            Under no circumstances will the Company be liable in any way for any
            content or materials of any third parties (including Users),
            including, but not limited to, for any errors or omissions in any
            content, or for any loss or damage of any kind incurred as a result
            of the use of any such content. You acknowledge that the Company
            does not pre-screen content, but that the Company and its designees
            will have the right (but not the obligation) in their sole
            discretion to refuse or remove any content that is available via the
            Platform. Without limiting the foregoing, the Company and its
            designees will have the right to remove any content that violates
            these Terms or is deemed by the Platform, in its sole discretion, to
            be otherwise objectionable. You agree that you must evaluate, and
            bear all risks associated with, the use of any content and the
            purchase of any Digital Asset, including any reliance on the
            accuracy, completeness, or usefulness of such content.
          </p>

          <p>
            <div>
              <strong>[NFT Content Transmitted Through the Platform]</strong>
            </div>
            With respect to launching the NFT Tokens, you represent and warrant
            that you own all right, title and interest in and to the provided
            NFT Content, including, without limitation, all copyrights and
            rights of publicity contained therein. By uploading any NFT Content
            you hereby grant and will grant the Company and its affiliated
            companies a non-exclusive, worldwide, royalty free, fully paid up,
            transferable, sublicensable, perpetual, irrevocable license to copy,
            display, upload, perform, distribute, store, modify and otherwise
            use your NFT Content in connection with the operation of the
            Platform or the promotion, advertising or marketing thereof in any
            form, medium or technology now known or later developed.
          </p>

          <p>
            Submissions provided by you to the Company are non-confidential and
            the Company will be entitled to the unrestricted use and
            dissemination of these Submissions for any purpose, commercial or
            otherwise, without acknowledgment or compensation to you.
          </p>

          <p>
            The Company may preserve content and may also disclose content if
            required to do so by law or in the good faith belief that such
            preservation or disclosure is reasonably necessary to: (a) comply
            with legal process, Applicable laws or government requests; (b)
            enforce these Terms and Conditions; (c) respond to claims that any
            content violates the rights of third parties; or (d) protect the
            rights, property, or personal safety of the Company, its users and
            the public. You understand that the technical processing and
            transmission of the Platform, including your content, may involve
            (a) transmissions over various networks; and (b) changes to conform
            and adapt to technical requirements of connecting networks or
            devices.
          </p>

          <p>
            <div>
              <strong>[Copyright Complaints]</strong>
            </div>
            The Company respects the intellectual property of others, and we ask
            our users to do the same. If you believe that your work has been
            copied in a way that constitutes copyright infringement, or that
            your intellectual property rights have been otherwise violated, you
            should notify the Company of your infringement claim in accordance
            with the procedure set forth below.
          </p>

          <p>
            The Company will process and investigate notices of alleged
            infringement and will take appropriate actions under the applicable
            intellectual property laws of Poland with respect to any alleged or
            actual infringement. A notification of claimed copyright
            infringement should be emailed to hello@luart.io (Subject line:
            “Infringement Notice”). To be effective, the notification must be in
            writing and contain the following information:
            <ul>
              <li>
                an electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright or other
                intellectual property interest;
              </li>
              <li>
                a description of the copyrighted work or other intellectual
                property that you claim has been infringed;{' '}
              </li>
              <li>
                a description of where the material that you claim is infringing
                is located on the Platform, with enough detail that we may find
                it on the Platform;{' '}
              </li>
              <li>your address, telephone number, and email address; </li>
              <li>
                a statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright or intellectual
                property owner, its agent, or the law;{' '}
              </li>
              <li>
                a statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright or intellectual property owner or authorized to
                act on the copyright or intellectual property owner’s behalf.
              </li>
            </ul>
          </p>

          <p>
            <div>
              <strong>[Counter-Notice]</strong>
            </div>
            If you believe your NFT Content that was removed (or to which access
            was disabled) is not infringing, or that you have the authorization
            from the copyright owner, the copyright owner’s agent, pursuant to
            the law, to upload and use the content in your NFT Content, you may
            e-mail us a written counter-notice to hello@luart.io, containing the
            following information:
            <ul>
              <li>
                your physical or electronic signature; identification of the
                content that has been removed or to which access has been
                disabled and the location at which the content appeared before
                it was removed or disabled;
              </li>
              <li>
                a statement that you have a good faith belief that the content
                was removed or disabled as a result of mistake or a
                misidentification of the content;{' '}
              </li>
              and
              <li>
                your name, address, telephone number, and email address, a
                statement that you consent to the jurisdiction of Ireland and a
                statement that you will accept service of process from the
                person who provided notification of the alleged infringement.
              </li>
            </ul>
            If a counter-notice is received by the Company we will send a copy
            of the counter-notice to the original complaining party informing
            that person that it may replace the removed content or cease
            disabling it in 7 (seven) business days. Unless the copyright owner
            files an action seeking a court order against the content provider,
            member or user, the removed content may be replaced, or access to it
            restored, up to 14 (fourteen) business days or more after receipt of
            the counter-notice, at our sole discretion.
          </p>
          <h3>What fees does LUART charge?</h3>

          <p>
            <div>
              <strong>[Fees]</strong>
            </div>
            There are four kinds of fees on the Platform:
            <ul>
              <li>
                Launchpad fees for minting NFTs that are calculated as a
                fraction (5-10%) of the NFT price;
              </li>
              <li>
                Marketplace fees for trading NFTs that are calculated as a
                fraction (5-10%) of the value of a Transaction (charged only
                when the Transaction is executed) and a fixed fee of 0.2 UST for
                every operation while using the Marketplace Service (posting a
                buy or sell order, cancelling or executing an order, listing or
                delisting a Digital Asset, adding or withdrawing funds,
                “minting” a Digital Asset);
              </li>
              <li>
                Staking fees when you unstake a Digital Asset – a fixed fee of 1
                UST per a request to unstake a Digital Asset and a fixed fee of
                1 UST when Unbounding period is finished;
              </li>
              <li>
                Instant unbounding fees that are calculated as a fraction
                (5-10%) of the value of Digital Assets subject to unstaking.
              </li>
            </ul>
          </p>

          <p>
            All Transactions on the Platform, including without limitation
            “minting”, listing, offering, purchasing, or executing, are
            facilitated by smart contracts existing on the Terra blockchain that
            requires the Gas fee. The Gas Fee changes, often unpredictably, and
            is entirely outside of the control of the Company. The User
            acknowledges that under no circumstances a contract, agreement,
            offer, sale, or other transaction on the Platform becomes invalid,
            revocable, retractable, or otherwise unenforceable on the basis that
            the Gas Fee for the given Transaction was unknown, too high, or
            otherwise unacceptable.
          </p>

          <p>
            <div>
              <strong>[Taxes]</strong>
            </div>
            Users are solely responsible to pay any and all sales, use,
            value-added and other taxes, duties, and assessments now or
            hereafter claimed or imposed by any governmental authority,
            associated with your use of the Platform (including, without
            limitation, any taxes that may become payable as the result of your
            ownership, transfer, purchase, sale, or creation of any Digital
            Asset).
          </p>

          <h3>Risks associated with cryptography</h3>

          <p>
            You accept and acknowledge each of the following risks:
            <ul>
              <li>
                The prices of blockchain assets are extremely volatile.
                Fluctuations in the price of all kinds of virtual assets could
                materially and adversely affect the value of your Digital Asset,
                which may also be subject to significant price volatility. We
                cannot guarantee that any purchasers of the Digital Asset will
                not lose money.
              </li>
              <li>
                Tokens are tangible digital assets that exist only by virtue of
                the ownership record maintained in the given blockchain. All
                smart contracts are conducted and occur on the decentralized
                ledger. We have no control over, and make no guarantees,
                warranties or promises with in relation to smart contracts.
              </li>
              <li>
                We will not be liable to you for any losses you incur as the
                result of your use of the given blockchain. This includes, but
                is not limited to, losses, damages or claims arising from (i)
                user error (e.g. forgotten access information such as private
                keys, seeds or mnemonics or incorrectly construed smart
                contracts or other transactions); (ii) server failure or data
                loss; (iii) corrupted wallet files; (iv) unauthorized access or
                activities by third parties, including but not limited to the
                use of viruses, phishing, or other means of attack against the
                Platform, the given blockchain, the Wallet or any other tool
                connected to the Platform. We are also not responsible for
                losses due to blockchains and Wallets or any other tool
                connected to the Platform, including but not limited to late
                report (or no report) by developers or representatives of any
                issues with the blockchain supporting the given blockchain,
                including forks, technical node issues or any other issues
                having fund losses as a result.
              </li>
              <li>
                There are inherent security risks in providing information and
                dealing online over the internet. The Company implements
                appropriate technological and organizational safeguards to
                reduce the risk of any security breaches which could result in
                the accidental or unlawful destruction, loss, alteration,
                disclosure or access to your information. We also release
                periodic updates to reduce the risk of security incidents.
              </li>
              <li>
                There are risks associated with using an Internet-based
                currency, including, but not limited to, the risk of hardware,
                software and Internet connections, the risk of malicious
                software introduction, and the risk that third parties may
                obtain unauthorized access to information stored within your
                Wallet. You accept and acknowledge that the Company will not be
                responsible for any communication failures, disruptions, errors,
                distortions or delays you may experience when using the given
                blockchain, however caused.
              </li>
              <li>
                A lack of use or public interest in the creation and development
                of distributed ecosystems could negatively impact the
                development of the LUART ecosystem, and therefore the potential
                utility or value of Digital Assets available on the Platform.
              </li>
              <li>
                The Platform, the Terra blockchain and Digital Assets could be
                impacted by one or more regulatory inquiries or regulatory
                action, which could impede or limit the ability of the Company
                to continue to develop the Platform, or which could impede or
                limit your ability to access or use the Platform or the given
                blockchain, including access to your Digital Assets or other
                funds, and new regulations or policies may materially adversely
                affect the development of the LUART ecosystem, and therefore the
                potential utility of the Tokens.
              </li>
              <li>
                You acknowledge and understand that cryptography is a
                progressing field. Advances in code cracking or technical
                advances such as the development of quantum computers may
                present risks to cryptocurrencies, digital assets and the
                Platform, which could result in the theft or loss of your
                Digital Assets. To the extent possible, it is intended to update
                the protocol underlying the Platform to account for any advances
                in cryptography and to incorporate additional security measures,
                but does not guarantee or otherwise represent full security of
                the system. By using the Platform, you acknowledge these
                inherent risks.
              </li>
              <li>
                Any use or interaction with the Platform requires a
                comprehensive understanding of applied cryptography and computer
                science in order to appreciate inherent risks, including those
                listed above. You represent and warrant that you possess
                relevant knowledge and skills. Any reference to a type of
                Digital Assets on the Platform does not indicate our approval or
                disapproval of the underlying technology regarding such type of
                Digital Assets, and should not be used as a substitute for your
                own understanding of the risks specific to each type of Digital
                Asset. We make no warranty as to the suitability of the Digital
                Assets referenced on the Platform and assume no fiduciary duty
                in our relations with you.
              </li>
              <li>
                Use of the Platform, in particular for creating, buying or
                selling the Digital Assets, may carry financial risk. Digital
                Assets are, by their nature, highly experimental, risky and
                volatile. You acknowledge and agree that you will access and use
                the Platform at your own risk. The risk of loss in trading the
                Digital Assets can be substantial. You should, therefore,
                carefully consider whether such creating, buying or selling
                Digital Assets is suitable for you in light of your
                circumstances and financial resources. By using the Platform,
                you represent that you have been, are and will be solely
                responsible for making your own independent appraisal and
                investigations into the risks of a given transaction and the
                underlying Digital Assets. You represent that you have
                sufficient knowledge, market sophistication, professional advice
                and experience to make your own evaluation of the merits and
                risks of any transaction conducted via the Platform or any
                underlying Digital Assets. You accept all consequences of using
                the Platform, including the risk that you may lose access to
                your Digital Assets indefinitely. All Transaction decisions are
                made solely by you. Notwithstanding anything in these Terms, we
                accept no responsibility whatsoever for and will in no
                circumstances be liable to you in connection with use of the
                Platform for performing Transactions. Under no circumstances
                will the operation of all or any portion of the Platform be
                deemed to create a relationship that includes the provision or
                tendering of investment advice.
              </li>
              <li>
                You are aware of and accept the risk of operational challenges.
                The Platform may experience sophisticated cyber-attacks,
                unexpected surges in activity or other operational or technical
                difficulties that may cause interruptions to or delays on the
                Platform. You agree to accept the risk of the Platform failure
                resulting from unanticipated or heightened technical
                difficulties. We do not guarantee that the Platform is or will
                remain updated, complete, correct or secure, or that access to
                the Platform will be uninterrupted. The Platform may include
                inaccuracies, errors and materials that violate or conflict with
                these Terms. Additionally, third parties may make unauthorized
                alterations to the Platform or the software underlying the
                Platform. Accordingly, you should verify all information on the
                Platform before relying on it, and all decisions based on
                information contained on the Platform are your sole
                responsibility and we will have no liability for such decisions.
              </li>
            </ul>
          </p>

          <h3>What about my privacy?</h3>
          <p>
            The Company is the data controller for your information and we have
            specific obligations to you under the data protection law. You can
            find out more about what information we use, what we use it for and
            who we share it with in ourPrivacy Policy. Our Privacy Policy also
            contains details about your legal rights in relation to your
            information (but your legal rights will vary depending on where you
            are located).
          </p>

          <h3>Third-Party services</h3>
          <p>
            The Services may include Third-Party services or contain links to
            Third-Party services. You understand that Third-Party services are
            the responsibility of the third party that created or provided it
            and acknowledges that the use of such Third-Party services is solely
            at your own risk.
          </p>

          <p>
            The Company makes no representations and excludes all warranties and
            liabilities arising out of or pertaining to such Third-Party
            services, including its accuracy or completeness.
          </p>

          <p>
            All intellectual property rights in and to Third-Party services are
            the property of the respective third parties.
          </p>
          <h3>Prohibited Uses</h3>
          <p>
            Prior to your use of the Services and on an ongoing basis you
            represent, warrant, and agree that:
          </p>

          <p>
            <div>
              <strong>[Jurisdictions]</strong>
            </div>
            You are not located in or you are not a resident of any jurisdiction
            in which it is prohibited to use the Services and therefore you are
            not permitted to make use of the Services. For the avoidance of
            doubt, the foregoing restrictions on Services from Prohibited
            Jurisdictions applies equally to residents and citizens of other
            nations while located in a Prohibited Jurisdiction. Any attempt to
            circumvent the restrictions by any persons located in a Prohibited
            Jurisdiction, is a breach of this Terms. An attempt at circumvention
            includes, but is not limited to, manipulating the information used
            to identify the Users location and providing false or misleading
            information regarding the Users location or place of residence. If
            any provision of these Terms is or becomes illegal, invalid or
            unenforceable in any jurisdiction, that shall not affect the
            validity or enforceability in that jurisdiction of any other
            provision hereof or the validity or enforceability in other
            jurisdictions of that or any other provision hereof.
          </p>

          <p>
            Due to the rather uncertain and volatile regulatory environment
            surrounding virtual asset activities (including cryptocurrencies),
            every User shall each time prior to using the Services verify if
            he/she is not located in the Prohibited Jurisdiction or is not a
            resident or citizen of a Prohibited Jurisdiction.
          </p>

          <p>
            <div>
              <strong>[Personal use]</strong>
            </div>
            The Service is intended solely for the User’s personal use. The User
            is only allowed to use the Service for personal use, and may not
            create multiple accounts, including for the purpose of collusion
            and/or abuse of service.
          </p>

          <p>
            <div>
              <strong>[KYC and AML Policy]</strong>
            </div>
            The Company expressly prohibits and rejects the use of the Services
            for any form of illicit activity, including money laundering,
            terrorist financing or trade sanctions violations, consistent with
            various Applicable’ laws, regulations and norms. To that end, the
            Services are not offered to individuals or entities on any
            Politically Exposed Persons (“PEP”) lists, or subject to any United
            States, European Union or other global sanctions or watch lists. By
            using the Service, you represent that you are not on any such lists.
          </p>

          <p>
            <strong>
              ALL USERS ACKNOWLEDGE AND DECLARE THAT THEIR FUNDS COME FROM
              LEGITIMATE SOURCES AND DO NOT ORIGINATE FROM ILLEGAL ACTIVITIES;
              USERS AGREE THAT THE COMPANY MAY REQUIRE THEM TO PROVIDE OR
              OTHERWISE COLLECT THE NECESSARY INFORMATION AND MATERIALS AS PER
              RELEVANT LAWS OR GOVERNMENT ORDERS TO VERIFY THE LEGALITY OF THE
              SOURCES AND USE OF THEIR FUNDS.
            </strong>
          </p>

          <p>
            The Company will maintain a stance of cooperation with law
            enforcement authorities globally and will not hesitate to terminate
            Users’ access to the Platform on the basis of the legal mandate.
          </p>

          <h3>Other Legal Terms</h3>
          <p>
            <div>
              <strong>[Indemnity and Release]</strong>
            </div>
            You agree to release, indemnify and hold Indemnitees harmless from
            any from any and all losses, damages, expenses, including reasonable
            attorneys’ fees, rights, claims, actions of any kind and injury
            (including death) arising out of or relating to your use of the
            Platform, any NFT Content, your connection to the Platform, your
            violation of these Terms or your violation of any rights of another.
          </p>

          <p>
            <div>
              <strong>[Disclaimer of Warranties]</strong>
            </div>
            Transactions on the Platform utilize experimental smart contract and
            blockchain technology, including fungible and non-fungible tokens,
            cryptocurrencies, consensus algorithms, and decentralized or
            peer-to-peer networks and systems. Users acknowledge and agree that
            such technologies are experimental, speculative, and inherently
            risky and may be subject to bugs, malfunctions, timing errors,
            hacking and theft, or changes to the protocol rules of the given
            blockchain, as well as of its forks, which can adversely affect the
            smart contracts and may expose you to a risk of total loss,
            forfeiture of your Digital Assets, or lost opportunities to buy or
            sell Digital Assets.
          </p>

          <p>
            <strong>
              YOUR USE OF THE PLATFORM IS AT YOUR SOLE RISK. THE PLATFORM IS
              PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE COMPANY
              EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
              IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE AND NON-INFRINGEMENT. THE COMPANY MAKES NO WARRANTY THAT (I)
              THE PLATFORM WILL MEET YOUR REQUIREMENTS, (II) THE PLATFORM WILL
              BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS
              THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM WILL BE ACCURATE
              OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, PLATFORMS,
              INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
              THROUGH THE PLATFORM WILL MEET YOUR EXPECTATIONS.
            </strong>
          </p>

          <p>
            <strong>
              <div>[Limitation of Liability]</div>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE COMPANY WILL NOT BE
              LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
              EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT
              NOT LIMITED TO, LOSS IN VALUE OF ANY DIGITAL ASSET, DAMAGES FOR
              LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
              THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
              WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
              OTHERWISE, RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE
              PLATFORM; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
              PLATFORMS RESULTING FROM ANY DIGITAL ASSET, GOODS, DATA,
              INFORMATION OR PLATFORMS PURCHASED OR OBTAINED OR MESSAGES
              RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
              PLATFORM; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
              TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD
              PARTY ON THE PLATFORM; OR (V) ANY OTHER MATTER RELATING TO THE
              PLATFORM. IN NO EVENT WILL LUART’ TOTAL LIABILITY TO YOU FOR ALL
              DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE
              PAID LUART IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED
              DOLLARS ($100).
            </strong>
          </p>

          <p>
            <strong>
              SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
              CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
              ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE
              ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY
              PORTION OF THE PLATFORM OR WITH THESE TERMS, YOUR SOLE AND
              EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE PLATFORM.
            </strong>
          </p>

          <p>
            <div>
              <strong>[Termination Rights]</strong>
            </div>
            You agree that the Company, in its sole discretion, may suspend or
            terminate use of the Platform and remove and discard any content
            within the Platform, for any reason, including, without limitation,
            for lack of use or if the Company believes that you have violated or
            acted inconsistently with the letter or spirit of these Terms. Any
            suspected fraudulent, abusive or illegal activity that may be
            grounds for termination of your use of the Platform, may be referred
            to appropriate law enforcement authorities. The Company may also in
            its sole discretion and at any time discontinue providing the
            Platform, or any part thereof, with or without notice. You agree
            that any termination of your access to the Platform under any
            provision of this Terms may be effected without prior notice, and
            acknowledge and agree that the Company may immediately deactivate or
            delete your files related to the Services and/or bar any further
            access to such files or the Platform. Further, you agree that the
            Company will not be liable to you or any third party for any
            termination of your access to the Platform.
          </p>

          <p>We do not get involved with User disputes.</p>

          <p>
            You agree that you are solely responsible for your interactions with
            any other Users in connection with the Platform and the Company will
            have no liability or responsibility with respect thereto. The
            Company reserves the right, but has no obligation, to become
            involved in any way with disputes between you and any other user of
            the Platform.
          </p>

          <p>
            <div>
              <strong>[Legal Disputes]</strong>
            </div>
            Any claim, dispute or matter arising under or in connection with
            these Terms shall be governed and construed in all respects by the
            laws of Poland.
          </p>

          <p>
            <div>
              <strong>[General Legal Terms]</strong>
            </div>
            These Terms constitute the entire agreement between you and the
            Company and govern your use of the Platform, superseding any prior
            agreements between you and the Company with respect to the Platform.
            You also may be subject to additional terms and conditions that may
            apply when you use affiliate or Third-Party services, Third-Party
            content or Third-Party software. These Terms will be governed by the
            laws of Georgia without regard to its conflict of law provisions.
            With respect to any disputes or claims you and the Company agree to
            submit to the court of Georgia.
          </p>

          <p>
            The failure of the Company to exercise or enforce any right or
            provision of these Terms will not constitute a waiver of such right
            or provision.
          </p>

          <p>
            If any provision of these Terms is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties’ intentions as
            reflected in the provision, and the other provisions of these Terms
            remain in full force and effect.
          </p>

          <p>
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to use of the
            Platform or these Terms must be filed within one (1) year after such
            claim or cause of action arose or be forever barred.
          </p>

          <p>
            A printed version of this agreement and of any notice given in
            electronic form will be admissible in judicial or administrative
            proceedings based upon or relating to this agreement to the same
            extent and subject to the same conditions as other business
            documents and records originally generated and maintained in printed
            form.
          </p>

          <p>
            You may not assign this Terms without the prior written consent of
            the Company, but the Company may assign or transfer this Terms, in
            whole or in part, without any restriction at any time.
          </p>

          <p>
            The section titles in these Terms are for convenience only and have
            no legal or contractual effect.
          </p>

          <p>
            The Platform may provide notices to you of changes to these Terms or
            other matters by displaying notices or links to notices generally on
            the Platform.
          </p>

          <p>
            <div>
              <strong>[Your Privacy]</strong>
            </div>
            The Company respects the privacy of Users. For details please see
            our Privacy Policy. By using the Platform, you consent to our
            collection and use of personal data as outlined therein.
          </p>

          <p>
            <div>
              <strong>[Force Majeure]</strong>
            </div>
            The Company shall have no liability to you if it is prevented from
            or delayed in performing its obligations or from carrying on its
            business, by acts, events, omissions or accidents beyond its
            reasonable control, including, without limitation, strikes, failure
            of a utility service or telecommunications network, act of God, war,
            riot, civil commotion, malicious damage, compliance with any law or
            governmental order, rule, regulation, or direction.
          </p>

          <h3>No Financial and Legal Advice</h3>
          <p>
            The Company is merely a technology platform, and we are not your
            broker, intermediary, agent, or legal advisor and has no fiduciary
            relationship or obligation to you in connection with any decisions
            or activities effected by you using or participating on the
            Platform. No communication or information provided to you by the
            Company is intended as or will be considered or construed as, the
            solicitation of an offer to buy, the investment advice, financial
            advice, legal advice, or any other sort of advice. All Services and
            Transactions will be executed automatically based on the parameters
            of your consideration. You will be solely responsible for
            determining whether any services are suitable and match your
            interests according to your judgement, objectives, circumstances and
            risk tolerance. You will be solely responsible for any losses or
            liabilities therefrom.
          </p>

          <p>
            Before executing any Transactions you should consult with your
            independent financial, legal, or tax professionals. The Company will
            not be liable for the decisions you make through the Platform.
          </p>

          <h3>Feedback and contact details</h3>
          <p>
            You may choose to submit any Feedback by emailing us. We are free to
            use such Feedback at our discretion and without additional
            compensation to you, and may disclose such Feedback to third
            parties.
          </p>

          <p>
            Users with questions, complaints or claims with respect to the
            Platform can be made by contacting us at hello@luart.io.
          </p>
        </ArticleContainer>
      </ContentContainer>
    </Layout>
  );
};

export default TermsOfServicePage;
