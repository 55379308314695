import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface SectionProps {
  subheading: string;
  heading: string;
  id?: string;
}

const Section: React.FC<SectionProps> = ({
  subheading,
  heading,
  children,
  id,
}) => {
  return (
    <Box
      mb={16}
      id={id}
      sx={{
        overflowX: 'scroll',
        paddingLeft: '15px',
        '&::-webkit-scrollbar': {
          height: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.18)',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.32)',
          },
        },
      }}
    >
      <Typography variant="h300" component="h6" mb={1} color="primary">
        {subheading}
      </Typography>
      <Typography variant="h700" component="h5" mb={2} color="text.primary">
        {heading}
      </Typography>
      {children}
    </Box>
  );
};

export default Section;
