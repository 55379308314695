import { palette } from '@theme/palette';
import styled from 'styled-components';

export const StyledNewsletter = styled.div`
  padding: 50px 16px;
  background: ${palette.alphaLight[50]};
  border: 1px solid ${palette.alphaLight[200]};
  border-radius: 8px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 40px 0 40px 64px;
  }

  .MuiGrid-root {
    display: flex;
    align-items: center;
  }

  form {
    margin-top: 48px;

    a {
      color: ${props => props.theme.palette.text.primary};
      text-decoration: underline;
      transition: color 350ms;

      &:hover {
        color: ${props => props.theme.palette.text.secondary};
      }
    }


    input {
      width: 100%;

      ${(props) => props.theme.breakpoints.up('md')} {
        width: 312px;
      }
    }
  }
`;

export const StyledMailbox = styled.img`
  display: none;

  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
    margin: 0 auto;
    width: 80%;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: auto;
  }
`;
