import {
  StyledFooter,
  StyledHeading,
  StyledLogo,
  StyledBottomLinks,
  StyledLinks,
  StyledLink,
  StyledListLink,
} from './Footer.styled';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import * as ROUTES from '@constants/routes';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import SignUpForm from './SignUpForm';
import Container from '@components/Container/Container';

const Footer = () => {
  const bottomLinks = [
    {
      name: 'Terms',
      href: ROUTES.TOS,
    },
    {
      name: 'Privacy Policy',
      href: ROUTES.PRIVACY_POLICY,
    },
    {
      name: 'Pricing & Fees',
      href: ROUTES.PRICING,
    },
    {
      name: 'Download Branding',
      href: ROUTES.BRANDING,
    },
  ];

  const socialLinks = [
    {
      name: 'Telegram',
      href: ROUTES.TELEGRAM,
      disabled: false,
    },
    {
      name: 'Twitter',
      href: ROUTES.TWITTER,
      disabled: false,
    },
    {
      name: 'Medium',
      href: ROUTES.MEDIUM,
      disabled: false,
    },
    {
      name: 'Discord',
      href: ROUTES.DISCORD,
      disabled: false,
    },
  ];

  const platformLinks = [
    {
      name: 'NFT Launchpad',
      href: ROUTES.HOME,
      disabled: false,
    },
    {
      name: 'NFT Marketplace',
      href: ROUTES.MARKETPLACE,
      disabled: false,
    },
    {
      name: 'App $LUART',
      href: ROUTES.STAKING_APP,
      disabled: false,
    },
    {
      name: 'Website',
      href: ROUTES.LANDING_PAGE,
      disabled: false,
    },
  ];

  const renderLink = (link: {
    href: string;
    name: string;
    disabled: boolean;
  }) => {
    const isExternal = !link.href.startsWith('/');

    return isExternal ? (
      <StyledListLink
        href={link.disabled ? undefined : link.href}
        target={link.disabled ? '_self' : '_blank'}
        rel="noreferrer"
        disabled={link.disabled}
      >
        {link.name}
      </StyledListLink>
    ) : (
      <Link to={link.href}>{link.name}</Link>
    );
  };

  const gridItems = [
    {
      heading: 'Apply to Launch NFTs',
      renderChildren: () => {
        return (
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ lineHeight: '26px' }}
          >
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.APPLICATION_FORM}
            >
              Apply today
            </StyledLink>{' '}
            to launch or list your <br /> NFT project on Luart's launchpad{' '}
            <br /> and/or marketplace. We're taking <br /> a curated approach to
            project <br />
            listings on our launchpad.
          </Typography>
        );
      },
    },
    {
      heading: 'Platform Luart.io',
      renderChildren: () => {
        return (
          <StyledLinks>
            {platformLinks.map((link, index) => (
              <li key={`platform-link-${index}`}>{renderLink(link)}</li>
            ))}
          </StyledLinks>
        );
      },
    },
    {
      heading: 'Social Media',
      renderChildren: () => {
        return (
          <StyledLinks>
            {socialLinks.map((link, index) => (
              <li key={`social-link-${index}`}>{renderLink(link)}</li>
            ))}
          </StyledLinks>
        );
      },
    },
  ];

  return (
    <StyledFooter>
      <Container>
        <Grid container>
          <Grid item xs={12} md={3}>
            <StyledHeading>Get the latest Luart updates</StyledHeading>
            <SignUpForm />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={4} sx={{ ml: { md: 6 } }}>
              {gridItems.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ mb: { xs: 5, mb: 0 } }}
                  key={`footer-grid-item-${index}`}
                >
                  <StyledHeading>{item.heading}</StyledHeading>
                  {item.renderChildren()}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: { xs: 0, md: 8 } }}>
          <Grid item xs={12} md={5} lg={4} order={{ xs: 2, md: 1 }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              spacing={{ xs: 2, md: 1 }}
            >
              <StyledLogo src="/images/logo2.svg" alt="Luart Logo" />
              <span>
                Copyright Luart {new Date().getFullYear()}. All Rights Reserved.
              </span>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7} lg={8} order={{ xs: 1, md: 2 }}>
            <StyledBottomLinks>
              {bottomLinks.map((link, index) => {
                const isExternal = !link.href.startsWith('/');

                return (
                  <li key={`footer-bottom-link-${index}`}>
                    {isExternal ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={link.href}
                      >
                        {link.name}
                      </a>
                    ) : (
                      <Link to={link.href}>{link.name}</Link>
                    )}
                  </li>
                );
              })}
            </StyledBottomLinks>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
