import ProjectNFTs from './ProjectNFTs';
import GetNFT from './GetNFT/GetNFT';
import Grid from '@mui/material/Grid';
import Heading from '@components/Heading/Heading';
import Typography from '@mui/material/Typography';
import Badge from '@components/Badge/Badge';
import ProjectLogoContainer from '@components/ProjectLogoContainer/ProjectLogoContainer';
import {
  ProjectDetails as Project,
  UserStatsForCollection,
  TxReceipt,
  ProjectDetails as IProjectDetails,
} from '@utils/blockchain/blockchain.interface';
import Overview from './Overview';
import Roadmap from './Roadmap';
import Box from '@mui/material/Box';
import {
  StyledContentStack,
  StyledProjectDetails,
} from './ProjectDetails.styled';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import SocialLinks from './SocialLinks';
import { stripHtmlTagsFromText } from '@utils/stripHtmlTagsFromText';

interface ProjectDetailsProps {
  project: Project;
  userProjectStats: UserStatsForCollection | null;
  txReceipt: TxReceipt | null;
  setTxReceipt: React.Dispatch<React.SetStateAction<TxReceipt | null>>;
  tokenCount: number;
  setTokenCount: React.Dispatch<React.SetStateAction<number>>;
  setMinting: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  setUserProjectStats: React.Dispatch<
    React.SetStateAction<UserStatsForCollection | null>
  >;
  setProject: React.Dispatch<
    React.SetStateAction<IProjectDetails | null | undefined>
  >;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  project,
  userProjectStats,
  txReceipt,
  setTxReceipt,
  tokenCount,
  setTokenCount,
  setMinting,
  count,
  setCount,
  setUserProjectStats,
  setProject,
}) => {
  const {
    title,
    imageURL,
    mintingAccess,
    description,
    roadmap,
    roadmapImageURL,
    socialLinks,
    mintingStages,
    mintingStage,
  } = project;

  const activeMintingStage =
    mintingStages[userProjectStats?.mintingStage || mintingStage || 0];

  const { isPublic, stageTitle } = activeMintingStage;

  const descriptionWithoutHtml = stripHtmlTagsFromText(description || '');

  const slicedDescription = descriptionWithoutHtml
    ? descriptionWithoutHtml.slice(0, 200)
    : '';

  const shortDescription = `${slicedDescription}${slicedDescription.charAt(slicedDescription.length - 1) !== '.'
    ? '...'
    : '..'
    }`;

  const location = useLocation();

  return (
    <StyledProjectDetails>
      <Grid container columnSpacing={4} pt={4}>
        <Grid item xs={12} md={8}>
          <div>
            <StyledContentStack
              direction="column"
              justifyContent="space-between"
            >
              <div>
                <ProjectLogoContainer
                  variant="secondary"
                  imgSrc={imageURL}
                  imgAlt="Project Logo"
                />
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems={{ md: 'center' }}
                  spacing={{ md: 4 }}
                  mb={{ xs: 4, md: 0 }}
                >
                  <Heading
                    variant="h800"
                    component="h1"
                    sx={{ mb: '12px', mt: 1 }}
                  >
                    {title}
                  </Heading>
                  <Badge
                    size="medium"
                    color={isPublic ? 'success' : 'warning'}
                    dotColor={isPublic ? 'success' : 'warning'}
                    text={stageTitle}
                  />
                </Stack>
                {socialLinks && <SocialLinks projectLinks={socialLinks} />}
                <Typography
                  variant="body3"
                  color="text.primary"
                  component="p"
                  mt={4}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  {mintingAccess === 'public'
                    ? 'This is the public mint. The public mint is open to all users. Mint quotas may apply.'
                    : 'This is the whitelist mint. Only wallets that have been allocated a whitelist spot may mint at this stage. Mint quotas may apply.'}
                </Typography>
                <Typography
                  variant="body3"
                  color="text.primary"
                  component="p"
                  mt={3}
                  mb={{ xs: 5, md: 3 }}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  {shortDescription}{' '}
                  <HashLink smooth to={`${location.pathname}#overview`}>
                    Read more
                  </HashLink>
                </Typography>
              </div>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {activeMintingStage && (
                  <GetNFT
                    project={project}
                    userProjectStats={userProjectStats}
                    txReceipt={txReceipt}
                    setTxReceipt={setTxReceipt}
                    tokenCount={tokenCount}
                    setTokenCount={setTokenCount}
                    setMinting={setMinting}
                    count={count}
                    setCount={setCount}
                    setProject={setProject}
                    setUserProjectStats={setUserProjectStats}
                    activeMintingStage={activeMintingStage}
                  />
                )}
              </Box>
              {project?.sampleDrops && project?.sampleDrops?.length > 0 && (
                <ProjectNFTs
                  randomTokens={project?.sampleDrops}
                  projectTitle={project?.title}
                />
              )}
            </StyledContentStack>
            {((roadmap && roadmap.length > 0) || roadmapImageURL) && (
              <Roadmap roadmap={roadmap} roadmapImageURL={roadmapImageURL} projectName={title} />
            )}
            <Overview description={description} projectName={title} />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          mb={16}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          {activeMintingStage && (
            <GetNFT
              project={project}
              userProjectStats={userProjectStats}
              txReceipt={txReceipt}
              setTxReceipt={setTxReceipt}
              tokenCount={tokenCount}
              setTokenCount={setTokenCount}
              setMinting={setMinting}
              count={count}
              setCount={setCount}
              setProject={setProject}
              setUserProjectStats={setUserProjectStats}
              activeMintingStage={activeMintingStage}
            />
          )}
        </Grid>
      </Grid>
    </StyledProjectDetails>
  );
};

export default ProjectDetails;
