import React from 'react';
import Section from './Section';
import Timeline from '@components/Timeline/Timeline';
import { ProjectDetails } from '@utils/blockchain/blockchain.interface';

interface RoadmapProps {
  roadmap?: ProjectDetails['roadmap'];
  roadmapImageURL?: string;
  projectName: string;
}

const Roadmap: React.FC<RoadmapProps> = ({ projectName, roadmap, roadmapImageURL }) => {
  return (
    <Section
      subheading={`${projectName} journey started here`}
      heading="The Roadmap"
      id="roadmap"
    >
      {roadmap ? <Timeline steps={roadmap} /> : <img src={roadmapImageURL} alt="Roadmap" style={{ maxWidth: '100%' }} />}
    </Section>
  );
};

export default Roadmap;
