import Card from '@components/Card/Card';
import { StyledProjectNFT } from './ProjectNFT.styled';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LazyLoad from 'react-lazyload';
import { useState, useRef } from 'react';
import {
  StyledImage,
  StyledImagePlaceholder,
  StyledImageWrapper,
} from './ProjectNFT.styled';
import Fade from '@mui/material/Fade';

interface ProjectNFTProps {
  image: string;
  projectTitle?: string;
  tokenId: string;
  name: string;
}

const ProjectNFT: React.FC<ProjectNFTProps> = ({
  image,
  projectTitle,
  tokenId,
  name,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [loaded, setLoaded] = useState(false);
  const placeholderRef = useRef<HTMLDivElement>(null);

  const handleLoad = () => {
    placeholderRef?.current?.remove();
    setLoaded(true);
  };

  return (
    <StyledProjectNFT>
      <Card>
        <StyledImageWrapper>
          <StyledImagePlaceholder ref={placeholderRef} />
          <LazyLoad>
            <Fade in={true}>
              <StyledImage
                src={image}
                alt="NFT"
                onLoad={handleLoad}
                onError={handleLoad}
                loaded={loaded}
              />
            </Fade>
          </LazyLoad>
        </StyledImageWrapper>
        <Typography
          variant={isMobile ? 'h300' : 'h100'}
          component="h6"
          color="text.primary"
          mt={3}
        >
          {name}
        </Typography>
      </Card>
    </StyledProjectNFT>
  );
};

export default ProjectNFT;
