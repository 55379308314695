import {
  StyledAppBar,
  StyledLogo,
  StyledNavContainer,
  StyledNavLink,
  StyledIconButton,
  StyledTestnetBadge,
} from './Navbar.styled';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { Link, useHistory, useLocation } from 'react-router-dom';
import WalletSelector from '@components/WalletSelector/WalletSelector';
import { useState, useCallback, useEffect } from 'react';
import * as ROUTES from '@constants/routes';
import { CloseIcon, MenuIcon } from '@theme/icons';
import SecondaryFooter from '@components/SecondaryFooter/SecondaryFooter';
import Box from '@mui/material/Box';
import blockchain from '@utils/blockchain/blockchain';
import Container from '@components/Container/Container';

const Navbar = () => {
  const [openMobile, setOpenMobile] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const navLinks = [
    {
      name: 'Launchpad',
      href: ROUTES.HOME,
    },
    {
      name: 'Marketplace',
      href: ROUTES.MARKETPLACE,
    },
    {
      name: 'App $LUART',
      href: ROUTES.STAKING_APP,
    },
    {
      name: 'All Collections',
      href: ROUTES.COLLECTIONS,
    },
    {
      name: 'Activity',
      href: ROUTES.ALL_ACTIVITY,
    },
    {
      name: 'My Items',
      href: ROUTES.ACCOUNT_OWNED,
    },
  ];

  const shouldShowLayoutComponent = (show: boolean) => {
    const layout = document.getElementById('layout-container');

    if (layout) {
      layout.style.display = show ? 'block' : 'none';
    }
  };

  const handleCloseMobile = useCallback(() => {
    setOpenMobile(false);
    shouldShowLayoutComponent(true);
  }, [openMobile]);

  const handleToggleMobile = useCallback(() => {
    setOpenMobile(!openMobile);
    shouldShowLayoutComponent(openMobile);
  }, [openMobile]);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > 991) handleCloseMobile();
    };

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  useEffect(() => {
    const unlisten = history.listen(handleCloseMobile);

    return unlisten;
  }, []);

  const [isTestnet, setIsTestnet] = useState(false);

  useEffect(() => {
    blockchain.isTestnet().then(setIsTestnet);
  }, []);

  return (
    <StyledAppBar position="static">
      <Container>
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Link to={ROUTES.HOME} onClick={handleCloseMobile}>
                <StyledLogo src="/images/logo.svg" alt="Luart Logo" />
              </Link>
              {isTestnet && <StyledTestnetBadge>TESTNET</StyledTestnetBadge>}
            </Stack>
            <Stack
              direction="row"
              spacing={{ xs: 2, md: 3, lg: 4 }}
              alignItems="center"
            >
              <StyledNavContainer open={openMobile}>
                <ul>
                  {navLinks.map((link, index) => {
                    const isExternal = !link.href.startsWith('/');
                    const isActive = link.href === location.pathname;

                    return (
                      <StyledNavLink
                        key={`navbar-link-${index}`}
                        active={isActive}
                      >
                        {isExternal ? (
                          <a href={link.href} rel="noreferrer">
                            {link.name}
                          </a>
                        ) : (
                          <Link to={link.href}>{link.name}</Link>
                        )}
                      </StyledNavLink>
                    );
                  })}
                </ul>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                  <SecondaryFooter />
                </Box>
              </StyledNavContainer>
              <WalletSelector />
              <StyledIconButton onClick={handleToggleMobile}>
                {openMobile ? (
                  <CloseIcon fontSize="small" />
                ) : (
                  <MenuIcon fontSize="small" />
                )}
              </StyledIconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Navbar;
