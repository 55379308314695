import { BlockchainModule } from './blockchain.interface';
// import mockBlockchain from './mock';
import realBlockchain from './real';
// import terraUtils from "./real/terra-utils";

const blockchainModule: BlockchainModule = {
  ...realBlockchain,
  // ...mockBlockchain,
};

export default blockchainModule;
