import Heading from '@components/Heading/Heading';
import { Swiper, SwiperSlide } from 'swiper/react';
import MintedNFT from './MintedNFT';
import {
  StyledConfettiContainer,
  StyledSwiperButtonWrapper,
  StyledSwiperContainer,
} from './Minted.styled';
import IconButton from '@components/Button/IconButton';
import { ArrowLeftIcon, ArrowRightIcon } from '@theme/icons';
import { useState, useEffect } from 'react';
import Fade from '@mui/material/Fade';
import Confetti from 'react-confetti';
import { useWindowSize } from '@hooks/useWindowSize';
import Box from '@mui/material/Box';
import {
  NFTTokenDetails,
  ProjectDetails,
} from '@utils/blockchain/blockchain.interface';
import blockchain from '@utils/blockchain/blockchain';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import ZoomedImage from '@components/ZoomedImage/ZoomedImage';
import Typography from '@mui/material/Typography';
import Button from '@components/Button/Button';
import { Link } from 'react-router-dom';
import * as ROUTES from '@constants/routes';

interface MintedProps {
  setMinted: React.Dispatch<React.SetStateAction<boolean>>;
  project?: ProjectDetails | null;
  collectionContractAddress: string;
  previousMintedNFTs?: NFTTokenDetails[] | null;
}

const Minted: React.FC<MintedProps> = ({
  setMinted,
  project,
  collectionContractAddress,
  previousMintedNFTs,
}) => {
  const [swiper, setSwiper] = useState<any>(null);
  const [confettiRecycle, setConfettiRecycle] = useState(true);
  const [mintedNFTs, setMintedNFTs] = useState<null | NFTTokenDetails[]>(null);
  const [loading, setLoading] = useState(true);

  const [zoomedImage, setZoomedImage] = useState<null | string>(null);

  const projectName = project?.title || '';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getMintedNFTs = () => {
    setTimeout(async () => {
      try {
        const userStats = await blockchain.getUserStatsForCollection(
          collectionContractAddress
        );

        let newMintedNFTs: NFTTokenDetails[] = [];

        // Show only new minted NFTs
        if (previousMintedNFTs && previousMintedNFTs?.length > 0) {
          newMintedNFTs = userStats.mintedNFTs.filter(
            ({ tokenId: newTokenId }) =>
              !previousMintedNFTs.some(
                ({ tokenId: prevTokenId }) => newTokenId === prevTokenId
              )
          );
        } else {
          newMintedNFTs = userStats.mintedNFTs;
        }

        setMintedNFTs(newMintedNFTs);
      } catch (error) {
        console.log(error);
        setMintedNFTs(null);
      }

      setLoading(false);
    }, 12500);
  };

  useEffect(() => {
    if (!mintedNFTs) getMintedNFTs();
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;

    if (!loading) {
      timer = setTimeout(() => {
        setConfettiRecycle(false);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [loading]);

  const { width, height } = useWindowSize();

  const arrowsEnabled = Boolean(mintedNFTs && mintedNFTs?.length > 1);

  const params = {
    slidesPerView: 1,
    spaceBetween: 32,
    loop: arrowsEnabled,
    allowTouchMove: false,
  };

  const renderArrows = () => {
    return arrowsEnabled ? (
      <>
        <StyledSwiperButtonWrapper position="left">
          <IconButton onClick={() => swiper?.slidePrev()}>
            <ArrowLeftIcon />
          </IconButton>
        </StyledSwiperButtonWrapper>
        <StyledSwiperButtonWrapper position="right">
          <IconButton onClick={() => swiper?.slideNext()}>
            <ArrowRightIcon />
          </IconButton>
        </StyledSwiperButtonWrapper>
      </>
    ) : (
      <></>
    );
  };

  return loading ? (
    <LoadingSpinner size="large" color="secondary" addText center />
  ) : (
    <Fade in>
      <Box mb={16}>
        {mintedNFTs && mintedNFTs.length > 0 ? (
          <>
            <StyledConfettiContainer>
              <Confetti
                width={width}
                height={height}
                recycle={confettiRecycle}
              />
            </StyledConfettiContainer>
            <Heading
              variant="h800"
              component="h1"
              textAlign="center"
              my={{ xs: '12px', md: 4 }}
            >
              Congratulations!
            </Heading>
            <StyledSwiperContainer>
              <Swiper onSwiper={(swiper) => setSwiper(swiper)} {...params}>
                {mintedNFTs.map((mintedNFT, index) => (
                  <SwiperSlide key={`minted-nft-${index}`}>
                    <MintedNFT
                      mintedNFT={mintedNFT}
                      setMinted={setMinted}
                      projectName={projectName}
                      renderArrows={renderArrows}
                      isMobile={isMobile}
                      setZoomedImage={setZoomedImage}
                      listOfHiddenTraits={project?.listOfHiddenTraits}
                      hideTraitsRarityFor={project?.hideTraitsRarityFor}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {!isMobile && renderArrows()}
            </StyledSwiperContainer>
            {zoomedImage && (
              <ZoomedImage
                setZoomedImage={setZoomedImage}
                zoomedImage={zoomedImage}
              />
            )}
          </>
        ) : (
          <Box textAlign="center">
            <Heading variant="h700" component="h1" my={{ xs: '12px', md: 3 }}>
              Sorry, you were late
            </Heading>
            <Typography
              variant="body4"
              color="text.primary"
              component="p"
              mb={2}
            >
              Unfortunately the mint was unsuccessful because all NFTs were sold
              out, try next time.
            </Typography>
            <Link to={ROUTES.HOME}>
              <Button variant="contained" color="primary" type="button">
                Check out other projects
              </Button>
            </Link>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default Minted;
