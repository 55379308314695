import { palette } from '@theme/palette';
import styled from 'styled-components';

export const StyledTimeline = styled.div`
  margin-top: 50px;
  max-width: 100%;
`;

export const StyledStep = styled.div`
  display: flex;
  position: relative;
  padding-left: 16px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 1px;
    background: ${(props) => props.theme.palette.primary.main};

    ${(props) => props.theme.breakpoints.up('md')} {
      display: none;
    }
  }
`;

export const StyledContent = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-right: 32px;
    margin-top: 32px;
  }
`;

export const StyledLine = styled.div`
  display: none;

  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    width: 100%;
    height: 4px;
    background: ${palette.alphaLight[200]};
    position: relative;
  }
`;

export const StyledProgress = styled.div<{ progress: number }>`
  position: absolute;
  height: 4px;
  width: ${(props) => `${props.progress}%`};
  background: ${(props) => props.theme.palette.primary.main};
`;

export const StyledPoint = styled.div<{ variant: 'primary' | 'secondary' }>`
  position: absolute;
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.variant === 'primary'
      ? props.theme.palette.primary.main
      : palette.alphaLight[200]};
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: ${(props) =>
      props.variant === 'primary'
        ? palette.alphaDark[700]
        : props.theme.palette.text.primary};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
