import React from 'react';
import Card from '@components/Card/Card';
import { ProjectDetails as IProjectDetails } from '@utils/blockchain/blockchain.interface';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import { atcb_action } from 'add-to-calendar-button';
import Button from '@components/Button/Button';
import { formatInTimeZone } from 'date-fns-tz';
import { UTC_TIMEZONE } from '@utils/date';
import { CalendarIcon } from '@theme/icons';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';

interface UpcomingProjectProps {
  project: IProjectDetails;
}

const UpcomingProject: React.FC<UpcomingProjectProps> = ({ project }) => {
  const { title, mintingStages, mintingStage, isTimeUnknown } = project;

  const activeMintingStage = mintingStages[mintingStage || 0];

  const { startTime, endTime } = activeMintingStage;

  const formattedStartDate = formatInTimeZone(
    startTime,
    UTC_TIMEZONE,
    DATE_FORMAT
  );
  const formattedEndDate = formatInTimeZone(endTime, UTC_TIMEZONE, DATE_FORMAT);

  const formattedStartTime = formatInTimeZone(
    startTime,
    UTC_TIMEZONE,
    TIME_FORMAT
  );
  const formattedEndTime = formatInTimeZone(endTime, UTC_TIMEZONE, TIME_FORMAT);

  const handleAddToCalendar = () => {
    atcb_action({
      name: `${title} minting`,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      options: [
        'Apple',
        'Google',
        'iCal',
        'Microsoft365',
        'Outlook.com',
        'MicrosoftTeams',
        'Yahoo',
      ],
      trigger: 'click',
      timeZone: 'currentBrowser',
      iCalFileName: title,
    });
  };

  return (
    <Card>
      <ProjectDetails
        project={project}
        hideDetails={['End Date', 'End Time']}
      />
      <Button
        variant="contained"
        color="tertiary"
        type="button"
        fullWidth
        className="atcb_customTrigger"
        onClick={handleAddToCalendar}
        endIcon={<CalendarIcon fontSize="small" />}
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}
        disabled={isTimeUnknown}
      >
        Add to your Calendar
      </Button>
    </Card>
  );
};

export default UpcomingProject;
