import { palette } from '@theme/palette';
import styled, { css } from 'styled-components';

export interface StyledProjectLogoContainerProps {
  variant?: 'primary' | 'secondary';
}

const getStylesByVariant = (
  variant: StyledProjectLogoContainerProps['variant']
) => {
  if (variant === 'primary') {
    return css`
      width: 96px;
      height: 96px;
      padding: 8px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 8px !important;
      }
    `;
  } else {
    return css`
      width: 64px;
      height: 64px;
      padding: 5px;
      border-radius: 5px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 5px !important;
      }
    `;
  }
};

export const StyledProjectLogoContainer = styled.div<StyledProjectLogoContainerProps>`
  background: ${palette.alphaLight[100]};
  padding: 8px;
  border: 1px solid ${palette.alphaLight[200]};
  border-radius: 8px;
  ${(props) => getStylesByVariant(props.variant || 'primary')}
`;
