import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  ProjectDetails,
  ProjectStatus,
} from '@utils/blockchain/blockchain.interface';
import Heading from '@components/Heading/Heading';
import Stack from '@mui/material/Stack';
import SwiperArrow from '@components/SwiperArrow/SwiperArrow';
import Fade from '@mui/material/Fade';
import LiveProject from './Project/Live';
import UpcomingProject from './Project/Upcoming';
import PreviousProject from './Project/Previous';
import Grid from '@mui/material/Grid';

interface ProjectsProps {
  projects: ProjectDetails[];
  heading: string;
  icon: JSX.Element;
  id?: string;
  status: ProjectStatus;
  variant?: 'grid' | 'slider';
}

const Projects: React.FC<ProjectsProps> = ({
  projects,
  heading,
  id,
  status,
  variant = 'slider',
  icon,
}) => {
  const [swiper, setSwiper] = useState<any>(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<null | {
    slidesPerView?: number | 'auto' | undefined;
    spaceBetween?: number;
  }>(null);
  const [isBeginning, setBeginning] = useState(true);
  const [isEnd, setEnd] = useState(false);

  const arrowsEnabled = Boolean(
    currentBreakpoint?.slidesPerView &&
      projects.length > currentBreakpoint.slidesPerView
  );

  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      1200: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
    allowTouchMove: false,
  };

  const getProjectComponent = (project: ProjectDetails) => {
    switch (status) {
      case 'live':
        return <LiveProject project={project} />;
      case 'upcoming':
        return <UpcomingProject project={project} />;
      case 'previous':
      default:
        return <PreviousProject project={project} />;
    }
  };

  return (
    <Box mt={16} id={id}>
      <Fade in={true}>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4}
          >
            <Heading variant="h700" component="h2">
              {icon}
              {heading}
            </Heading>
            {variant === 'slider' && (
              <Stack direction="row" alignItems="center">
                <SwiperArrow
                  disabled={!arrowsEnabled || isBeginning}
                  direction="left"
                  onClick={() => swiper?.slidePrev()}
                />
                <SwiperArrow
                  disabled={!arrowsEnabled || isEnd}
                  direction="right"
                  onClick={() => swiper?.slideNext()}
                />
              </Stack>
            )}
          </Stack>
          {variant === 'slider' ? (
            <Swiper
              onSwiper={(swiper) => setSwiper(swiper)}
              onSlideChange={(swiper) => {
                setBeginning(swiper.isBeginning);
                setEnd(swiper.isEnd);
              }}
              onBreakpoint={(swiper, breakpoint) => {
                setCurrentBreakpoint(breakpoint);
              }}
              {...swiperParams}
            >
              {projects.map((project, index) => (
                <SwiperSlide key={`${status}-project-${index}`}>
                  {getProjectComponent(project)}
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Grid container spacing={4}>
              {projects.map((project, index) => (
                <Grid item xs={12} key={`${status}-project-${index}`}>
                  {getProjectComponent(project)}
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Fade>
    </Box>
  );
};

export default Projects;
