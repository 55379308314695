import { palette } from '@theme/palette';
import styled, { css } from 'styled-components';

export interface StyledBadgeProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
}

const getBadgeStylesByColor = (color: StyledBadgeProps['color']) => {
  switch (color) {
    case 'success':
      return css`
        background: ${palette.alphaDark[700]};
        border-color: ${palette.teal[200]};
      `;
    case 'warning':
      return css`
        background: ${palette.alphaDark[700]};
        border-color: ${palette.orange[50]};
      `;
    case 'alpha-light':
    default:
      return css`
        background: ${palette.alphaLight[50]};
        border-color: ${palette.alphaLight[100]};
      `;
  }
};

const getBadgeStylesBySize = (size: StyledBadgeProps['size']) => {
  switch (size) {
    case 'small':
      return css`
        border-radius: 4px;
        padding: 0 8px;
        height: 28px;

        span {
          line-height: 28px;
        }
      `;
    case 'large':
      return css`
        padding: 0 8px;
        border: 1px solid rgba(255, 255, 255, 0.11);
        border-radius: 6px;
        height: 28px;

        span {
          font-size: 12px;
          line-height: 12px;
        }

        ${(props) => props.theme.breakpoints.up('md')} {
          padding: 0 16px;
          height: 40px;
          padding-top: 2px;

          span {
            font-size: 16px;
            line-height: 16px;
          }
        }
      `;
    case 'medium':
    default:
      return css`
        border-radius: 4px;
        padding: 0 8px;
        height: 32px;

        span {
          line-height: 14px;
        }
      `;
  }
};

const dotColor = {
  warning: palette.orange[50],
  success: palette.teal[200],
  danger: palette.red[200],
};

export const StyledBadge = styled.div<StyledBadgeProps>`
  position: relative;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  width: max-content;

  ${(props) => getBadgeStylesByColor(props.color || 'success')};
  ${(props) => getBadgeStylesBySize(props.size || 'medium')};
`;

export const StyledDot = styled.div<{
  dotColor?: 'success' | 'warning' | 'danger';
}>`
  width: 8px;
  height: 8px;
  border-radius: 555px;
  background: ${(props) => dotColor[props.dotColor || 'success']};
`;

export const StyledDotContainer = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
