import {
  EstimatedCosts,
  TerraCurrency,
  TxReceipt,
} from '../../blockchain.interface';
import terraUtils from '../terra-utils';

export type CanMintResponse =
  | 'yes_in_public'
  | 'yes_in_whitelisted'
  | 'launch_is_not_active'
  | 'not_enough_lua_power'
  | 'not_on_whitelist'
  | 'max_tokens_minted';

export interface MintingStatusForUser {
  canMint: boolean;
  reason: CanMintResponse | null;
  userMintedCountInStage: number[];
  mintingStage: number;
}

const { blockchainValueToUserFacing, userFacingToBlockchainValue } =
  terraUtils.amountConverter.ust;

async function queryConfig(collectionContractAddress: string): Promise<any> {
  const response = await terraUtils.sendQuery(collectionContractAddress, {
    config: {},
  });
  return response.config;
}

async function getMintingStatus(
  collectionContractAddress: string
): Promise<any> {
  const response = await terraUtils.sendQuery(collectionContractAddress, {
    minting_state: {},
  });

  return response;
}

async function getAlreadyMintedCount(
  collectionContractAddress: string
): Promise<number> {
  const mintingStatus = await getMintingStatus(collectionContractAddress);

  return mintingStatus.minted_count;
}

async function getUserMintingStatus(
  collectionContractAddress: string
): Promise<any> {
  const address = await terraUtils.getWalletAddress();

  const response = await terraUtils.sendQuery(collectionContractAddress, {
    user_minting_status: { address },
  });

  return response;
}

async function getMintingStatusForUser(
  nftLaunchContractAddress: string
): Promise<MintingStatusForUser> {
  const { can_mint, reason, minted_by_user_in_stage, minting_stage } =
    await getUserMintingStatus(nftLaunchContractAddress);

  return {
    canMint: can_mint,
    reason,
    userMintedCountInStage: minted_by_user_in_stage,
    mintingStage: minting_stage,
  };
}

async function estimateTotalCost(
  collectionContractAddress: string,
  tokensCount: number
): Promise<{ ust: number; luna: number }> {
  const config = await queryConfig(collectionContractAddress);
  const mintingStatus = await getMintingStatus(collectionContractAddress);

  const mintingPrice = getMintingPrice(config, mintingStatus.minting_stage);

  if (mintingPrice.denom == 'uluna') {
    return {
      ust:
        tokensCount *
        blockchainValueToUserFacing(config.minting_stablecoin_fee.amount),
      luna: tokensCount * blockchainValueToUserFacing(mintingPrice.amount),
    };
  } else {
    return {
      ust:
        tokensCount *
        (blockchainValueToUserFacing(config.minting_stablecoin_fee.amount) +
          blockchainValueToUserFacing(mintingPrice.amount)),
      luna: 0,
    };
  }
}

async function estimateMintingCosts(
  collectionContractAddress: string,
  tokensCount: number
): Promise<EstimatedCosts> {
  const config = await queryConfig(collectionContractAddress);
  const mintingStatus = await getMintingStatus(collectionContractAddress);

  const mintingPrice = getMintingPrice(config, mintingStatus.minting_stage);

  return {
    luartFee:
      (
        blockchainValueToUserFacing(config.minting_stablecoin_fee.amount) *
        tokensCount
      ).toFixed(2) + ' UST',
    price:
      (blockchainValueToUserFacing(mintingPrice.amount) * tokensCount).toFixed(
        2
      ) +
      ' ' +
      (mintingPrice.denom == 'uusd' ? 'UST' : 'LUNA'),
    txFee: `< ${2 * tokensCount} UST`,
  };
}

function getMintingPrice(config: any, mintingStage: number) {
  const { minting_stages } = config;

  const mintingPrice = minting_stages[mintingStage].minting_price;

  return mintingPrice;
}

async function mint(
  collectionContractAddress: string,
  tokensCount: number
): Promise<TxReceipt> {
  const totalCost = await estimateTotalCost(collectionContractAddress, 1);

  return await terraUtils.postManyTransactions(
    Array(tokensCount).fill({
      contractAddress: collectionContractAddress,
      message: {
        random_mint: {},
      },
      coins: {
        ust: userFacingToBlockchainValue(totalCost.ust),
        luna: totalCost.luna
          ? userFacingToBlockchainValue(totalCost.luna)
          : undefined,
      },
    })
  );
}

// Internal function
// We can move it away from blockchan module later
function getLaunchContractAdddress(): string {
  const urlParams = new URLSearchParams(window.location.search);
  const contractAddress = urlParams.get('contract');
  if (!contractAddress) {
    alert(`You've visited invalid link`);
    throw new Error(`contract query param must be set`);
  }
  return contractAddress;
}

async function getWithdrawableBalance() {
  const userAddress = await terraUtils.getWalletAddress();
  const queryResult = await terraUtils.sendQuery(getLaunchContractAdddress(), {
    balance: {
      address: userAddress,
    },
  });

  const userBalance: any = {};

  for (const balanceForDenom of queryResult.balance) {
    userBalance[balanceForDenom.denom] = balanceForDenom.amount;
  }

  return {
    UST: terraUtils.amountConverter.ust.blockchainValueToUserFacing(
      userBalance.uusd || 0
    ),
    LUNA: terraUtils.amountConverter.ust.blockchainValueToUserFacing(
      userBalance.uluna || 0
    ),
  };
}

async function withdraw(amount: number, currency: TerraCurrency) {
  const userAddress = await terraUtils.getWalletAddress();
  const useDefaultTxFeeIsFeeEstimationFails = true;
  return await terraUtils.postTransaction({
    contractAddress: getLaunchContractAdddress(),
    message: {
      withdraw: {
        tokens: {
          denom: currency == 'LUNA' ? 'uluna' : 'uusd',
          amount:
            terraUtils.amountConverter.ust.userFacingToBlockchainValue(amount),
        },
        recipient: userAddress,
      },
    },
  }, useDefaultTxFeeIsFeeEstimationFails);
}

export default {
  getMintingStatusForUser,
  estimateMintingCosts,
  getAlreadyMintedCount,
  getMintingStatus,
  getUserMintingStatus,
  mint,

  getWithdrawableBalance,
  withdraw,
};
