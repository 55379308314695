import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';

export const store = createStore(
  rootReducer,
  undefined,
  applyMiddleware(thunk)
);

export type State = ReturnType<typeof rootReducer>;
