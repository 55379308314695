import Typography from '@mui/material/Typography';
import { StyledOverview } from './Overview.styled';
import Section from './Section';

interface OverviewProps {
  projectName: string;
  description?: string;
}

const Overview: React.FC<OverviewProps> = ({ projectName, description }) => {
  return (
    <Section
      subheading={`${projectName} Project Overview`}
      heading={`About ${projectName}`}
      id="overview"
    >
      <StyledOverview>
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </StyledOverview>
    </Section>
  );
};

export default Overview;
