import { StyledCard } from '@components/Card/Card.styled';
import styled from 'styled-components';

export const StyledMintedNFTDetails = styled(StyledCard)`
  padding: 0;
  background: transparent;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 100%;
  }
`;

export const StyledHeader = styled.div`
  background: rgba(33, 33, 33, 0.24);
  padding: 24px;
  padding-bottom: 21px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.18);

  ${(props) => props.theme.breakpoints.up('md')} {
    min-height: 128px;
  }
`;

export const StyledBody = styled.div`
  padding: 24px;
  background: rgba(255, 255, 255, 0.04);

  ${(props) => props.theme.breakpoints.up('md')} {
    height: calc(100% - 128px);
  }
`;

export const StyledProjectTitle = styled.span`
  color: ${(props) => props.theme.palette.text.secondary};
  transition: color 350ms;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: -2px;
  font-family: 'Libre Franklin', sans-serif;
  cursor: pointer;

  &:hover {
    ${(props) => props.theme.breakpoints.up('md')} {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;
