import styled from 'styled-components';

export const StyledLayout = styled.div<{ variant: 'primary' | 'secondary' }>`
  background: #1d2343;

  ${(props) => props.theme.breakpoints.up('md')} {
    background: #1d2343
      url(${(props) =>
        props.variant === 'primary' ? '/images/bg2.jpg' : '/images/bg1.jpg'})
      no-repeat center center;
    background-size: cover;
  }
`;

export const StyledLoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
