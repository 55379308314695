import React from 'react';
import ProjectLogoContainer from '@components/ProjectLogoContainer/ProjectLogoContainer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatInteger } from '@utils/formatNumbers';
import { ProjectDetails as IProjectDetails } from '@utils/blockchain/blockchain.interface';
import { useMediaQuery } from 'react-responsive';
import { formatLUNADecimal, formatUSTDecimal } from '@utils/currency';
import { getFormattedTime, isDateOlderThanOneYear } from '@utils/date';
import { DiscordIcon, GlobeIcon, MediumIcon, TwitterIcon } from '@theme/icons';
import TypographyLink from '@components/TypographyLink/TypographyLink';

interface ProjectDetailsProps {
  project: IProjectDetails;
  hideDetails?: string[];
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  project,
  hideDetails,
}) => {
  const {
    imageURL,
    title,
    totalTokensCount,
    mintingStages,
    mintingStage,
    socialLinks,
    formattedPrice,
    isTimeUnknown,
  } = project;

  const isMobile = useMediaQuery({ maxWidth: 991 });

  const activeMintingStage = mintingStages[mintingStage || 0];

  const { mintPrice, mintCurrency, endTime, startTime } = activeMintingStage;

  const getMintPrice = () => {
    if (formattedPrice) return formattedPrice;

    if (mintPrice === 0) return 'TBA';

    if (mintCurrency === 'LUNA') return formatLUNADecimal(mintPrice);

    if (mintCurrency === 'UST') return formatUSTDecimal(mintPrice);
  }

  const details = [
    {
      name: 'Mint Price',
      value: getMintPrice()
    },
    {
      name: 'End Date',
      value: isDateOlderThanOneYear(endTime)
        ? 'Unlimited'
        : getFormattedTime(endTime, 'date'),
    },
    {
      name: 'End Time',
      value: isDateOlderThanOneYear(endTime)
        ? 'Unlimited'
        : getFormattedTime(endTime, 'time'),
    },
    {
      name: 'Start Date',
      value: isDateOlderThanOneYear(startTime)
        ? 'TBA'
        : getFormattedTime(startTime, 'date'),
    },
    {
      name: 'Start Time',
      value:
        isDateOlderThanOneYear(startTime) || isTimeUnknown
          ? 'TBA'
          : getFormattedTime(startTime, 'time'),
    },
    {
      name: 'Project Links',
      value: [
        {
          icon: <TwitterIcon fontSize="small" />,
          href: socialLinks?.twitter?.url,
        },
        {
          icon: <DiscordIcon fontSize="small" viewBox="-3 -2 24 24" />,
          href: socialLinks?.discord?.url,
        },
        {
          icon: <MediumIcon fontSize="small" />,
          href: socialLinks?.medium?.url,
        },
        {
          icon: <GlobeIcon fontSize="small" />,
          href: socialLinks?.website?.url,
        },
      ],
    },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={4} sx={{ mb: 4 }}>
        <ProjectLogoContainer imgSrc={imageURL} imgAlt={title} />
        <div>
          <Typography
            variant="h400"
            component="h3"
            color="text.primary"
            mt={{ xs: 1, md: 1 / 2 }}
            mb={{ xs: 1, lg: 0 }}
          >
            {title}
          </Typography>
          <Typography variant="body3" color="text.primary" component="div">
            Supply:{' '}
            <Typography
              variant="h300"
              color="text.primary"
              component="h3"
              display="inline-block"
            >
              {totalTokensCount === 0 ? 'TBA' : formatInteger(totalTokensCount)}
            </Typography>
          </Typography>
        </div>
      </Stack>
      {details.map(
        (detail, index) =>
          !hideDetails?.includes(detail.name) && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mb: index === details.length - 1 ? 0 : 1 }}
              key={`${endTime}-project-details-${index}`}
            >
              <Typography
                variant={isMobile ? 'body2' : 'body3'}
                color="text.secondary"
              >
                {detail.name}
              </Typography>
              {Array.isArray(detail.value) ? (
                <Stack direction="row" spacing={2} alignItems="center">
                  {detail.value.map(
                    (item, index) =>
                      item.href &&
                      item.href.length > 0 && (
                        <TypographyLink
                          key={`detail-array-value-${index}`}
                          href={item.href}
                          target="_blank"
                          color="text.secondary"
                        >
                          {item.icon}
                        </TypographyLink>
                      )
                  )}
                </Stack>
              ) : (
                <Typography
                  variant={isMobile ? 'h200' : 'h300'}
                  color="text.primary"
                  component="h6"
                >
                  {detail.value}
                </Typography>
              )}
            </Stack>
          )
      )}
    </>
  );
};

export default ProjectDetails;
