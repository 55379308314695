import MintedNFTDetails from './MintedNFTDetails';
import { NFTTokenDetails } from '@utils/blockchain/blockchain.interface';
import Grid from '@mui/material/Grid';
import { StyledNFTImageContainer } from './MintedNFT.styled';
import Image from './Image';

interface MintedNFTProps {
  mintedNFT: NFTTokenDetails;
  setMinted: React.Dispatch<React.SetStateAction<boolean>>;
  projectName: string;
  renderArrows: () => JSX.Element;
  isMobile: boolean;
  setZoomedImage: React.Dispatch<React.SetStateAction<null | string>>;
  listOfHiddenTraits?: null | string[];
  hideTraitsRarityFor?: null | string[];
}

const MintedNFT: React.FC<MintedNFTProps> = ({
  mintedNFT,
  setMinted,
  projectName,
  renderArrows,
  isMobile,
  setZoomedImage,
  listOfHiddenTraits,
  hideTraitsRarityFor,
}) => {
  const { imageURL } = mintedNFT;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <StyledNFTImageContainer>
          <Image imageURL={imageURL} setZoomedImage={setZoomedImage} />
          {isMobile && renderArrows()}
        </StyledNFTImageContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <MintedNFTDetails
          mintedNFT={mintedNFT}
          setMinted={setMinted}
          projectName={projectName}
          listOfHiddenTraits={listOfHiddenTraits}
          hideTraitsRarityFor={hideTraitsRarityFor}
        />
      </Grid>
    </Grid>
  );
};

export default MintedNFT;
