import { createSvgIcon, SvgIcon } from '@mui/material';
import { ComponentType, createElement } from 'react';
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg';
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as Wallet } from './assets/wallet.svg';
import { ReactComponent as ExternalLink } from './assets/external-link.svg';
import { ReactComponent as Discord } from './assets/discord.svg';
import { ReactComponent as Telegram } from './assets/telegram.svg';
import { ReactComponent as Twitter } from './assets/twitter.svg';
import { ReactComponent as Plus } from './assets/plus.svg';
import { ReactComponent as Minus } from './assets/minus.svg';
import { ReactComponent as ArrowUp } from './assets/arrow-up.svg';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as CheckCircle } from './assets/check-circle.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as CopyFile } from './assets/copy-file.svg';
import { ReactComponent as LoginAlt } from './assets/login-alt.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg';
import { ReactComponent as Lock } from './assets/lock.svg';
import { ReactComponent as Globe } from './assets/globe.svg';
import { ReactComponent as Account } from './assets/account.svg';
import { ReactComponent as Multimedia } from './assets/multimedia.svg';
import { ReactComponent as Newspaper } from './assets/newspaper.svg';
import { ReactComponent as Resize } from './assets/resize.svg';
import { ReactComponent as MoneyWithdraw } from './assets/money-withdraw.svg';
import { ReactComponent as MoneyStack } from './assets/money-stack.svg';
import { ReactComponent as AngleDown } from './assets/angle-down.svg';
import { ReactComponent as AngleUp } from './assets/angle-up.svg';
import { ReactComponent as Medium } from './assets/medium.svg';
import { ReactComponent as Calendar } from './assets/calendar.svg';

const createMaterialIcon = (Icon: ComponentType): typeof SvgIcon => {
  return createSvgIcon(createElement(Icon), Icon.displayName || 'SVGIcon');
};

export const CalendarIcon = createMaterialIcon(Calendar);
export const AngleUpIcon = createMaterialIcon(AngleUp);
export const AngleDownIcon = createMaterialIcon(AngleDown);
export const MoneyStackIcon = createMaterialIcon(MoneyStack);
export const MoneyWithdrawIcon = createMaterialIcon(MoneyWithdraw);
export const ResizeIcon = createMaterialIcon(Resize);
export const ArrowRightIcon = createMaterialIcon(ArrowRight);
export const ArrowLeftIcon = createMaterialIcon(ArrowLeft);
export const WalletIcon = createMaterialIcon(Wallet);
export const ExternalLinkIcon = createMaterialIcon(ExternalLink);
export const DiscordIcon = createMaterialIcon(Discord);
export const MediumIcon = createMaterialIcon(Medium);
export const TelegramIcon = createMaterialIcon(Telegram);
export const TwitterIcon = createMaterialIcon(Twitter);
export const PlusIcon = createMaterialIcon(Plus);
export const MinusIcon = createMaterialIcon(Minus);
export const ArrowUpIcon = createMaterialIcon(ArrowUp);
export const InfoIcon = createMaterialIcon(Info);
export const CheckCircleIcon = createMaterialIcon(CheckCircle);
export const CheckIcon = createMaterialIcon(Check);
export const CloseIcon = createMaterialIcon(Close);
export const CopyFileIcon = createMaterialIcon(CopyFile);
export const LoginAltIcon = createMaterialIcon(LoginAlt);
export const MenuIcon = createMaterialIcon(Menu);
export const ArrowDownIcon = createMaterialIcon(ArrowDown);
export const LockIcon = createMaterialIcon(Lock);
export const GlobeIcon = createMaterialIcon(Globe);
export const MultimediaIcon = createMaterialIcon(Multimedia);
export const AccountIcon = createMaterialIcon(Account);
export const NewspaperIcon = createMaterialIcon(Newspaper);
