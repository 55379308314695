import {
  StyledProjectLogoContainer,
  StyledProjectLogoContainerProps,
} from './ProjectLogoContainer.styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ProjectLogoContainerProps extends StyledProjectLogoContainerProps {
  imgSrc: string;
  imgAlt: string;
}

const ProjectLogoContainer: React.FC<ProjectLogoContainerProps> = ({
  imgSrc,
  imgAlt,
  variant = 'primary',
}) => {
  return (
    <StyledProjectLogoContainer variant={variant}>
      <LazyLoadImage src={imgSrc} alt={imgAlt} />
    </StyledProjectLogoContainer>
  );
};

export default ProjectLogoContainer;
