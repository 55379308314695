import { ArrowLeftIcon, ArrowRightIcon } from '@theme/icons';
import { StyledSwiperArrow } from './SwiperArrow.styled';

interface SwiperArrowProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
  direction: 'left' | 'right';
}

const SwiperArrow: React.FC<SwiperArrowProps> = ({
  direction,
  onClick,
  disabled = false,
}) => {
  return (
    <StyledSwiperArrow onClick={onClick} type="button" disabled={disabled}>
      {direction === 'left' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </StyledSwiperArrow>
  );
};

export default SwiperArrow;
