import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import React from 'react';
import { StyledLayout, StyledLoaderContainer } from './Layout.styled';
import { useLocation } from 'react-router-dom';
import * as ROUTES from '@constants/routes';
import { useSelector } from 'react-redux';
import { State } from '@store/store';
import Topbar from '@components/Topbar/Topbar';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import Fade from '@mui/material/Fade';
import Container from '@components/Container/Container';
import MigrationBanner from '@components/MigrationBanner/MigrationBanner';

interface LayoutProps {
  loading?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, loading }) => {
  const location = useLocation();
  const { errors } = useSelector((state: State) => state.blockchain);

  if (loading) {
    return (
      <StyledLoaderContainer>
        <LoadingSpinner size="large" color="secondary" center />
      </StyledLoaderContainer>
    );
  }

  return (
    <StyledLayout
      variant={location.pathname === ROUTES.HOME ? 'secondary' : 'primary'}
    >
      {errors?.pingTerraGateway && <Topbar />}
      {/* <MigrationBanner /> */}
      <Navbar />
      <div id="layout-container">
        <Fade in={true}>
          <Container>{children}</Container>
        </Fade>
        <Footer />
      </div>
    </StyledLayout>
  );
};

export default Layout;
