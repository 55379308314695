import nftLaunch from './contracts/nft-launch';
import cw721 from './contracts/cw721';
import {
  BlockchainModule,
  UserStatsForCollection,
} from '../blockchain.interface';
import nftProjects from './nft-projects';
import terraUtils from './terra-utils';
import mockBlockchain from '../mock';
import luart from './contracts/luart';

async function getUserStatsForCollection(
  collectionContractAddress: string
): Promise<UserStatsForCollection> {
  const mintedNFTs = await cw721.getUserTokens(collectionContractAddress);

  const userMintingStatus = await nftLaunch.getMintingStatusForUser(
    collectionContractAddress
  );

  const projectDetails = await nftProjects.findProjectByAddress(
    collectionContractAddress
  );

  const { mintingStage, reason, canMint, userMintedCountInStage } =
    userMintingStatus;

  if (canMint) {
    return {
      mintedCount: userMintedCountInStage[mintingStage],
      maxTokensToMintForUser:
        projectDetails.mintingStages?.[mintingStage]?.maxTokensToMintPerUser! ||
        0,
      mintedNFTs,
      mintingStage,
      canMint,
      reason,
    };
  } else {
    // User has no access to mint more (at least now)
    // So we set maxTokensToMintForUser to the number of
    // minted tokens through the nft-launch contract

    const mintedCount = userMintingStatus.userMintedCountInStage.reduce(
      (acc, count) => acc + count,
      0
    );

    return {
      mintedCount,
      maxTokensToMintForUser: mintedCount,
      mintedNFTs,
      mintingStage,
      canMint,
      reason,
    };
  }
}

const realBlockchainModule: BlockchainModule = {
  estimateMintingCosts: nftLaunch.estimateMintingCosts,
  mint: nftLaunch.mint,

  getUserStatsForCollection,
  getRandomImageUrlFromCollection: cw721.getRandomImageUrlFromCollection,
  getRandomTokensFromCollection: cw721.getRandomTokensFromCollection,

  getProjects: nftProjects.getProjects,

  setWallet: terraUtils.setWallet,
  getBalanceUST: terraUtils.getBalanceUST,
  getBalanceLUNA: terraUtils.getBalanceLUNA,
  getBalanceLUART: luart.getBalanceLUART,
  pingTerraGateway: terraUtils.pingTerraGateway,
  getTxResult: terraUtils.getTxResult,
  isTestnet: terraUtils.isTestnet,

  // Withdraw
  getWithdrawableBalance: nftLaunch.getWithdrawableBalance,
  withdraw: nftLaunch.withdraw,
};

export default realBlockchainModule;
