import {
  StyledBody,
  StyledHeader,
  StyledMintedNFTDetails,
  StyledProjectTitle,
} from './MintedNFTDetails.styled';
import Typography from '@mui/material/Typography';
import Heading from '@components/Heading/Heading';
import Stack from '@mui/material/Stack';
import Button from '@components/Button/Button';
import { TwitterIcon } from '@theme/icons';
import { NFTTokenDetails } from '@utils/blockchain/blockchain.interface';
import { toCapitalize } from '@utils/toCapitalize';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { generateTwitterShareLink } from '@utils/twitterLinks';

interface MintedNFTDetailsProps {
  mintedNFT: NFTTokenDetails;
  setMinted: React.Dispatch<React.SetStateAction<boolean>>;
  projectName: string;
  listOfHiddenTraits?: null | string[];
  hideTraitsRarityFor?: null | string[];
}

const MintedNFTDetails: React.FC<MintedNFTDetailsProps> = ({
  mintedNFT,
  setMinted,
  projectName,
  listOfHiddenTraits,
  hideTraitsRarityFor,
}) => {
  const { name } = mintedNFT;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getTraits = () => {
    const traits = Object.keys(mintedNFT.traits).map((key) => ({
      name: toCapitalize(key),
      value: toCapitalize(mintedNFT.traits[key].value),
      rarity: mintedNFT.traits[key].rarity,
    }));

    return traits;
  };

  const traits = getTraits();

  const twitterLink = generateTwitterShareLink(mintedNFT.name);

  return (
    <StyledMintedNFTDetails>
      <StyledHeader>
        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <StyledProjectTitle onClick={() => setMinted(false)}>
            {projectName}
          </StyledProjectTitle>
          <img src="/images/blue-check.svg" alt="Check" />
        </Stack>
        <Heading
          variant="h600"
          component="h2"
          sx={{ fontSize: '32px !important', lineHeight: '48px !important' }}
          noWrap={!isMobile}
        >
          {name}
        </Heading>
      </StyledHeader>
      <StyledBody>
        <Stack sx={{ height: '100%' }} justifyContent="space-between">
          <div>
            {traits.map(
              (trait, index) =>
                !listOfHiddenTraits?.includes(trait.name) && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={`nft-trait-${index}`}
                    mb="14px"
                  >
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {trait.name}
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1 / 2}
                    >
                      <Typography variant="body2" color="text.primary" noWrap>
                        {trait.value}
                      </Typography>
                      {!hideTraitsRarityFor?.includes(trait.name) && (
                        <Typography
                          variant="h200"
                          color="text.primary"
                          component="h6"
                          noWrap
                        >
                          ({trait.rarity}%)
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                )
            )}
          </div>
          <Box mt={{ xs: 3, md: 0 }}>
            <a href={twitterLink} target="_blank">
              <Button
                type="button"
                variant="contained"
                color="tertiary"
                fullWidth
                sx={{ mb: 2 }}
              >
                <TwitterIcon sx={{ mr: '12px' }} />
                Tweet it!
              </Button>
            </a>
            <Button
              type="button"
              onClick={() => setMinted(false)}
              variant="contained"
              color="primary"
              fullWidth
            >
              Mint more {isMobile ? '' : projectName}
            </Button>
          </Box>
        </Stack>
      </StyledBody>
    </StyledMintedNFTDetails>
  );
};

export default MintedNFTDetails;
