import { differenceInSeconds } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const UTC_TIMEZONE = 'Africa/Abidjan'; // UTC

const ONE_DAY = 60 * 60 * 24;
const ONE_HOUR = 60 * 60;
const ONE_MINUTE = 60;

const timeUnits = ['d', 'h', 'm', 's'];

export const getCountdown = (deadline: number) => {
  const currentTime = Date.now();

  const diffInSeconds = differenceInSeconds(deadline, currentTime);

  if (diffInSeconds <= 1) {
    return {
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      formattedCountdown: '00d 00h 00m',
      finished: true,
    };
  }

  const days = Math.floor(diffInSeconds / ONE_DAY);
  const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
  const minutes = Math.floor(
    (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
  );
  const seconds =
    diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;

  const filteredCountdown = [days, hours, minutes].reduce(
    (acc: string[], time) => {
      const timeStr = time.toString();
      if (timeStr.length === 1) acc.push(`0${timeStr}`);
      else acc.push(timeStr);
      return acc;
    },
    []
  );

  let formattedCountdown = '';

  for (let i = 0; i < filteredCountdown.length; i++) {
    const time = filteredCountdown[i];
    formattedCountdown = formattedCountdown + time + timeUnits[i] + ' ';
  }

  return {
    countdown: {
      days,
      hours,
      minutes,
      seconds,
    },
    formattedCountdown,
    finished: days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0,
  };
};

export const isDateOlderThanOneYear = (date?: number) => {
  if (date) {
    const yearInMiliseconds = 1000 * 3600 * 24 * 365;

    const currentTime = new Date().getTime();

    return (date - currentTime) / yearInMiliseconds > 1;
  }

  return true;
};

export const getFormattedTime = (
  timestamp?: number,
  format: 'date' | 'time' | 'full-date' = 'full-date'
) => {
  if (!timestamp) return '';

  if (isDateOlderThanOneYear(timestamp)) {
    return 'TBA';
  }

  if (format === 'full-date') {
    return (
      formatInTimeZone(timestamp, UTC_TIMEZONE, "d MMM y h:mmaaaaa'm'") + ' UTC'
    );
  }

  if (format === 'time') {
    return formatInTimeZone(timestamp, UTC_TIMEZONE, "h:mmaaaaa'm'") + ' UTC';
  }

  if (format === 'date') {
    return formatInTimeZone(timestamp, UTC_TIMEZONE, 'd MMM y');
  }
};
