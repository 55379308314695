import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as ROUTES from '@constants/routes';
import HomePage from '@pages/home';
import ProjectPage from '@pages/project';
import { RouterScrollRestoration } from '@hooks/useRouterScrollRestoration';
import TermsOfServicePage from '@pages/tos';
import PrivacyPolicyPage from '@pages/privacy-policy';
import WithdrawPage from '@pages/withdraw/withdraw';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route exact path={`${ROUTES.PROJECT}/:id`} component={ProjectPage} />
        <Route exact path={ROUTES.TOS} component={TermsOfServicePage} />
        <Route
          exact
          path={ROUTES.PRIVACY_POLICY}
          component={PrivacyPolicyPage}
        />
        <Route exact path={ROUTES.WITHDRAW} component={WithdrawPage} />
      </Switch>
      <RouterScrollRestoration />
    </BrowserRouter>
  );
};

export default Router;
