import styled from 'styled-components';

export const StyledProjectNFT = styled.div`
  img {
    border-radius: 8px;
  }

  .MuiCard-root {
    padding: 16px;
  }
`;

export const StyledImage = styled.img<{ loaded?: boolean }>`
  border-radius: 16px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(props) => (props.loaded ? 1 : 0)} !important;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 320px;

  ${(props) => props.theme.breakpoints.up('md')} {
    height: 158px;
  }
`;

export const StyledImagePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
`;
