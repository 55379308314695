import { Dispatch } from 'redux';
import {
  BlockchainAction,
  BlockchainActionTypes,
} from '../types/blockchain.types';
import blockchain from '@utils/blockchain/blockchain';
import { ProjectDetails } from '@utils/blockchain/blockchain.interface';

export const getProjects =
  () => async (dispatch: Dispatch<BlockchainAction>) => {
    const loadingName = 'getProjects';

    dispatch({ type: BlockchainActionTypes.LOADING, payload: loadingName });

    try {
      const projects = await blockchain.getProjects();

      const live = getLiveProjects(projects);
      const upcoming = getUpcomingProjects(projects);
      const finished = getPreviousProjects(projects);

      dispatch({
        type: BlockchainActionTypes.GET_PROJECTS,
        payload: {
          live,
          upcoming,
          finished,
        },
      });
    } catch (error) {
      dispatch({
        type: BlockchainActionTypes.ERROR,
        payload: {
          loading: loadingName,
        },
      });
    }
  };

const getLiveProjects = (projects: ProjectDetails[]) =>
  sortProjects(
    projects.filter((project) => project.status === 'live'),
    'desc',
    'startTime'
  );

const getUpcomingProjects = (projects: ProjectDetails[]) =>
  sortProjects(
    projects.filter((project) => project.status === 'upcoming'),
    'asc',
    'startTime'
  );

const getPreviousProjects = (projects: ProjectDetails[]) => {
  const previousProjects = projects.filter(
    (project) => project.status === 'previous'
  );

  return sortProjects(previousProjects, 'desc', 'endTime');
};

const sortProjects = (
  projects: ProjectDetails[],
  sortBy: 'desc' | 'asc',
  key: 'startTime' | 'endTime'
) =>
  projects.sort((a, b) => {
    const keyA = a.mintingStages[a.mintingStage || 0][key];
    const keyB = b.mintingStages[b.mintingStage || 0][key];
    if (sortBy === 'desc') return keyB - keyA;
    else return keyA - keyB;
  });

export const getBalance =
  () => async (dispatch: Dispatch<BlockchainAction>) => {
    const loadingName = 'getBalance';

    dispatch({
      type: BlockchainActionTypes.LOADING,
      payload: loadingName,
    });

    try {
      let balance = {
        ust: 0,
        luna: 0,
        luart: 0,
      };
      let promises = [];

      promises.push(
        blockchain.getBalanceUST().then((ust) => (balance.ust = ust))
      );

      promises.push(
        blockchain.getBalanceLUNA().then((luna) => (balance.luna = luna))
      );

      promises.push(
        blockchain.getBalanceLUART().then((luart) => (balance.luart = luart))
      );

      await Promise.all(promises);

      dispatch({
        type: BlockchainActionTypes.GET_BALANCE,
        payload: balance,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: BlockchainActionTypes.ERROR,
        payload: {
          loading: loadingName,
          newState: {
            balance: null,
          },
        },
      });
    }
  };

export const pingTerraGateway =
  () => async (dispatch: Dispatch<BlockchainAction>) => {
    const loadingName = 'pingTerraGateway';

    dispatch({ type: BlockchainActionTypes.LOADING, payload: loadingName });

    try {
      await blockchain.pingTerraGateway();
      dispatch({ type: BlockchainActionTypes.PING_TERRA_GATEWAY });
    } catch (error) {
      console.log(error);
      dispatch({
        type: BlockchainActionTypes.ERROR,
        payload: {
          loading: loadingName,
        },
      });
    }
  };
