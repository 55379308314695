import React from 'react';
import {
  StyledAvailableButton,
  StyledCurrency,
  StyledCurrencyInputWrapper,
} from './CurrencyInput.styled';
import Typography from '@mui/material/Typography';
import Input from '@components/Input/Input';
import { InputProps as MuiInputProps } from '@mui/material/Input';
import Grid from '@mui/material/Grid';

interface CurrencyInputProps extends Omit<MuiInputProps, 'error'> {
  currency?: string;
  available?: boolean;
  error?: string;
  label?: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  currency = 'UST',
  available = false,
  label,
  onChange,
  error,
  readOnly,
  ...rest
}) => {
  const pattern: RegExp = new RegExp(/^[0-9.]\d*$/g);

  const props = {
    type: 'number',
    autoComplete: 'off',
    placeholder: '0.00',
    inputProps: {
      inputMode: 'decimal' as 'decimal',
      pattern,
    },
    onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!pattern.test(e.key)) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedText = e.clipboardData?.getData('text');

      if (!pattern.test(pastedText)) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
  };

  return (
    <>
      {label && (
        <Typography
          variant="h300"
          component="label"
          color="text.primary"
          mb={1}
          display="block"
        >
          {label}
        </Typography>
      )}
      <StyledCurrencyInputWrapper readOnly={readOnly}>
        <StyledCurrency>
          <Typography variant="body3" color="text.secondary">
            ${currency}
          </Typography>
        </StyledCurrency>
        <Input {...rest} {...props} readOnly={readOnly} onChange={onChange} />
      </StyledCurrencyInputWrapper>
      <Grid container sx={{ mt: 1 }}>
        {error && (
          <Grid item xs={12} md={!available ? 12 : 6}>
            <Typography variant="body2" color="error.light" component="p">
              {error}
            </Typography>
          </Grid>
        )}
        {available && (
          <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
            <StyledAvailableButton type="button">
              10 984.76 UST Available
            </StyledAvailableButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CurrencyInput;
