import styled from 'styled-components';
import MuiIconButton from '@mui/material/IconButton';

export const StyledIconButton = styled(MuiIconButton)`
  width: 42px;
  height: 42px;
  background: ${(props) => props.theme.palette.tertiary.main};
  border-radius: 6px;
  padding: 0;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: ${(props) => props.theme.palette.text.primary};

  ${(props) => props.theme.breakpoints.down('md')} {
    background: #262c4a;
  }

  &:hover {
    background: ${(props) => props.theme.palette.tertiary.light};

    ${(props) => props.theme.breakpoints.down('md')} {
      background: #262c4a;
    }
  }
`;
